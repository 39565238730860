/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

/************************* AUTHENTICATION *************************/
export const login = async (email, password) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        email,
        password,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Logged in successfully!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout',
    });
    if ((res.data.status = 'success'))
      showAlert('success', `You have successfully logged out`);
    window.setTimeout(() => {
      location.assign('/login');
    }, 1500);
  } catch (err) {
    showAlert('error', 'Error logging out! Try again.');
  }
};

export const signup = async (
  name,
  email,
  line1,
  line2,
  city,
  state,
  postal_code,
  country,
  phone,
  environment,
  customerId,
  subscriptionId,
  plan,
  maxShopsFromOneTime,
  maxShopsFromSubscription,
  userState,
  role,
  subscriber,
  oneTime,
  freelanceId,
  vatNumber,
  subscriptionAmount,
  password,
  passwordConfirm
) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: {
        name,
        email,
        line1,
        line2,
        city,
        state,
        postal_code,
        country,
        phone,
        environment,
        customerId,
        subscriptionId,
        plan,
        maxShopsFromOneTime,
        maxShopsFromSubscription,
        userState,
        role,
        subscriber,
        oneTime,
        freelanceId,
        vatNumber,
        subscriptionAmount,
        password,
        passwordConfirm,
      },
    });

    if (res.data.status === 'success') {
      try {
        const response = await fetch(`/create-contact-on-brevo/${email}/13`, {
          method: 'POST',
        });

        if (response.ok) console.log('Contact created successfully');
      } catch (err) {
        console.log(`${err} from brevo subscription`);
      }

      showAlert('success', 'Sign up successfully!');
      window.setTimeout(() => {
        location.assign('/exclusive-offer');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

/************************* SHOP *************************/
export const createShop = async (name, url) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/users/me/shops`,
      data: {
        name,
        url,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Shop added successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createShopAdmin = async (name, url, userId) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/users/${userId}/shops/createShopAdmin`,
      data: {
        name,
        url,
        user: userId,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Shop added successfully!');
      window.setTimeout(() => {
        location.assign('/shops');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateShop = async (data, id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/me/shops/${id}`,
      data,
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Shop updated successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateShopAdmin = async (data, userId, id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${userId}/shops/${id}`,
      data,
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Shop updated successfully!');
    }
  } catch (err) {
    console.error(err);
    showAlert('error', err.response.data.message);
  }
};

export const deleteShop = async (id) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/users/me/shops/${id}`,
      data: null,
    });

    if (res.status === 204) {
      showAlert('success', 'Shop deleted successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

/************************* KURO *************************/

export const deleteKuro = async (id) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/kuros/check/${id}`,
      data: null,
    });

    if (res.status === 204) {
      showAlert('success', 'Kuro deleted successfully!');
      window.setTimeout(() => {
        location.assign('/hackors');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

/************************* USERS *************************/
export const createUser = async (body) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/',
      data: body,
    });

    console.log(res);

    if (res.data.status === 'success') {
      showAlert('success', 'Create user successfully!');
      window.setTimeout(() => {
        location.assign('/allusers');
      }, 1500);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

export const deleteUser = async (id) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/users/${id}`,
      data: null,
    });

    if (res.status === 204) {
      showAlert('success', 'User deleted successfully!');
      window.setTimeout(() => {
        location.assign('/allusers');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotPassword',
      data: {
        email,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Email sent successfully!');
      window.setTimeout(() => {
        location.assign('/login');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const changeDefaultPM = async (pmId, subscriptionId, userId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${userId}`,
      data: {
        pmId,
        subscriptionId,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'New payment method saved!');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

/************************* THEME VERSION *************************/
export const createThemeVersion = async (body) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/themeVersion',
      data: body,
    });

    console.log(res);

    if (res.data.status === 'success') {
      showAlert('success', 'Theme version created successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

export const deleteThemeVersion = async (id) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/themeVersion/${id}`,
    });

    if (res.status === 204) {
      showAlert('success', 'Theme version deleted successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', 'Error deleting theme version! Try again.');
  }
};

export const updateThemeVersion = async (id, body) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/themeVersion/${id}`,
      data: body,
    });

    console.log(res);

    if (res.data.status === 'success') {
      showAlert('success', 'Theme version updated successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', 'Error updating theme version! Try again.');
  }
};

//////// Updates
export const createUpdates = async (body) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/update',
      data: body,
    });

    console.log(res);

    if (res.data.status === 'success') {
      showAlert('success', 'Update created successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

export const updateUpdate = async (id, updatedData) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/update/${id}`,
      data: updatedData,
    });

    console.log(res);

    if (res.data.status === 'success') {
      showAlert('success', 'Update updated successfully!');
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

export const deleteUpdate = async (id) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/update/${id}`,
    });

    if (res.status === 204) {
      showAlert('success', 'Update deleted successfully!');
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};
