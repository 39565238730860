const slider = document.getElementById('myRange');
const output = document.getElementById('sliderValue');
const colorPicker = document.getElementById('colorPicker');
const sizeBefore = document.getElementById('sizeBefore');
const sizeAfter = document.getElementById('sizeAfter');
const sizeReduction = document.getElementById('sizeReduction');

if (slider || colorPicker || sizeBefore) {
  output.innerHTML = slider.value + '%';

  slider.oninput = function () {
    output.innerHTML = this.value + '%';
    updateImageDisplay();
  };

  colorPicker.oninput = function () {
    updateImageDisplay();
  };

  function setupListeners() {
    const fileInput = document.getElementById('imageInput');
    const widthInput = document.getElementById('width');
    const heightInput = document.getElementById('height');

    fileInput.addEventListener('change', updateImageDisplay);
    widthInput.addEventListener('input', updateImageDisplay);
    heightInput.addEventListener('input', updateImageDisplay);
  }

  // Initialisation des éléments une fois que le DOM est complètement chargé
  document.addEventListener('DOMContentLoaded', () => {
    const widthInput = document.getElementById('width');
    const heightInput = document.getElementById('height');
    const banner = document.getElementById('banner');
    const others = document.getElementById('others');
    const format4_3 = document.getElementById('4-3');
    const format3_4 = document.getElementById('3-4');
    const maintainProportions = document.getElementById('maintainProportions');
    const linkIcon = document.getElementById('linkIcon');
    const unlinkIcon = document.getElementById('unlinkIcon');

    let maintainAspectRatio = false;
    let aspectRatio = 1;

    banner.addEventListener('click', () => {
      widthInput.value = 1920;
      heightInput.value = 1080;
      updateImageDisplay();
    });

    others.addEventListener('click', () => {
      widthInput.value = 1000;
      heightInput.value = 1000;
      updateImageDisplay();
    });

    format4_3.addEventListener('click', () => {
      widthInput.value = 800;
      heightInput.value = 600;
      updateImageDisplay();
    });

    format3_4.addEventListener('click', () => {
      widthInput.value = 600;
      heightInput.value = 800;
      updateImageDisplay();
    });

    maintainProportions.addEventListener('click', () => {
      const currentWidth = parseInt(widthInput.value);
      const currentHeight = parseInt(heightInput.value);
      if (currentWidth && currentHeight) {
        const aspectRatio = currentHeight / currentWidth;
        widthInput.value = 1000;
        heightInput.value = Math.round(1000 * aspectRatio);
        updateImageDisplay();
      } else {
        console.log(
          "Aucune image n'est chargée ou les dimensions sont invalides."
        );
      }
    });

    linkIcon.addEventListener('click', () => {
      maintainAspectRatio = false;
      linkIcon.classList.add('hidden');
      unlinkIcon.classList.remove('hidden');
    });

    unlinkIcon.addEventListener('click', () => {
      maintainAspectRatio = true;
      unlinkIcon.classList.add('hidden');
      linkIcon.classList.remove('hidden');

      const currentWidth = parseInt(widthInput.value);
      const currentHeight = parseInt(heightInput.value);
      if (currentWidth && currentHeight) {
        aspectRatio = currentHeight / currentWidth;
      }
    });

    widthInput.addEventListener('input', () => {
      if (maintainAspectRatio) {
        const currentWidth = parseInt(widthInput.value);
        if (currentWidth) {
          heightInput.value = Math.round(currentWidth * aspectRatio);
          updateImageDisplay();
        }
      } else {
        updateImageDisplay();
      }
    });

    heightInput.addEventListener('input', () => {
      if (maintainAspectRatio) {
        const currentHeight = parseInt(heightInput.value);
        if (currentHeight) {
          widthInput.value = Math.round(currentHeight / aspectRatio);
          updateImageDisplay();
        }
      } else {
        updateImageDisplay();
      }
    });

    setupListeners();
  });

  document.addEventListener('DOMContentLoaded', setupListeners);

  let isInitialLoad = true;

  function updateImageDisplay() {
    const fileInput = document.getElementById('imageInput');
    const canvas = document.getElementById('canvas');
    const widthInput = document.getElementById('width');
    const heightInput = document.getElementById('height');
    const ctx = canvas.getContext('2d');
    const text = document.querySelector('.imageTool__results--text');
    const overlayOpacity = slider.value / 100;
    const overlayColor = colorPicker.value;

    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const originalFileName = file.name.replace(/\.[^/.]+$/, '');
      const originalSizeMB = (file.size / 1048576).toFixed(2);
      sizeBefore.style.display = 'block';
      text.style.display = 'none';
      sizeBefore.textContent = `Taille de l'image avant : ${originalSizeMB} Mo`;

      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          if (isInitialLoad) {
            widthInput.value = img.width;
            heightInput.value = img.height;
            isInitialLoad = false;
          }

          const width = parseInt(widthInput.value) || img.width;
          const height = parseInt(heightInput.value) || img.height;

          canvas.style.display = 'block';
          canvas.width = width || img.width;
          canvas.height = height || img.height;

          const imgRatio = img.width / img.height;
          const canvasRatio = canvas.width / canvas.height;
          let drawWidth, drawHeight, dx, dy;

          if (imgRatio < canvasRatio) {
            drawWidth = canvas.width;
            drawHeight = canvas.width / imgRatio;
            dx = 0;
            dy = (canvas.height - drawHeight) / 2;
          } else {
            drawHeight = canvas.height;
            drawWidth = canvas.height * imgRatio;
            dx = (canvas.width - drawWidth) / 2;
            dy = 0;
          }

          ctx.drawImage(img, dx, dy, drawWidth, drawHeight);
          ctx.fillStyle = `rgba(${parseInt(
            overlayColor.slice(1, 3),
            16
          )}, ${parseInt(overlayColor.slice(3, 5), 16)}, ${parseInt(
            overlayColor.slice(5, 7),
            16
          )}, ${overlayOpacity})`;
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.toBlob(function (blob) {
            const url = URL.createObjectURL(blob);
            const downloadLink = document.getElementById('downloadLink');
            downloadLink.href = url;
            downloadLink.download = `${originalFileName}.webp`;
            downloadLink.textContent = "Télécharger l'image WebP";
            downloadLink.style.display = 'block';

            const newSizeMB = (blob.size / 1048576).toFixed(2);
            sizeAfter.style.display = 'block';
            sizeAfter.textContent = `Taille de l'image après : ${newSizeMB} Mo`;

            const reductionPercent = (
              ((originalSizeMB - newSizeMB) / originalSizeMB) *
              100
            ).toFixed(2);
            sizeReduction.style.display = 'block';
            sizeReduction.textContent = `Réduction de la taille : ${reductionPercent}%`;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    } else {
      console.log('Aucun fichier sélectionné');
    }
  }
}
