import { showAlert } from './alerts';
const userShops = document.querySelector('.user-shops');
const userShopsAdmin = document.querySelector('.user-shops-admin');
const tableBody = document.getElementById('shopTableBody');

if (userShops || userShopsAdmin) {
  document.addEventListener('DOMContentLoaded', () => {
    // Sélection des éléments
    const searchInput = document.getElementById('shopSearch');
    const sortSelect = document.getElementById('shopSort');

    // ✅ Gardez une copie originale des lignes (pour réafficher ensuite)
    let originalRows = Array.from(tableBody.querySelectorAll('tr'));

    // Recherche dynamique et tri
    searchInput.addEventListener('input', filterShops);
    sortSelect.addEventListener('change', filterShops);

    // ✅ Tri par défaut au chargement
    window.addEventListener('load', () => {
      sortSelect.value = 'createdAtDesc'; // Par défaut : Date d'ajout (récent → ancien)
      filterShops();
    });

    function filterShops() {
      const searchTerm = searchInput.value.toLowerCase();
      const sortBy = sortSelect.value;

      // ✅ Filtrage par recherche (nom + URL)
      const filteredRows = originalRows.filter((row) => {
        const name =
          row.querySelector('.shop-name')?.textContent.toLowerCase() || '';
        const url =
          row.querySelector('.shop-url')?.textContent.toLowerCase() || '';
        return name.includes(searchTerm) || url.includes(searchTerm);
      });

      // ✅ Tri selon le critère choisi
      filteredRows.sort((a, b) => {
        let valA = '',
          valB = '';

        if (sortBy === 'createdAtAsc' || sortBy === 'createdAtDesc') {
          valA = a.querySelector('.shop-created-date')?.dataset.timestamp || '';
          valB = b.querySelector('.shop-created-date')?.dataset.timestamp || '';
        } else if (sortBy === 'updatedAtAsc' || sortBy === 'updatedAtDesc') {
          valA = a.querySelector('.shop-updated-date')?.dataset.timestamp || '';
          valB = b.querySelector('.shop-updated-date')?.dataset.timestamp || '';
        } else if (sortBy === 'nameAsc' || sortBy === 'nameDesc') {
          valA = a.querySelector('.shop-name')?.textContent.toLowerCase() || '';
          valB = b.querySelector('.shop-name')?.textContent.toLowerCase() || '';
        }

        // ✅ Ordre de tri
        const comparison = valA.localeCompare(valB);

        // ✅ Gérer l'ordre ascendant ou descendant
        if (['createdAtDesc', 'updatedAtDesc', 'nameDesc'].includes(sortBy)) {
          return comparison * -1; // Inversement pour les tris décroissants
        }
        return comparison;
      });

      // ✅ Affichage des lignes triées
      tableBody.innerHTML = '';
      filteredRows.forEach((row) => {
        tableBody.appendChild(row);
      });
    }

    // Sélection des éléments
    const selectAllCheckbox = document.getElementById('selectAllCheckbox');
    const shopCheckboxes = () =>
      Array.from(document.querySelectorAll('.shop-checkbox'));

    // ✅ Gestion de la case "Tout sélectionner"
    selectAllCheckbox.addEventListener('change', () => {
      const isChecked = selectAllCheckbox.checked;
      shopCheckboxes().forEach((checkbox) => {
        checkbox.checked = isChecked;
      });
      updateBulkDeleteButton();
    });

    // ✅ Mise à jour du bouton suppression groupée
    function updateBulkDeleteButton() {
      const selected = shopCheckboxes().filter((checkbox) => checkbox.checked);
      bulkDeleteBtn.disabled = selected.length === 0;
      if (selected.length > 0) {
        bulkDeleteBtn.classList.add('btn--delete');
      }
      if (selected.length === 0) {
        bulkDeleteBtn.classList.remove('btn--delete');
      }
    }

    // ✅ Sur changement individuel, mettre à jour la case globale
    tableBody.addEventListener('change', () => {
      const allChecked = shopCheckboxes().every((checkbox) => checkbox.checked);
      const someChecked = shopCheckboxes().some((checkbox) => checkbox.checked);
      selectAllCheckbox.checked = allChecked;
      selectAllCheckbox.indeterminate = someChecked && !allChecked;
      updateBulkDeleteButton();
    });

    // ✅ Suppression Groupée avec confirmation
    bulkDeleteBtn.addEventListener('click', () => {
      if (confirm('Êtes-vous sûr de vouloir supprimer ces boutiques ?')) {
        const selectedIds = shopCheckboxes()
          .filter((checkbox) => checkbox.checked)
          .map((input) => input.closest('tr').dataset.id);

        if (selectedIds.length === 0) {
          alert('Aucune boutique sélectionnée');
          return;
        }

        fetch('/api/v1/shops/bulk-delete', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ids: selectedIds }),
        })
          .then((res) => {
            if (!res.ok) throw new Error(`Erreur HTTP : ${res.status}`);
            return res.json();
          })
          .then((data) => {
            showAlert('success', data.message);
            // ✅ Retirer les lignes supprimées du DOM
            selectedIds.forEach((id) => {
              const row = document.querySelector(`tr[data-id="${id}"]`);
              if (row) row.remove();
            });
            updateBulkDeleteButton();
          })
          .catch((err) => {
            console.error(`❌ Erreur de suppression groupée : ${err.message}`);
            // alert(`Erreur : ${err.message}`);
            showAlert('error', "Vous n'avez pas l'autorisation");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
      }
    });
  });
}

if (userShops) {
  document.addEventListener('DOMContentLoaded', () => {
    // ✅ Suppression Individuelle avec confirmation
    tableBody.addEventListener('click', (e) => {
      if (e.target.classList.contains('btn--delete')) {
        const shopId = e.target.dataset.id;

        if (confirm('Êtes-vous sûr de vouloir supprimer cette boutique ?')) {
          fetch(`/api/v1/shops/${shopId}`, {
            method: 'DELETE',
          })
            .then((res) => {
              if (res.status === 204) {
                // ✅ Gestion de la réponse vide (204)
                return {
                  status: 'success',
                  message: 'Boutique supprimée avec succès',
                };
              }
              if (!res.ok) {
                throw new Error(`Erreur HTTP : ${res.status}`);
              }
              return res.json(); // Pour une réponse JSON standard
            })
            .then((data) => {
              showAlert(
                'success',
                data.message || 'Boutique supprimée avec succès'
              );
              e.target.closest('tr').remove(); // ✅ Supprime directement la ligne
            })
            .catch((err) => {
              console.error(`❌ Erreur de suppression : ${err.message}`);
              alert('Échec de la suppression');
            });
        }
      }
    });

    // ✅ Détection automatique de la langue en fonction de l'URL
    const lang = window.location.pathname.startsWith('/fr') ? 'fr' : 'en';

    // ✅ Définition des textes en fonction de la langue
    const translations = {
      fr: {
        save: 'Sauvegarder',
        edit: 'Modifier',
        successUpdate: 'Boutique mise à jour avec succès',
        errorUpdate: 'Échec de la mise à jour',
      },
      en: {
        save: 'Save',
        edit: 'Edit',
        successUpdate: 'Shop updated successfully',
        errorUpdate: 'Update failed',
      },
    };

    // ✅ Mise à jour de l'édition des boutiques avec la langue détectée
    tableBody.addEventListener('click', (e) => {
      const row = e.target.closest('tr');
      if (!row) return;

      const nameCell = row.querySelector('.shop-name');
      const urlCell = row.querySelector('.shop-url');
      const lastUpdateCell = row.querySelector('.shop-updated-date');
      const editButton = e.target;

      // ✅ Mode Édition
      if (editButton.classList.contains('user-shops__btns--edit')) {
        nameCell.innerHTML = `<input type='text' value='${nameCell.textContent.trim()}' class='shop-edit-name form__input'>`;
        urlCell.innerHTML = `<input type='text' value='${urlCell.textContent.trim()}' class='shop-edit-url form__input'>`;

        nameCell.querySelector('.shop-edit-name').focus();

        editButton.textContent = translations[lang].save;
        editButton.classList.replace(
          'user-shops__btns--edit',
          'user-shops__btns--save'
        );
        return;
      }

      // ✅ Mode Sauvegarde
      if (editButton.classList.contains('user-shops__btns--save')) {
        const shopId = row.dataset.id;
        const newName = nameCell.querySelector('.shop-edit-name').value.trim();
        const newUrl = urlCell.querySelector('.shop-edit-url').value.trim();
        const currentDate = new Date().toISOString();

        fetch(`/api/v1/shops/${shopId}`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: newName,
            url: newUrl,
            lastUpdate: currentDate,
          }),
        })
          .then((res) => {
            if (!res.ok) throw new Error(`HTTP Error: ${res.status}`);
            return res.json();
          })
          .then((data) => {
            nameCell.textContent = newName;
            urlCell.innerHTML = `<a href="https://${newUrl}" target="_blank">${newUrl}</a>`;
            lastUpdateCell.textContent = new Date(
              currentDate
            ).toLocaleDateString(lang === 'fr' ? 'fr-FR' : 'en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });

            editButton.textContent = translations[lang].edit;
            editButton.classList.replace(
              'user-shops__btns--save',
              'user-shops__btns--edit'
            );

            showAlert('success', translations[lang].successUpdate);
          })
          .catch((err) => {
            console.error(`❌ Update error: ${err.message}`);
            showAlert('error', translations[lang].errorUpdate);
          });
      }
    });

    const addShopForm = document.getElementById('addShopForm');

    if (addShopForm) {
      const addShopBtn = document.getElementById('addShopBtn');

      addShopForm.addEventListener('submit', async (e) => {
        e.preventDefault();

        addShopBtn.disabled = true;

        const name = document.getElementById('shopName').value.trim();
        const url = document
          .getElementById('shopUrl')
          .value.trim()
          .replace('https://', '')
          .replace('http://', '')
          .toLowerCase();

        if (!name || !url) {
          alert('Veuillez remplir tous les champs.');
          return;
        }

        try {
          const res = await fetch('/api/v1/shops', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name, url }),
          });

          const data = await res.json();

          if (res.ok) {
            showAlert('success', 'Boutique ajoutée avec succès');
            location.reload();
          } else {
            throw new Error(
              data.message || 'Erreur lors de l’ajout de la boutique'
            );
          }
        } catch (err) {
          console.error(`❌ Erreur : ${err.message}`);
          alert('Échec de l’ajout de la boutique');
        } finally {
          setTimeout(() => {
            addShopBtn.disabled = false;
          }, 3000);
        }
      });
    }
  });
}
