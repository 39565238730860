import { showAlert } from './alerts';

///////////////// #textify

const textify = document.getElementById('textify');
const textifyShopDetails = document.getElementById('textifyShopDetails');

if (textify) {
  document.addEventListener('DOMContentLoaded', function () {
    const step1 = document.querySelector('.step-1');
    const step2 = document.querySelector('.step-2');
    const step3 = document.querySelector('.step-3');

    const brandYesButton = document.getElementById('hasBrandYesButton');
    const brandNoButton = document.getElementById('hasBrandNoButton');
    const brandNameInputSection = document.getElementById('brandNameInput');
    const brandDescriptionSection = document.getElementById(
      'brandDescriptionSection'
    );
    const confirmBrandNameButton = document.getElementById('confirmBrandName');
    const confirmBrandDescriptionButton = document.getElementById(
      'confirmBrandDescription'
    );
    const brandDescriptionTextarea =
      document.getElementById('brandDescription');
    const brandDescriptionLoader = document.getElementById(
      'textifyBrandNameLoader'
    );
    const suggestedNamesButtonsContainer = document.querySelector(
      '.textify__suggested-names--buttons'
    );
    const confirmSuggestedNameButton = document.getElementById(
      'confirmSuggestedName'
    );

    const productYesButton = document.getElementById('hasProductYesButton');
    const productNoButton = document.getElementById('hasProductNoButton');
    const confirmProductNameButton =
      document.getElementById('confirmProductName');
    const productNameInputSection = document.getElementById('productNameInput');
    const productDescriptionSection = document.getElementById(
      'productDescriptionSection'
    );
    const confirmProductDescriptionButton = document.getElementById(
      'confirmProductDescription'
    );
    const productDescriptionTextarea =
      document.getElementById('productDescription');
    const productDescriptionLoader = document.getElementById(
      'textifyProductNameLoader'
    );

    const suggestedProductNamesButtonsContainer = document.querySelector(
      '.textify__suggested-product-names--buttons'
    );
    const confirmSuggestedProductNameButton = document.getElementById(
      'confirmSuggestedProductName'
    );

    const marketingToneSelect = document.getElementById('marketingTone');
    const customToneInput = document.getElementById('customTone');
    const marketingLanguageSelect =
      document.getElementById('marketingLanguage');
    const customLanguageInput = document.getElementById('customLanguage');
    const confirmMarketingToneButton = document.getElementById(
      'confirmMarketingTone'
    );

    const recapBrandName = document.getElementById('recapBrandName');
    const recapBrandDescription = document.getElementById(
      'recapBrandDescription'
    );
    const recapProductName = document.getElementById('recapProductName');
    const recapProductDescription = document.getElementById(
      'recapProductDescription'
    );
    const recapTone = document.getElementById('recapTone');

    let selectedBrandName = '';
    let selectedProductName = '';

    function handleSelection(
      selectedButton,
      otherButton,
      sectionToShow,
      sectionToHide
    ) {
      selectedButton.classList.add('textify__button--selected');
      otherButton.classList.remove('textify__button--selected');
      sectionToShow.classList.remove('hidden');
      sectionToHide.classList.add('hidden');
      selectedButton.disabled = true;
      otherButton.disabled = true;
      selectedButton.classList.add('btn--not-allowed');
      otherButton.classList.add('btn--not-allowed');
    }

    function displaySuggestedNames(
      names,
      container,
      confirmButton,
      isProduct = false
    ) {
      container.innerHTML = '';
      names.forEach((name) => {
        const cleanName = name.replace(/^\d+\.\s*/, '');
        const button = document.createElement('button');
        button.className = 'btn textify__button';
        button.type = 'button';
        button.textContent = cleanName;
        button.addEventListener('click', () => {
          container
            .querySelectorAll('.textify__button--selected')
            .forEach((btn) =>
              btn.classList.remove('textify__button--selected')
            );
          button.classList.add('textify__button--selected');
          confirmButton.classList.remove('hidden');
          if (isProduct) {
            selectedProductName = cleanName;
            recapProductName.textContent = selectedProductName;
          } else {
            selectedBrandName = cleanName;
            recapBrandName.textContent = selectedBrandName;
          }
        });
        container.appendChild(button);
      });
      brandDescriptionLoader.classList.add('hidden');
      productDescriptionLoader.classList.add('hidden');
      container.parentNode.classList.remove('hidden');
    }

    async function fetchSuggestedNames(
      endpoint,
      description,
      container,
      confirmButton,
      isProduct = false
    ) {
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ description }),
        });
        if (!response.ok) throw new Error("Erreur lors de l'appel à l'API");
        const result = await response.json();
        displaySuggestedNames(result.data, container, confirmButton, isProduct);
      } catch (error) {
        console.error('Erreur :', error);
        alert('Erreur lors de la génération des suggestions de nom');
      }
    }

    // Fonction pour mettre à jour le texte avec des retours à la ligne
    function updateRecapTextWithLineBreaks(element, text) {
      element.innerHTML = text.replace(/\n/g, '<br>');
    }

    brandYesButton.addEventListener('click', () =>
      handleSelection(
        brandYesButton,
        brandNoButton,
        brandNameInputSection,
        brandDescriptionSection
      )
    );
    brandNoButton.addEventListener('click', () =>
      handleSelection(
        brandNoButton,
        brandYesButton,
        brandDescriptionSection,
        brandNameInputSection
      )
    );

    confirmBrandNameButton.addEventListener('click', () => {
      recapBrandName.textContent = document
        .getElementById('brandName')
        .value.trim();
      brandDescriptionSection.classList.remove('hidden');
      brandNameInputSection.classList.add('hidden');
    });

    confirmBrandDescriptionButton.addEventListener('click', () => {
      const description = brandDescriptionTextarea.value.trim();
      if (description.length < 50 || description.length > 3000) {
        showAlert(
          'error',
          'La description doit contenir entre 50 et 3000 caractères.'
        );
        return;
      }
      updateRecapTextWithLineBreaks(recapBrandDescription, description);

      if (brandNoButton.classList.contains('textify__button--selected')) {
        fetchSuggestedNames(
          '/api/v1/textifyshop/brand-names',
          description,
          suggestedNamesButtonsContainer,
          confirmSuggestedNameButton
        );

        brandDescriptionSection.classList.add('hidden');
        brandDescriptionLoader.classList.remove('hidden');
      } else {
        proceedToStep2();
      }
    });

    confirmSuggestedNameButton.addEventListener('click', proceedToStep2);

    // Mise à jour pour le bouton de validation du nom du produit
    confirmProductNameButton.addEventListener('click', () => {
      selectedProductName = document.getElementById('productName').value.trim();
      if (selectedProductName.length < 3) {
        alert('Le nom du produit doit comporter au moins 3 caractères.');
        return;
      }
      recapProductName.textContent = selectedProductName;
      productDescriptionSection.classList.remove('hidden');
      productNameInputSection.classList.add('hidden');
    });

    productYesButton.addEventListener('click', () =>
      handleSelection(
        productYesButton,
        productNoButton,
        productNameInputSection,
        productDescriptionSection
      )
    );
    productNoButton.addEventListener('click', () =>
      handleSelection(
        productNoButton,
        productYesButton,
        productDescriptionSection,
        productNameInputSection
      )
    );

    confirmProductDescriptionButton.addEventListener('click', () => {
      const productDescription = productDescriptionTextarea.value.trim();
      if (productDescription.length < 50 || productDescription.length > 3000) {
        alert('La description doit contenir entre 50 et 3000 caractères.');
        return;
      }
      updateRecapTextWithLineBreaks(
        recapProductDescription,
        productDescription
      );

      if (productNoButton.classList.contains('textify__button--selected')) {
        const combinedDescription = `${brandDescriptionTextarea.value} ${selectedBrandName} ${productDescription}`;
        fetchSuggestedNames(
          '/api/v1/textifyshop/product-names',
          combinedDescription,
          suggestedProductNamesButtonsContainer,
          confirmSuggestedProductNameButton,
          true
        );
        productDescriptionSection.classList.add('hidden');
        productDescriptionLoader.classList.remove('hidden');
      } else {
        proceedToStep3();
      }
    });

    confirmSuggestedProductNameButton.addEventListener('click', proceedToStep3);

    marketingToneSelect.addEventListener('change', () => {
      if (
        marketingToneSelect.value === 'Autre' ||
        marketingToneSelect.value === 'Other'
      ) {
        customToneInput.classList.remove('hidden');
      } else {
        customToneInput.classList.add('hidden');
        customToneInput.value = '';
      }
    });

    marketingLanguageSelect.addEventListener('change', () => {
      if (
        marketingLanguageSelect.value === 'Autre' ||
        marketingLanguageSelect.value === 'Other'
      ) {
        customLanguageInput.classList.remove('hidden');
      } else {
        customLanguageInput.classList.add('hidden');
        customLanguageInput.value = '';
      }
    });

    confirmMarketingToneButton.addEventListener('click', async () => {
      const selectedTone =
        marketingToneSelect.value === 'Autre' ||
        marketingToneSelect.value === 'Other'
          ? customToneInput.value
          : marketingToneSelect.value;
      recapTone.textContent = selectedTone;

      const brandName =
        selectedBrandName || document.getElementById('brandName').value.trim();
      const brandDescription = brandDescriptionTextarea.value.trim();
      const productName =
        selectedProductName ||
        document.getElementById('productName').value.trim();
      const productDescription = productDescriptionTextarea.value.trim();

      // Récupération de la langue sélectionnée
      const marketingLanguageSelect =
        document.getElementById('marketingLanguage');
      const selectedLanguage =
        marketingLanguageSelect.value === 'Autre' ||
        marketingLanguageSelect.value === 'Other'
          ? document.getElementById('customLanguage').value.trim()
          : marketingLanguageSelect.value;

      // Structure de l'objet avec marketingTone et marketingLanguage comme chaînes de caractères
      const payload = {
        brandName,
        brandDescription,
        productName,
        productDescription,
        marketingTone: selectedTone,
        marketingLanguage: selectedLanguage, // Ajout de la langue
      };

      if (
        !brandName ||
        !brandDescription ||
        !productName ||
        !productDescription ||
        !selectedLanguage // Validation supplémentaire pour la langue
      ) {
        alert('Tous les champs doivent être remplis.');
        return;
      }

      try {
        const response = await fetch(
          '/api/v1/textifyshop/create-shop-with-product',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const result = await response.json();

          window.location.href = `/textify/shop/${result.data.shop._id}`;
        } else {
          const errorDetails = await response.json();
          console.log(
            "Erreur lors de l'enregistrement, détails :",
            errorDetails
          );
          alert(
            `Erreur lors de l’enregistrement : ${
              errorDetails.message || 'Vérifiez les champs'
            }`
          );
        }
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
        alert('Erreur lors de la requête');
      }
    });

    function proceedToStep2() {
      step1.classList.add('hidden');
      step2.classList.remove('hidden');
    }

    function proceedToStep3() {
      step2.classList.add('hidden');
      step3.classList.remove('hidden');
    }
  });
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// #textifyShopDetails
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

if (textifyShopDetails) {
  const generateHomeTextsBtn = document.getElementById(
    'generateHomeTextsButton'
  );
  const generatedTextsContainer = document.querySelector(
    '.textify__brand--generation'
  );
  const homeTextsLoader = document.getElementById('textifyHomeTextsLoader');
  const generatedTextsContent = document.querySelector(
    '.textify__brand--generation-container'
  );

  const generationCountElement = document.getElementById('generationCount');

  function incrementGenerationCount() {
    const currentCount = parseInt(generationCountElement.textContent);
    generationCountElement.textContent = `${currentCount + 1}/100`;
  }

  generateHomeTextsBtn.addEventListener('click', async () => {
    try {
      homeTextsLoader.classList.remove('hidden');
      generateHomeTextsBtn.classList.add('hidden');
      generatedTextsContent.classList.add('hidden');
      incrementGenerationCount();

      const brandName = document.querySelector(
        '.form__brandName--title'
      ).textContent;
      const brandDescription = document.querySelector(
        '.textify__content--generation p'
      ).textContent;
      const shopId = generateHomeTextsBtn.getAttribute('data-id');

      const payload = {
        shopId,
        brandDescription,
        brandName,
      };
      const response = await fetch('/api/v1/textifyshop/generate-home-texts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok)
        throw new Error('Erreur lors de la génération des textes.');

      const result = await response.json();
      const generatedTexts = result.data;

      // Efface les anciens contenus générés avant d'ajouter les nouveaux
      generatedTextsContainer.innerHTML = '';

      function createElementWithClass(tag, className, textContent = '') {
        const element = document.createElement(tag);
        element.className = className;
        element.textContent = textContent;
        return element;
      }

      const sections = [
        {
          title: 'Bannière',
          className: 'banner', // Classe explicite pour bannière
          fields: ['banner.headline', 'banner.description'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Bannière</h2>
          <h3 class="banner-title mb-xs">${data.headline || ''}</h3>
          <p class="banner-description">${data.description || ''}</p>
        `,
        },
        {
          title: 'Texte défilant',
          className: 'scrollingText', // Classe explicite pour le texte défilant
          fields: ['scrollingText.offer'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Texte défilant</h2>
          <p class="scrollingText-offer">${data.offer || ''}</p>
        `,
        },
        {
          title: 'Collection en vedette',
          className: 'featuredCollection', // Classe explicite pour la collection en vedette
          fields: [
            'featuredCollection.title',
            'featuredCollection.description',
          ],
          render: (data) => `
          <h2 class="textify__brand--section-title">Collection en vedette</h2>
          <h3 class="featuredCollection-title mb-xs">${data.title || ''}</h3>
          <p class="featuredCollection-description">${
            data.description || ''
          }</p>
        `,
        },
        {
          title: 'Image avec texte 1 (Problèmes rencontrés)',
          className: 'imageTextSection1', // Classe explicite pour la première image texte
          fields: [
            'imageTextSection1.title',
            'imageTextSection1.description',
            'imageTextSection1.icons',
            'imageTextSection1.buttonText',
          ],
          render: (data) => `
          <h2 class="textify__brand--section-title">Image avec texte 1 (Problèmes rencontrés)</h2>
          <h3 class="imageTextSection1-title mb-xs">${data.title || ''}</h3>
          <p class="imageTextSection1-description mb-xs">${
            data.description || ''
          }</p>
          <div class="imageTextSection1-icons mb-xs">
            ${(data.icons || [])
              .map((icon) => `<p class="imageTextSection1-icon">${icon}</p>`)
              .join('')}
          </div>
          <p class="imageTextSection1-button btn">${data.buttonText || ''}</p>
        `,
        },
        {
          title: 'Image avec texte 2 (Solution)',
          className: 'imageTextSection2', // Classe explicite pour la deuxième image texte
          fields: [
            'imageTextSection2.title',
            'imageTextSection2.description',
            'imageTextSection2.icons',
            'imageTextSection2.buttonText',
          ],
          render: (data) => `
          <h2 class="textify__brand--section-title">Image avec texte 2 (Solution)</h2>
          <h3 class="imageTextSection2-title mb-xs">${data.title || ''}</h3>
          <p class="imageTextSection2-description mb-xs">${
            data.description || ''
          }</p>
          <div class="imageTextSection2-icons mb-xs">
            ${(data.icons || [])
              .map((icon) => `<p class="imageTextSection2-icon">${icon}</p>`)
              .join('')}
          </div>
          <p class="imageTextSection2-button btn">${data.buttonText || ''}</p>
        `,
        },
        {
          title: 'Image avec texte 3 (Valeurs de la marque)',
          className: 'imageTextSection3', // Classe explicite pour la troisième image texte
          fields: [
            'imageTextSection3.title',
            'imageTextSection3.description',
            'imageTextSection3.icons',
            'imageTextSection3.buttonText',
          ],
          render: (data) => `
          <h2 class="textify__brand--section-title">Image avec texte 3 (Valeurs de la marque)</h2>
          <h3 class="imageTextSection3-title mb-xs">${data.title || ''}</h3>
          <p class="imageTextSection3-description mb-xs">${
            data.description || ''
          }</p>
          <div class="imageTextSection3-icons mb-xs">
            ${(data.icons || [])
              .map((icon) => `<p class="imageTextSection3-icon">${icon}</p>`)
              .join('')}
          </div>
          <p class="imageTextSection3-button btn">${data.buttonText || ''}</p>
        `,
        },
        {
          title: 'Icônes de confiance',
          className: 'trustIcons', // Classe explicite pour les icônes de confiance
          fields: ['trustIcons'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Icônes de confiance</h2>
          ${(data || [])
            .map(
              (icon) => `
            <h4 class="trustIcons-title">${icon.title || ''}</h4>
            <p class="trustIcons-description mb-xs">${
              icon.description || ''
            }</p>
          `
            )
            .join('')}
        `,
        },
        {
          title: 'Newsletter',
          className: 'newsletter', // Classe explicite pour la section newsletter
          fields: ['newsletter.title', 'newsletter.description'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Newsletter</h2>
          <h3 class="newsletter-title">${data.title || ''}</h3>
          <p class="newsletter-description">${data.description || ''}</p>
        `,
        },
        {
          title: 'Avis clients',
          className: 'customerReviews', // Classe explicite pour les avis clients
          fields: ['customerReviews'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Avis clients</h2>
          ${(data || [])
            .map(
              (review) => `
            <h4 class="customerReviews-title">${review.title || ''}</h4>
            <p class="customerReviews-description mb-xs">${
              review.description || ''
            }</p>
          `
            )
            .join('')}
        `,
        },
        {
          title: 'Footer',
          className: 'footer', // Classe explicite pour le footer
          fields: ['footer.slogan'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Footer</h2>
          <h3 class="footer-title">Logo</h3>
          <p class="footer-slogan">${data.slogan || ''}</p>
        `,
        },
        {
          title: 'Angles marketing et bénéfices',
          className: 'marketingAngles', // Classe explicite pour les angles marketing
          fields: ['marketingAngles'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Angles marketing et bénéfices</h2>
          <ul class="marketingAngles-angles">
            ${(data || [])
              .map((angle) => `<li class="marketingAngles-angle">${angle}</li>`)
              .join('')}
          </ul>
        `,
        },
        {
          title: 'Bénéfices produits',
          className: 'productBenefits', // Classe explicite pour les bénéfices produits
          fields: ['productBenefits'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Bénéfices produits</h2>
          <ul class="productBenefits-benefits">
            ${(data || [])
              .map(
                (benefit) =>
                  `<li class="productBenefits-benefit mb-xs">${benefit}</li>`
              )
              .join('')}
          </ul>
        `,
        },
        {
          title: 'Avatars clients',
          className: 'customerAvatars', // Classe explicite pour les avatars clients
          fields: ['customerAvatars'],
          render: (data) => `
          <h2 class="textify__brand--section-title">Avatars clients</h2>
          <ul class="customerAvatars-avatars">
            ${(data || [])
              .map(
                (avatar) =>
                  `<li class="customerAvatars-avatar mb-xs">${avatar}</li>`
              )
              .join('')}
          </ul>
        `,
        },
      ];

      // Génère chaque section avec la classe définie
      sections.forEach((section) => {
        generatedTextsContainer.insertAdjacentHTML(
          'beforeend',
          section.render(generatedTexts[section.className] || {})
        );
      });
    } catch (error) {
      console.error('Erreur lors de la génération des textes :', error);
      alert('Erreur lors de la génération des textes.');
    }
  });

  document.addEventListener('DOMContentLoaded', () => {
    const overlay = document.querySelector('.overlay');
    const addProctModal = document.getElementById('addProductModal');

    // Fonction pour ouvrir la modal
    function openModal() {
      addProctModal.classList.remove('hidden');
      overlay.classList.remove('hidden');
      document.body.classList.add('no-scroll');
    }

    // Fonction pour fermer la modal
    function closeModal() {
      addProctModal.classList.add('hidden');
      overlay.classList.add('hidden');
      document.body.classList.remove('no-scroll');
    }

    // Attacher l'événement click au bouton "Ajouter un produit"
    document
      .getElementById('addProductButton')
      .addEventListener('click', openModal);

    // Attacher l'événement click au bouton de fermeture de la modal
    document
      .querySelector('.close-modal')
      .addEventListener('click', closeModal);

    overlay.addEventListener('click', closeModal);

    // Gestion de l'envoi du formulaire pour ajouter un produit
    document
      .getElementById('addProductForm')
      .addEventListener('submit', async function (e) {
        e.preventDefault();

        // Récupère les données du formulaire
        const productName = document.getElementById('productName').value;
        const productDescription =
          document.getElementById('productDescription').value;

        // Vérifie que la description respecte les limites de caractères
        if (
          productDescription.length < 50 ||
          productDescription.length > 4000
        ) {
          alert('La description doit contenir entre 50 et 4000 caractères.');
          return;
        }

        const shopId = document
          .querySelector('#deleteTextifyShopButton')
          .getAttribute('data-id');

        // Envoie les données au serveur
        try {
          const response = await fetch('/api/v1/textifyshop/create-product', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              productName,
              productDescription,
              shopId,
            }),
          });

          if (!response.ok) throw new Error('La création du produit a échoué.');

          // Redirige vers la page du nouveau produit ou recharge la page
          const data = await response.json();
          if (data.status === 'success') {
            window.location.href = `/textify/product/${data.data.product._id}`;
          }
        } catch (error) {
          console.error('Erreur lors de la création du produit:', error);
          alert("Une erreur s'est produite lors de la création du produit.");
        }
      });
  });

  // Suppression d'une boutique
  document
    .getElementById('deleteTextifyShopButton')
    .addEventListener('click', async () => {
      const button = document.getElementById('deleteTextifyShopButton');
      const shopId = button.getAttribute('data-id');

      if (!shopId) {
        console.error('ID de la marque introuvable');
        alert(
          "Une erreur est survenue. Impossible de trouver l'ID de la marque."
        );
        return;
      }

      if (confirm(`Êtes-vous sûr de vouloir supprimer cette marque ?`)) {
        try {
          const response = await fetch(`/api/v1/textifyshop/shop/${shopId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok)
            throw new Error('Erreur lors de la suppression de la marque.');

          showAlert('success', 'La marque a été supprimée avec succès.');
          setTimeout(() => {
            window.location.href = '/textify';
          }, 1500);
        } catch (error) {
          console.error('Erreur lors de la suppression :', error);
          alert('Une erreur est survenue. La suppression a échoué.');
        }
      }
    });
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// #textifyProductDetails
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const textifyProductDetails = document.getElementById('textifyProductDetails');

if (textifyProductDetails) {
  const generateProductTextsBtn = document.getElementById(
    'generateProductTextsButton'
  );
  const generatedProductTextsContainer = document.querySelector(
    '.textify__product--generation'
  );
  const productTextsLoader = document.getElementById(
    'textifyProductTextsLoader'
  );
  const generatedProductTextsContent = document.querySelector(
    '.textify__product--generation-container'
  );

  const generationCountElement = document.getElementById('generationCount');

  function incrementGenerationCount() {
    const currentCount = parseInt(generationCountElement.textContent);
    generationCountElement.textContent = `${currentCount + 1}/100`;
  }

  generateProductTextsBtn.addEventListener('click', async () => {
    try {
      // Afficher le loader et masquer le bouton
      productTextsLoader.classList.remove('hidden');
      generateProductTextsBtn.classList.add('hidden');
      generatedProductTextsContent.classList.add('hidden');
      incrementGenerationCount();

      // Récupérer les informations du produit et de la marque
      const productName = document.querySelector(
        '.form__productName--title'
      ).textContent;
      const productDescription = document.querySelector(
        '.textify__content--generation p'
      ).textContent;
      const productId = generateProductTextsBtn.getAttribute('data-id');

      // Payload pour la requête
      const payload = {
        productId,
        productDescription,
        productName,
      };

      // Requête pour générer les textes du produit
      const response = await fetch(
        '/api/v1/textifyproduct/generate-product-texts',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok)
        throw new Error(`Erreur ${response.status}: ${response.statusText}`);

      const result = await response.json();
      const generatedTexts = result.data;

      // Efface les anciens contenus générés avant d'ajouter les nouveaux
      generatedProductTextsContainer.innerHTML = '';

      const sections = [
        {
          title: "Texte d'accroche",
          className: 'banner',
          render: (data) => `
          <h2 class="textify__product--section-title">Texte d'accroche</h2>
          <h3 class="banner-title mb-xs">${data.headline || ''}</h3>
          <p class="banner-description">${data.description || ''}</p>
        `,
        },
        {
          title: 'Problèmes rencontrés',
          className: 'problemSection',
          render: (data) => `
          <h2 class="textify__product--section-title">Problèmes rencontrés</h2>
          <h3 class="problemSection-title mb-xs">${data.title || ''}</h3>
          <p class="problemSection-description mb-xs">${
            data.description || ''
          }</p>
          <div class="problemSection-icons mb-xs">
            ${(data.icons || [])
              .map((icon) => `<p class="problemSection-icon">${icon}</p>`)
              .join('')}
          </div>
          <p class="problemSection-button btn">${data.buttonText || ''}</p>
        `,
        },
        {
          title: 'Solution proposée',
          className: 'solutionSection',
          render: (data) => `
          <h2 class="textify__product--section-title">Solution proposée</h2>
          <h3 class="solutionSection-title mb-xs">${data.title || ''}</h3>
          <p class="solutionSection-description mb-xs">${
            data.description || ''
          }</p>
          <div class="solutionSection-icons mb-xs">
            ${(data.icons || [])
              .map((icon) => `<p class="solutionSection-icon">${icon}</p>`)
              .join('')}
          </div>
          <p class="solutionSection-button btn">${data.buttonText || ''}</p>
        `,
        },
        {
          title: 'Bénéfices supplémentaires',
          className: 'benefitSection',
          render: (data) => `
          <h2 class="textify__product--section-title">Bénéfices supplémentaires</h2>
          <h3 class="benefitSection-title mb-xs">${data.title || ''}</h3>
          <p class="benefitSection-description mb-xs">${
            data.description || ''
          }</p>
          <div class="benefitSection-icons mb-xs">
            ${(data.icons || [])
              .map((icon) => `<p class="benefitSection-icon">${icon}</p>`)
              .join('')}
          </div>
          <p class="benefitSection-button btn">${data.buttonText || ''}</p>
        `,
        },
        {
          title: 'Angles marketing clés',
          className: 'marketingAngles',
          render: (data) => `
          <h2 class="textify__product--section-title">Angles marketing clés</h2>
          <ul class="marketingAngles-list">
            ${(data || [])
              .map((angle) => `<li class="marketingAngles-item">${angle}</li>`)
              .join('')}
          </ul>
        `,
        },
        {
          title: 'Bénéfices du produit',
          className: 'productBenefits',
          render: (data) => `
          <h2 class="textify__product--section-title">Bénéfices du produit</h2>
          <ul class="productBenefits-list">
            ${(data || [])
              .map(
                (benefit) => `<li class="productBenefits-item">${benefit}</li>`
              )
              .join('')}
          </ul>
        `,
        },
      ];

      // Génération dynamique des sections de contenu dans le conteneur
      sections.forEach((section) => {
        generatedProductTextsContainer.insertAdjacentHTML(
          'beforeend',
          section.render(generatedTexts[section.className] || {})
        );
      });

      // Masquer le loader et afficher le contenu généré
      productTextsLoader.classList.add('hidden');
      generatedProductTextsContent.classList.remove('hidden');
    } catch (error) {
      console.error(
        'Erreur lors de la génération des textes du produit:',
        error
      );
      alert('Erreur lors de la génération des textes du produit.');
    }
  });

  document.addEventListener('DOMContentLoaded', () => {
    const deleteButton = document.getElementById('deleteTextifyProductButton');
    const shopId = document
      .querySelector('.textify__button--back')
      .getAttribute('data-shop-id');

    deleteButton.addEventListener('click', async () => {
      const productId = deleteButton.getAttribute('data-id');

      if (!confirm(`Êtes-vous sûr de vouloir supprimer ce produit ?`)) {
        return; // Arrête la suppression si l'utilisateur annule
      }

      try {
        const response = await fetch(
          `/api/v1/textifyproduct/product/${productId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok)
          throw new Error('La suppression du produit a échoué.');

        showAlert('success', 'Produit supprimé avec succès');
        setTimeout(() => {
          window.location.href = `/textify/shop/${shopId}`;
        }, 1500);
      } catch (error) {
        console.error('Erreur lors de la suppression du produit :', error);
        alert("Une erreur s'est produite lors de la suppression du produit.");
      }
    });
  });
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// all
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

if (textifyShopDetails || textifyProductDetails) {
  const editIcons = document.querySelectorAll('.form__icon--edit');
  const confirmIcons = document.querySelectorAll('.form__icon--confirm');

  function toggleIcons(editIcon, confirmIcon, title, inputWrapper, showInput) {
    if (showInput) {
      editIcon.classList.add('hidden');
      confirmIcon.classList.remove('hidden');
      title.classList.add('hidden');
      inputWrapper.classList.remove('hidden');
    } else {
      editIcon.classList.remove('hidden');
      confirmIcon.classList.add('hidden');
      title.classList.remove('hidden');
      inputWrapper.classList.add('hidden');

      const inputElement = inputWrapper.querySelector(
        'input, select, textarea'
      );
      if (inputElement) {
        if (inputElement.tagName === 'SELECT') {
          title.textContent =
            inputElement.value === 'Autre' || inputElement.value === 'Other'
              ? document.getElementById(
                  inputElement.id === 'language'
                    ? 'customLanguage'
                    : 'customTone'
                ).value
              : inputElement.options[inputElement.selectedIndex]?.text || '';
        } else {
          title.textContent = inputElement.value || '';
        }
      }
    }
  }

  async function saveToDatabase(field, value) {
    try {
      let id, endpoint, requestData;

      if (textifyShopDetails) {
        // Logique pour TextifyShop
        id = document
          .querySelector('#deleteTextifyShopButton')
          .getAttribute('data-id');
        if (!id)
          throw new Error(
            `ID de TextifyShop introuvable pour le champ ${field}`
          );

        endpoint = `/api/v1/textifyshop/shop/${id}`;

        switch (field) {
          case 'brandName':
            requestData = { 'brand.name': value };
            break;
          case 'tone':
            requestData = { 'marketingTone.tone': value };
            break;
          case 'language':
            requestData = { language: value };
            break;
          case 'description':
            requestData = { 'brand.description': value };
            break;
          default:
            requestData = { [field]: value };
        }
      } else if (textifyProductDetails) {
        // Logique pour TextifyProduct
        id = document
          .querySelector('#generateProductTextsButton')
          .getAttribute('data-id');
        if (!id)
          throw new Error(
            `ID de TextifyProduct introuvable pour le champ ${field}`
          );

        // Vérification pour le champ à mettre à jour
        if (field === 'language' || field === 'tone') {
          // On utilise l'ID du shop et on met à jour dans le TextifyShop
          const shopId = document
            .querySelector('.textify__button--back')
            .getAttribute('data-shop-id');
          endpoint = `/api/v1/textifyshop/shop/${shopId}`;

          requestData =
            field === 'tone'
              ? { 'marketingTone.tone': value }
              : { language: value };
        } else {
          // Cas standard pour les autres champs du produit
          endpoint = `/api/v1/textifyproduct/product/${id}`;
          requestData =
            field === 'productName' ? { name: value } : { [field]: value };
        }
      }

      const response = await fetch(endpoint, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) throw new Error('La mise à jour a échoué');
    } catch (error) {
      console.error(
        'Erreur lors de la sauvegarde dans la base de données:',
        error
      );
      alert("Une erreur s'est produite lors de la sauvegarde.");
    }
  }

  function handleCustomInputDisplay(selectElement, customInputElement) {
    if (selectElement.value === 'Autre' || selectElement.value === 'Other') {
      customInputElement.classList.remove('hidden');
    } else {
      customInputElement.classList.add('hidden');
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
    const languageSelect = document.getElementById('language');
    const toneSelect = document.getElementById('tone');
    const customLanguageInput = document.getElementById('customLanguage');
    const customToneInput = document.getElementById('customTone');
    handleCustomInputDisplay(languageSelect, customLanguageInput);
    handleCustomInputDisplay(toneSelect, customToneInput);
  });

  document.getElementById('language').addEventListener('change', function () {
    const customLanguageInput = document.getElementById('customLanguage');
    handleCustomInputDisplay(this, customLanguageInput);
  });

  document.getElementById('tone').addEventListener('change', function () {
    const customToneInput = document.getElementById('customTone');
    handleCustomInputDisplay(this, customToneInput);
  });

  editIcons.forEach((editIcon) => {
    editIcon.addEventListener('click', () => {
      const inputData = editIcon.getAttribute('data-input');
      const confirmIcon = document.querySelector(
        `.form__icon--confirm[data-input="${inputData}"]`
      );
      const title = document.querySelector(`.form__${inputData}--title`);
      const inputWrapper = document.querySelector(`.form__${inputData}--input`);
      toggleIcons(editIcon, confirmIcon, title, inputWrapper, true);
    });
  });

  confirmIcons.forEach((confirmIcon) => {
    confirmIcon.addEventListener('click', () => {
      const inputData = confirmIcon.getAttribute('data-input');
      const editIcon = document.querySelector(
        `.form__icon--edit[data-input="${inputData}"]`
      );
      const title = document.querySelector(`.form__${inputData}--title`);
      const inputWrapper = document.querySelector(`.form__${inputData}--input`);

      const inputElement = inputWrapper.querySelector(
        'input, select, textarea'
      );
      let newValue = '';

      if (inputElement.tagName === 'SELECT') {
        newValue =
          inputElement.value === 'Autre' || inputElement.value === 'Other'
            ? document.getElementById(
                inputElement.id === 'language' ? 'customLanguage' : 'customTone'
              ).value
            : inputElement.value;
      } else {
        newValue = inputElement.value.trim();
      }

      newValue = newValue.replace(/^"+|"+$/g, '');

      // Vérification pour voir si la valeur a changé
      if (title.textContent === newValue) {
        // Si aucune modification, restaurer l'affichage sans appeler saveToDatabase
        toggleIcons(editIcon, confirmIcon, title, inputWrapper, false);
      } else {
        // Si modification, appeler saveToDatabase
        toggleIcons(editIcon, confirmIcon, title, inputWrapper, false);
        saveToDatabase(inputData, newValue);
      }
    });
  });
}
