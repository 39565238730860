import './salePage.js';

import './index.js';
import './newsletter.js';

import './colorify.js';
import './colorifyFR.js';
import './imageTool.js';
import './imageToolFR.js';
import './textify.js';
