/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const updateUserData = async (data, userId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${userId}`,
      data,
    });

    if (res.data.status === 'success') {
      showAlert('success', `Data updated successfully!`);
      window.setTimeout(() => {
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateAddressOnStripe = async (data, customerId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/updateOnStripe/${customerId}`,
      data,
    });

    if (res.data.status === 'success') {
      showAlert('success', `Data updated successfully on Stripe!`);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updatePassword = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/updateMyPassword',
      data,
    });

    if (res.data.status === 'success') {
      showAlert('success', `Password updated successfully!`);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const resetPassword = async (data, token) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/resetPassword/${token}`,
      data,
    });

    if (res.data.status === 'success') {
      showAlert('success', `Password updated successfully!`);
      window.setTimeout(() => {
        location.assign('/login');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateUserVat = async (vatNumber, userId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${userId}`,
      data: {
        vatNumber,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', `VAT Number updated successfully!`);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
