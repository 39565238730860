import './salePage.js';

import './index.js';
import './newsletter.js';
import './optin.js';

import './shopsManagement.js';
import './adminShopsManagement.js';

import './docsAdmin.js';

import './colorify.js';
import './colorifyFR.js';
import './imageTool.js';
import './imageToolFR.js';
import './textify.js';

import './test.js';
