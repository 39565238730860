/* eslint-disable */
/************************* SHOPS *************************/
export const searchShops = async () => {
  const searchResult = document.querySelector('.table--shops');
  let dataArr;

  const getShops = async () => {
    const res = await fetch('/api/v1/shops');
    const preShops = await res.json();
    dataArr = preShops.data.data.sort((a, b) => b.visits - a.visits);
    console.log(dataArr);
    console.log(preShops);
    createShopList(dataArr);
  };

  getShops();

  const createShopList = (shopsList) => {
    shopsList.forEach((shop) => {
      const listItem = document.createElement('div');
      listItem.setAttribute('class', 'table--details');

      listItem.innerHTML = `
        <div class="table--item"><a href="/users/${shop.user[0].id}/shops/${shop.id}">${shop.name}</a></div>
        <div class="table--item"><a href="https://${shop.url}" target="_blank">${shop.url}</a></div>
        <div class="table--item"><p>${shop.visits}</p></div>
        <div class="table--item"><p>${shop.user[0].email}</p></div>
        <div class="table--item"><a href="/users/${shop.user[0].id}">${shop.user[0].name}</a></div>
        <div class="table--item"><p>${shop.user[0].state}</p></div>
        <div class="table--item"><p>${shop.user[0].maxShops}</p></div>
        <div class="table--item"><a href="/shops/${shop.id}">Details</a></div>
      `;

      searchResult.appendChild(listItem);
    });
  };

  const filterView = (e) => {
    const tableHeader = document.querySelector('.table--header');
    searchResult.innerHTML = '';
    searchResult.appendChild(tableHeader);

    const searchedString = e.target.value.toLowerCase();
    const filteredArr = dataArr.filter(
      (el) =>
        el.name.toLowerCase().includes(searchedString) ||
        el.url.toLowerCase().includes(searchedString)
    );

    createShopList(filteredArr);
  };

  document.querySelector('#search-shops').addEventListener('input', filterView);
};

/************************* USERS *************************/

// export const searchUsers = async () => {
//   const searchResult = document.querySelector('.table--users');
//   let dataArr;

//   const getUsers = async () => {
//     const res = await fetch('/api/v1/users');
//     const preUsers = await res.json();
//     dataArr = preUsers.data.data;

//     dataArr.reverse();

//     createUserList(dataArr);
//   };

//   getUsers();

//   const createUserList = (usersList) => {
//     usersList.forEach((user) => {
//       const listItem = document.createElement('div');
//       listItem.setAttribute('class', 'table--details');

//       listItem.innerHTML = `
//         <div class="table--item"><a href="/users/${user.id}">${user.name}</a></div>
//         <div class="table--item"><p>${user.email}</p></div>
//         <div class="table--item"><p>${user.maxShops}</p></div>
//         <div class="table--item"><p>${user.subscriptionAmount}</p></div>
//         <div class="table--item"><p>${user.state}</p></div>
//         <div class="table--item"><p>${user.rank}</p></div>
//         <div class="table--item"><p>${user.subscriptionId}</p></div>
//         <div class="table--item"><img class="table--photo" src=https://storytheme.s3.eu-central-1.amazonaws.com/img/users/${user.photo}></div>
//       `;
//       searchResult.appendChild(listItem);
//     });
//   };

//   const filterView = (e) => {
//     const tableHeader = document.querySelector('.table--header');
//     searchResult.innerHTML = '';
//     searchResult.appendChild(tableHeader);

//     const searchedString = e.target.value.toLowerCase();
//     const filteredArr = dataArr.filter(
//       (el) =>
//         el.name.toLowerCase().includes(searchedString) ||
//         el.email.toLowerCase().includes(searchedString)
//     );

//     createUserList(filteredArr);
//   };

//   document.querySelector('#search-users').addEventListener('input', filterView);
// };

export const searchUsers = async () => {
  const searchResult = document.querySelector('.table--users');
  const paginationPrev = document.querySelector('.pagination__prev'); // Bouton page précédente
  const paginationNext = document.querySelector('.pagination__next'); // Bouton page suivante
  const paginationInfo = document.querySelector('.pagination__info'); // Information sur la page actuelle
  let dataArr;
  let currentPage = 1;
  const itemsPerPage = 50; // Nombre d'éléments par page, ajustez selon vos besoins

  const updatePaginationInfo = () => {
    const totalPages = Math.ceil(dataArr.length / itemsPerPage);
    paginationInfo.textContent = `Page ${currentPage} sur ${totalPages}`;
  };

  console.log('hey');

  const paginate = (array, page, perPage) =>
    array.slice((page - 1) * perPage, page * perPage);

  const createUserList = (usersList) => {
    searchResult.innerHTML = ''; // Assurez-vous de conserver l'en-tête du tableau si nécessaire
    const usersToShow = paginate(usersList, currentPage, itemsPerPage);
    usersToShow.forEach((user) => {
      const listItem = document.createElement('div');
      listItem.setAttribute('class', 'table--details');
      listItem.innerHTML = `
        <div class="table--item"><a href="/users/${user.id}">${user.name}</a></div>
        <div class="table--item"><p>${user.email}</p></div>
        <div class="table--item"><p>${user.maxShops}</p></div>
        <div class="table--item"><p>${user.subscriptionAmount}</p></div>
        <div class="table--item"><p>${user.state}</p></div>
        <div class="table--item"><p>${user.rank}</p></div>
        <div class="table--item"><p>${user.subscriptionId}</p></div>
        <div class="table--item"><img class="table--photo" src="https://storytheme.s3.eu-central-1.amazonaws.com/img/users/${user.photo}"></div>
      `;
      searchResult.appendChild(listItem);
    });
    updatePaginationInfo();
  };

  const getUsers = async (page) => {
    const res = await fetch(`/api/v1/users?page=${page}`);
    const preUsers = await res.json();
    dataArr = preUsers.data.data;
    dataArr.reverse();
    createUserList(dataArr);
  };

  const filterView = (e) => {
    currentPage = 1; // Réinitialiser à la première page lors de la recherche
    const searchedString = e.target.value.toLowerCase();
    const filteredArr = dataArr.filter(
      (el) =>
        el.name.toLowerCase().includes(searchedString) ||
        el.email.toLowerCase().includes(searchedString)
    );
    createUserList(filteredArr);
  };

  document.querySelector('#search-users').addEventListener('input', filterView);

  paginationPrev.addEventListener('click', () => {
    if (currentPage > 1) {
      currentPage--;
      createUserList(dataArr);
    }
  });

  paginationNext.addEventListener('click', () => {
    const totalPages = Math.ceil(dataArr.length / itemsPerPage);
    if (currentPage < totalPages) {
      currentPage++;
      createUserList(dataArr);
    }
  });

  getUsers();
};
