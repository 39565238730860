import { showAlert } from './alerts';
import { createShopAdmin, updateShopAdmin } from './helpers';

const userShopsAdmin = document.querySelector('.user-shops-admin');
const addShopForm = document.getElementById('addShopForm');
const tableBody = document.getElementById('shopTableBody');

if (userShopsAdmin) {
  // ✅ Gestion de l'ajout d'une boutique
  if (addShopForm) {
    addShopForm.addEventListener('submit', async (e) => {
      e.preventDefault();
      const addShopBtn = document.getElementById('addShopBtn');
      addShopBtn.disabled = true;

      const userId = addShopForm.dataset.userId;
      const shopName = document.getElementById('shopName').value.trim();
      const shopUrl = document
        .getElementById('shopUrl')
        .value.trim()
        .replace(/https?:\/\//, '');

      if (!shopName || !shopUrl) {
        alert('Veuillez remplir tous les champs.');
        addShopBtn.disabled = false;
        return;
      }

      try {
        await createShopAdmin(shopName, shopUrl, userId);
        showAlert('success', 'Boutique ajoutée avec succès');
        location.reload();
      } catch (err) {
        console.error(`❌ Erreur : ${err.message}`);
        showAlert('error', err.message);
      } finally {
        setTimeout(() => {
          addShopBtn.disabled = false;
        }, 3000);
      }
    });
  }

  // ✅ Gestion des événements sur le tableau
  tableBody.addEventListener('click', async (e) => {
    const row = e.target.closest('tr');
    if (!row) return;

    const shopId = row.dataset.id;
    const userId = row.dataset.userId;
    const nameCell = row.querySelector('.shop-name');
    const urlCell = row.querySelector('.shop-url');
    const editButton = e.target;

    // ✅ Mode Édition
    if (editButton.classList.contains('user-shops__btns--edit')) {
      nameCell.innerHTML = `<input type='text' value='${nameCell.textContent.trim()}' class='shop-edit-name form__input'>`;
      urlCell.innerHTML = `<input type='text' value='${urlCell.textContent.trim()}' class='shop-edit-url form__input'>`;
      editButton.textContent = 'Save';
      editButton.classList.replace(
        'user-shops__btns--edit',
        'user-shops__btns--save'
      );
      return;
    }

    // ✅ Mode Sauvegarde
    if (editButton.classList.contains('user-shops__btns--save')) {
      const newName = nameCell.querySelector('.shop-edit-name').value.trim();
      const newUrl = urlCell.querySelector('.shop-edit-url').value.trim();
      const currentDate = new Date().toISOString();

      try {
        await updateShopAdmin(
          { name: newName, url: newUrl, lastUpdate: currentDate },
          userId,
          shopId
        );
        nameCell.textContent = newName;
        urlCell.innerHTML = `<a href="https://${newUrl}" target="_blank">${newUrl}</a>`;
        editButton.textContent = 'Modifier';
        editButton.classList.replace(
          'user-shops__btns--save',
          'user-shops__btns--edit'
        );
        showAlert('success', 'Boutique mise à jour avec succès');
      } catch (err) {
        console.error(`❌ Erreur de mise à jour : ${err.message}`);
        showAlert('error', 'Échec de la mise à jour');
      }
    }

    // ✅ Suppression Individuelle
    if (e.target.classList.contains('btn--delete')) {
      if (!confirm('Êtes-vous sûr de vouloir supprimer cette boutique ?'))
        return;

      try {
        const res = await fetch(`/api/v1/users/${userId}/shops/${shopId}`, {
          method: 'DELETE',
        });

        if (!res.ok) throw new Error(`Erreur HTTP : ${res.status}`);

        row.remove();
        showAlert('success', 'Boutique supprimée avec succès');
      } catch (err) {
        console.error(`❌ Erreur de suppression : ${err.message}`);
        showAlert('error', 'Échec de la suppression');
      }
    }
  });
}
