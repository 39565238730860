import { showAlert } from './alerts.js';
const optin = document.querySelector('.optin');

if (optin) {
  document.addEventListener('DOMContentLoaded', () => {
    const optinForm = document.querySelector('#optinForm');

    if (!optinForm) return;

    optinForm.addEventListener('submit', async (e) => {
      e.preventDefault(); // ⛔ Empêche la redirection de la page

      const emailInput = optinForm.querySelector('input[name="email"]');
      const nameInput = optinForm.querySelector('input[name="name"]');
      const listIdInput = optinForm.querySelector('input[name="listId"]');

      if (!emailInput || !nameInput || !listIdInput) {
        showAlert('error', 'Formulaire incomplet.');
        return;
      }

      const email = emailInput.value.trim();
      const name = nameInput.value.trim();
      const listId = listIdInput.value.trim();

      if (!email || !name || !listId) {
        showAlert('error', 'Veuillez remplir tous les champs.');
        return;
      }

      try {
        const response = await fetch('/optin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, name, listId }),
        });

        const data = await response.json(); // ✅ Convertir correctement la réponse

        if (response.ok) {
          showAlert('success', data.message);
          setTimeout(() => {
            window.location.href = '/fr'; // 🔄 Redirige vers /fr après 3 secondes
          }, 3000);
          emailInput.value = '';
          nameInput.value = '';
        } else {
          showAlert('error', `Erreur : ${data.message}`);
        }
      } catch (err) {
        console.error('❌ Erreur de connexion à Brevo:', err);
        showAlert(
          'error',
          'Une erreur réseau est survenue. Veuillez réessayer.'
        );
      }
    });
  });
}
