/* eslint-disable */
import '@babel/polyfill';
import axios from 'axios';
import Sortable from 'sortablejs';
import {
  login,
  logout,
  signup,
  createShop,
  createShopAdmin,
  updateShop,
  deleteShop,
  createUser,
  deleteUser,
  deleteKuro,
  forgotPassword,
  changeDefaultPM,
  updateShopAdmin,
  createThemeVersion,
  deleteThemeVersion,
  updateThemeVersion,
  createUpdates,
  updateUpdate,
  deleteUpdate,
} from './helpers';
import { showAlert } from './alerts';
import {
  updateUserData,
  updateAddressOnStripe,
  updateUserVat,
  updatePassword,
  resetPassword,
} from './updateSettings';
import { searchUsers, searchShops } from './search';

// DOM ELEMENTS
const loginForm = document.querySelector('.form--login');
const logOutBtn = document.querySelector('.btn--logout');
const signupForm = document.querySelector('.form--signup');
const userDataForm = document.querySelector('.form-user-data');
const userVatForm = document.querySelector('.form-user-vat');
const userAddressForm = document.querySelector('.form-user-address');
const userPasswordForm = document.querySelector('.form-user-password');
const newDefaultPMBtns = document.querySelectorAll(
  '.user-view__payments--card-make-default'
);
const newShopForm = document.querySelector('.form--add-shop');
const newShopFormUser = document.querySelector('.form--add-shop-user');
const updateShopForms = document.querySelectorAll('.form--edit-shop');
const updateShopFormAdmin = document.querySelector('.form--edit-shop-admin');
const updateShopBtn = document.querySelector('.btn--edit-shop');
const searchShopsForm = document.querySelector('#search-shops');
const searchUsersForm = document.querySelector('#search-users');
const installationPopup = document.querySelector('#installationPopup');
const majForm = document.querySelector('.form--maj');

const storyThemeVersion = '2.8.3';

/************************* AUTHENTICATION *************************/
if (loginForm)
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (signupForm)
  signupForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const line1 = document.getElementById('line1').value;
    const line2 = document.getElementById('line2').value;
    const city = document.getElementById('city').value;
    const state = document.getElementById('state').value;
    const postal_code = document.getElementById('postal_code').value;
    const country = document.getElementById('country').value;
    const phone = document.getElementById('phone').value;
    const environment = document.getElementById('environment').value;
    const customerId = document.getElementById('customerId').value;
    const subscriptionId = document.getElementById('subscriptionId').value;
    const plan = document.getElementById('plan').value;
    const maxShopsFromOneTime = document.getElementById(
      'maxShopsFromOneTime'
    ).value;
    const maxShopsFromSubscription = document.getElementById(
      'maxShopsFromSubscription'
    ).value;
    const userState = document.getElementById('userState').value;
    const role = document.getElementById('userRole')
      ? document.getElementById('userRole').value
      : 'user';
    const subscriber = document.getElementById('subscriber').value;
    const oneTime = document.getElementById('oneTime').value;
    const freelanceId = document.getElementById('freelanceId')
      ? document.getElementById('freelanceId').value
      : '';
    const vatNumber = document.getElementById('vatNumber').value;
    const subscriptionAmount =
      document.getElementById('subscriptionAmount').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    signup(
      name,
      email,
      line1,
      line2,
      city,
      state,
      postal_code,
      country,
      phone,
      environment,
      customerId,
      subscriptionId,
      plan,
      maxShopsFromOneTime,
      maxShopsFromSubscription,
      userState,
      role,
      subscriber,
      oneTime,
      freelanceId,
      vatNumber,
      subscriptionAmount,
      password,
      passwordConfirm
    );
  });

/************************* UPDATE USER DATA *************************/
if (userDataForm) {
  userDataForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', document.getElementById('name').value);
    form.append('email', document.getElementById('email').value);

    if (document.getElementById('photo').files.length > 0) {
      form.append('photo', document.getElementById('photo').files[0]);
    }

    const userId = e.target.dataset.userId;
    updateUserData(form, userId);
  });

  const showPreview = (event) => {
    if (event.target.files.length > 0) {
      const src = URL.createObjectURL(event.target.files[0]);
      const preview = document.getElementById('file-ip-1-preview');
      preview.src = src;
      preview.style.display = 'block';
    }
  };

  document
    .getElementById('photo')
    .addEventListener('change', (e) => showPreview(e));
}

/************************* UPDATE USER VAT *************************/
if (userVatForm)
  userVatForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const vatNumber = document.getElementById('vatNumber').value;
    const userId = e.target.dataset.userId;

    updateUserVat(vatNumber, userId);
  });

/************************* UPDATE USER ADDRESS *************************/
if (userAddressForm)
  userAddressForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const line1 = document.getElementById('line1').value;
    const line2 = document.getElementById('line2').value
      ? document.getElementById('line2').value
      : '';
    const country = document.getElementById('country').value;
    const city = document.getElementById('city').value;
    const postal_code = document.getElementById('postal_code').value;

    const userId = e.target.dataset.userId;
    const customerId = e.target.dataset.customerId;
    const address = {
      line1,
      line2,
      city,
      country,
      postal_code,
    };

    const data = {
      billingAddress: address,
    };

    await updateUserData(data, userId);
    await updateAddressOnStripe(address, customerId);
  });

/************************* PASSWORD *************************/
if (userPasswordForm)
  userPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Updating...';

    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updatePassword({ passwordCurrent, password, passwordConfirm });

    document.querySelector('.btn--save-password').textContent = 'Save password';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
  });

/************************* ALERT *************************/
const alertMessage = document.querySelector('body').dataset.alert;
if (alertMessage) showAlert('success', alertMessage, 20);

/************************* CREATE SHOPS *************************/
if (newShopForm)
  newShopForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-shop').textContent = '...';

    const name = document.querySelector('.form--add-shop #name').value;
    const urlField = document.querySelector('.form--add-shop #url').value;
    const url = urlField
      .replace('https://', '')
      .replace('www.', '')
      .replaceAll('/', '')
      .toLowerCase();

    await createShop(name, url);
  });

/************************* CREATE SHOPS (ADMIN) *************************/

if (newShopFormUser)
  newShopFormUser.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-shop').textContent = 'Updating...';

    const name = document.getElementById('nameNewShopAdmin').value;
    const url = document.getElementById('urlNewShopAdmin').value;
    const userId = e.target.dataset.userId;
    await createShopAdmin(name, url, userId);

    document.querySelector('.btn--save-shop').textContent = 'Save shop';
    document.getElementById('name').value = '';
    document.getElementById('url').value = '';
  });

/************************* UPDATE SHOPS *************************/
if (updateShopForms) {
  updateShopForms.forEach((updateShopForm) => {
    updateShopForm.addEventListener('submit', async (e) => {
      e.preventDefault();
      updateShopBtn.textContent = '...';

      const name = updateShopForm.querySelector('#name').value;
      const url = updateShopForm
        .querySelector('#url')
        .value.replace('https://', '')
        .replaceAll('/', '')
        .toLowerCase();
      const shopId = e.target.dataset.shopId;

      await updateShop({ name, url }, shopId);
    });
  });
}

/************************* UPDATE SHOPS (ADMIN) *************************/
if (updateShopFormAdmin) {
  const urlBase = document.getElementById('urlUpdateShopAdmin').value;
  updateShopFormAdmin.addEventListener('submit', async (e) => {
    e.preventDefault();
    updateShopBtn.textContent = 'Updating...';

    const userId = e.target.dataset.userId;
    const name = document.getElementById('nameUpdateShopAdmin').value;
    const url = document.getElementById('urlUpdateShopAdmin').value;
    const shopId = e.target.dataset.shopId;
    const currentVisits =
      +document.getElementById('currentShopVisits').textContent;
    let visits;
    urlBase !== url ? (visits = 0) : (visits = currentVisits);

    await updateShopAdmin({ name, url, visits }, userId, shopId);

    updateShopBtn.textContent = 'Save changes';
  });
}

/************************* DELETE SHOPS *************************/
const deleteShopBtns = document.querySelectorAll('.btn--delete-shop');
if (deleteShopBtns) {
  deleteShopBtns.forEach((deleteShopBtn) => {
    deleteShopBtn.addEventListener('click', async (e) => {
      deleteShopBtn.textContent = '...';
      const shopId = e.target.dataset.shopId;
      await deleteShop(shopId);
    });
  });
}

/************************* CREATE USER (ADMIN) *************************/
const btnCreateUser = document.querySelector('.btn--create-user');
const createUserForm = document.querySelector('.form--create');

if (createUserForm) {
  btnCreateUser.addEventListener('click', function (e) {
    createUserForm.classList.toggle('hide');
    e.target.textContent == 'Create a new user'
      ? (e.target.textContent = 'Cancel')
      : (e.target.textContent = 'Create a new user');
  });
  createUserForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    const customerId = document.getElementById('customerid').value;
    const maxShopsFromSubscription = 0;
    const maxShopsFromOneTime = 0;
    createUser({
      name,
      email,
      password,
      passwordConfirm,
      customerId,
      maxShopsFromSubscription,
      maxShopsFromOneTime,
    });
  });
}

/************************* UPDATE USER (ADMIN) *************************/
const updateUserForm = document.querySelector('.form--edit-user');
const updateUserBtn = document.querySelector('.btn--edit-user');

if (updateUserForm)
  updateUserForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    updateUserBtn.textContent = 'Updating...';

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const maxShopsFromOneTime = document.getElementById(
      'maxShopsFromOneTime'
    ).value;
    const maxShopsFromSubscription = document.getElementById(
      'maxShopsFromSubscription'
    ).value;
    const role = document.getElementById('role').value;
    const plan = document.getElementById('plan').value;
    const state = document.getElementById('state').value;
    const subscriptionId = document.getElementById('subscriptionId').value;
    const subscriptionAmount =
      document.getElementById('subscriptionAmount').value;
    const amountSpent = document.getElementById('amountSpent').value;
    const customerId = document.getElementById('customerId').value;
    const preconfigTemplates =
      document.getElementById('preconfigTemplates').checked;
    const productTemplates =
      document.getElementById('productTemplates').checked;
    const storyTemplates = document.getElementById('storyTemplates').checked;
    const ultraTemplates = document.getElementById('ultraTemplates').checked;
    const line1 = document.getElementById('line1').value;
    const line2 = document.getElementById('line2').value;
    const country = document.getElementById('country').value;
    const city = document.getElementById('city').value;
    const postal_code = document.getElementById('postal_code').value;

    const userId = e.target.dataset.userId;
    const billingAddress = {
      line1,
      line2,
      city,
      country,
      postal_code,
    };

    const data = {
      name,
      email,
      phone,
      maxShopsFromSubscription,
      maxShopsFromOneTime,
      role,
      plan,
      state,
      subscriptionId,
      amountSpent,
      subscriptionAmount,
      customerId,
      preconfigTemplates,
      productTemplates,
      storyTemplates,
      ultraTemplates,
      billingAddress: billingAddress,
    };

    await updateUserData(data, userId);
    await updateAddressOnStripe(data.billingAddress, customerId);

    updateUserBtn.textContent = 'Edit user';
  });

/************************* DELETE USER (ADMIN) *************************/
const deleteUserBtn = document.querySelector('.btn--delete-user');

if (deleteUserBtn)
  deleteUserBtn.addEventListener('click', async (e) => {
    deleteUserBtn.textContent = 'Deleting...';
    const userId = e.target.dataset.userId;

    await deleteUser(userId);
  });

/************************* DELETE KURO (ADMIN) *************************/
const deleteKuroBtns = document.querySelectorAll('.btn--delete-kuro');
if (deleteKuroBtns)
  deleteKuroBtns.forEach((deleteBtn) => {
    deleteBtn.addEventListener('click', async (e) => {
      deleteBtn.textContent = 'Deleting...';
      const kuroId = e.currentTarget.dataset.kuroId;

      await deleteKuro(kuroId);
    });
  });

/************************* SEARCH SHOPS AND USERS *************************/
const btnsSearchUser = document.querySelectorAll('.btnSearchUser');
btnsSearchUser.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    const urlToReload = window.location.href.split('&')[0];
    const value = document.getElementById(btn.dataset.input).value;

    location.assign(`${urlToReload}&${btn.dataset.inputType}=${value}`);
  });
});

const resultsPerPage = document.getElementById('resultsPerPage');
if (resultsPerPage) {
  resultsPerPage.addEventListener('change', (e) => {
    const limit = e.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('limit', limit);
    window.location.href = url.href;
  });
}
const btnPrev = document.querySelector('.pagination__prev');
if (btnPrev) {
  btnPrev.addEventListener('click', (e) => {
    e.preventDefault();
    const newPage = parseInt(btnPrev.getAttribute('onclick').match(/\d+/)[0]);
    if (newPage < 1) return; // Empêcher les pages inférieures à 1
    const url = new URL(window.location.href);
    url.searchParams.set('page', newPage);
    window.location.href = url.href;
  });
}

const btnNext = document.querySelector('.pagination__next');
if (btnNext) {
  btnNext.addEventListener('click', (e) => {
    e.preventDefault();
    const newPage = parseInt(btnNext.getAttribute('onclick').match(/\d+/)[0]);
    const url = new URL(window.location.href);
    url.searchParams.set('page', newPage);
    window.location.href = url.href;
  });
}

/************************* SHOW ANALYTICS AFTER LOADED PAGE *************************/
if (document.querySelector('.totalUsers')) {
  document.addEventListener('DOMContentLoaded', async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `/api/v1/users/get-analytics`,
      });

      if (response.data && response.data.status === 'success') {
        const data = response.data.data;

        const totalUsers = document.querySelector('.totalUsers');
        const allDataSubscriptions = document.querySelector(
          '.allDataSubscriptions'
        );
        const allDataLiveMRR = document.querySelector('.allDataLiveMRR');
        const allDataASV = document.querySelector('.allDataASV');
        const trialSubscriptions = document.querySelector(
          '.trialSubscriptions'
        );
        const trialLiveMRR = document.querySelector('.trialLiveMRR');
        const trialASV = document.querySelector('.trialASV');
        const payingSubscriptions = document.querySelector(
          '.payingSubscriptions'
        );
        const payingLiveMRR = document.querySelector('.payingLiveMRR');
        const payingASV = document.querySelector('.payingASV');
        const payingStandard = document.querySelector('.payingStandard');
        const payingPlus = document.querySelector('.payingPlus');
        const payingBusiness = document.querySelector('.payingBusiness');
        const numberShopBusiness = document.querySelector(
          '.numberShopBusiness'
        );

        if (totalUsers)
          totalUsers.textContent = `Total users: ${data.totalUsers || 0}`;
        if (allDataSubscriptions)
          allDataSubscriptions.textContent = `Subscriptions: ${
            data.subscriptions?.length || 0
          }`;
        if (allDataLiveMRR)
          allDataLiveMRR.textContent = `Live MRR: ${
            data.mrr ? data.mrr.toFixed(2) : 0
          }€`;
        if (allDataASV)
          allDataASV.textContent = `ASV: ${
            data.mrr && data.subscriptions
              ? (data.mrr / data.subscriptions.length).toFixed(2)
              : 0
          }€`;
        if (trialSubscriptions)
          trialSubscriptions.textContent = `Subscriptions: ${
            data.subscriptionsTrial?.length || 0
          }`;
        if (trialLiveMRR)
          trialLiveMRR.textContent = `Live MRR: ${
            data.mrrTrial ? data.mrrTrial.toFixed(2) : 0
          }€`;
        if (trialASV)
          trialASV.textContent = `ASV: ${
            data.mrrTrial && data.subscriptionsTrial
              ? (data.mrrTrial / data.subscriptionsTrial.length).toFixed(2)
              : 0
          }€`;
        if (payingSubscriptions)
          payingSubscriptions.textContent = `Subscriptions: ${
            data.subscriptionsPaying?.length || 0
          }`;
        if (payingLiveMRR)
          payingLiveMRR.textContent = `Live MRR: ${
            data.mrrPaying ? data.mrrPaying.toFixed(2) : 0
          }€`;
        if (payingASV)
          payingASV.textContent = `ASV: ${
            data.mrrPaying && data.subscriptionsPaying
              ? (data.mrrPaying / data.subscriptionsPaying.length).toFixed(2)
              : 0
          }€`;
        if (payingStandard)
          payingStandard.textContent = `Paying Standard: ${
            data.payingStandard || 0
          }`;
        if (payingPlus)
          payingPlus.textContent = `Paying Plus: ${data.payingPlus || 0}`;
        if (payingBusiness)
          payingBusiness.textContent = `Paying Business: ${
            data.payingBusiness || 0
          }`;

        if (numberShopBusiness)
          numberShopBusiness.textContent = `numberShopBusiness: ${data.numberShopBusiness}`;
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'An error occurred';
      showAlert('error', errorMessage);
    }
  });
}

if (document.querySelector('.allUsers')) {
  document.addEventListener('DOMContentLoaded', async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `/api/v1/users/get-analytics`,
      });

      const data = response.data.data;

      const progressBars = [
        {
          id: 'progressAll',
          type: 'All Data',
          currentValueId: 'current-value-all',
          current: data.mrr.toFixed(2),
          max: 30000,
        },
        {
          id: 'progressPaying',
          type: 'Paying',
          currentValueId: 'current-value-paying',
          current: data.mrrPaying.toFixed(2),
          max: 20000,
        },
      ];

      progressBars.forEach((bar) => {
        const progressBar = document.getElementById(bar.id);
        if (progressBar) {
          const percentage = (bar.current / bar.max) * 100;
          progressBar.style.width = `${percentage}%`;

          const currentValueElement = document.getElementById(
            bar.currentValueId
          );
          if (currentValueElement) {
            currentValueElement.textContent = `Objectif ${bar.type} : ${
              bar.current
            } / ${bar.max} (${percentage.toFixed(0)}%)`;
          }
        }
      });
    } catch (err) {
      console.error('Error caught:', err);
      const errorMessage = err.response?.data?.message || 'An error occurred';
      showAlert('error', errorMessage);
    }
  });
}

/************************* FORGOT AND RESET PASSWORD *************************/
const forgotPasswordForm = document.querySelector('.form--forgot-password');

if (forgotPasswordForm)
  forgotPasswordForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    forgotPassword(email);
  });

const resetPasswordForm = document.querySelector('.form--reset-password');
if (resetPasswordForm)
  resetPasswordForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    const url = window.location.href.split('/');
    const token = url[url.length - 1];
    console.log(token);
    resetPassword({ password, passwordConfirm }, token);
  });

/************************* CONTACT FORM *************************/
const contactForm = document.querySelector('.form--contact');

if (contactForm) {
  let allConvertedFiles = [];

  const showPreview = (event) => {
    if (event.target.files.length > 0) {
      const src = URL.createObjectURL(event.target.files[0]);
      const preview = document.getElementById('file-ip-1-preview');
      preview.src = src;
      preview.style.display = 'block';
    }
  };

  // Get preview of the image attached
  document.getElementById('attachment').addEventListener('change', (e) => {
    showPreview(e);
    let reader;
    let dataUri;
    let file =
      document.getElementById('attachment').files[
        document.getElementById('attachment').files.length - 1
      ];
    reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function () {
      dataUri = 'data:' + file.type + ';base64,' + btoa(reader.result);
    };
    reader.onloadend = function () {
      allConvertedFiles.push({ name: file.name, data: dataUri });
    };
    document.querySelector('.form__label--attachment').style.display = 'none';
  });

  // Send the actual email
  contactForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.getElementById('sendEmail').textContent = 'Sending...';

    const formattedMessage = document
      .getElementById('message')
      .value.replace(/\n/g, '<br>');

    Email.send({
      SecureToken: '43d58c34-cafb-40f2-8ee1-c1c583680b53',
      From: `contact@story-theme.com`,
      To: 'contact@story-theme.com',
      Subject: `${document.getElementById('subject').value}`,
      Body: `Name: ${document.getElementById('name').value}
    <br> Email : ${document.getElementById('email').value}
    <br> url : ${document.getElementById('url').value}
    <br> Subject : ${document.getElementById('subject').value}
    <br><br> Message : <br> ${formattedMessage}`,
      Attachments: allConvertedFiles.map((file) => ({
        name: file.name,
        data: file.data,
      })),
    })
      .then(() => {
        document.getElementById('sendEmail').textContent = 'Send Message';
        showAlert('success', 'Mail sent successfully!');
        window.setTimeout(() => {
          location.reload();
        }, 1500);
      })
      .catch((err) => {
        showAlert('error', 'Error sending email');
        window.setTimeout(() => {
          location.reload();
        }, 1500);
      });

    return false;
  });
}

/************************* POPUP *************************/
if (installationPopup) {
  const openBtn = document.getElementById('openInstallationPopup');
  const closeBtn = document.querySelector('.popup__close');
  const overlayPopup = document.querySelector('.popup');

  openBtn.addEventListener('click', () =>
    installationPopup.classList.add('showPopup')
  );

  closeBtn.addEventListener('click', () =>
    installationPopup.classList.remove('showPopup')
  );

  overlayPopup.addEventListener('click', () =>
    installationPopup.classList.remove('showPopup')
  );

  document.addEventListener('keydown', function (e) {
    if (
      e.key === 'Escape' &&
      installationPopup.classList.contains('showPopup')
    ) {
      installationPopup.classList.remove('showPopup');
    }
  });
}

if (document.getElementById('openMajPopup')) {
  const openBtn = document.getElementById('openMajPopup');
  const closeBtn = document.querySelector('.popup__close-maj');
  const overlayPopup = document.querySelector('.popup-maj');

  openBtn.addEventListener('click', () => majPopup.classList.add('showPopup'));

  closeBtn.addEventListener('click', () =>
    majPopup.classList.remove('showPopup')
  );

  overlayPopup.addEventListener('click', () =>
    majPopup.classList.remove('showPopup')
  );

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape' && majPopup.classList.contains('showPopup')) {
      majPopup.classList.remove('showPopup');
    }
  });
}

/************************* SIDEBAR STYLES *************************/
const links = document.querySelectorAll('.sidebar__link');

links.forEach((link) => {
  if (link.attributes.href.textContent === window.location.pathname) {
    link.classList.add('sidebar__link--active');
  }
});

/************************* SWIPER CARDS *************************/
const swiperCard = document.querySelector('.user-view__payments--swiper');

if (swiperCard) {
  const swiperCardSwiper = new Swiper('.user-view__payments--swiper', {
    spaceBetween: 15,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  });
}

if (newDefaultPMBtns.length > 0) {
  newDefaultPMBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      const pmId = e.target.dataset.pmId;
      const subscriptionId = e.target.dataset.subscriptionId;
      const userId = e.target.dataset.userId;

      changeDefaultPM(pmId, subscriptionId, userId);
    });
  });
}

/************************* CHANGE PLAN *************************/
const popupPlan = document.querySelector('.popup__plan');

if (popupPlan) {
  const btnStandardMonthly = document.getElementById(
    'openPopupToStandardMonthly'
  );
  const btnPlusMonthly = document.getElementById('openPopupToPlusMonthly');
  const btnBusinessMonthly = document.getElementById(
    'openPopupToBusinessMonthly'
  );
  const btnStandardAnnually = document.getElementById(
    'openPopupToStandardAnnually'
  );
  const btnPlusAnnually = document.getElementById('openPopupToPlusAnnually');
  const btnBusinessAnnually = document.getElementById(
    'openPopupToBusinessAnnually'
  );

  const popupOverlay = document.querySelector('.popup__plan--overlay');
  const popupClose = document.querySelector('.popup__plan--close');
  const popupBillDate = document.querySelector(
    '.popup__plan--disclaimer-bill-date'
  );
  const popupNextBill = document.querySelector(
    '.popup__plan--disclaimer-next-bill'
  );
  const popupButton = popupPlan.querySelector('button');

  const openPopup = async (plan) => {
    popupPlan.classList.remove('hidden');

    try {
      const response = await axios({
        method: 'GET',
        url: `/api/v1/users/switch-to/${plan}`,
      });

      console.log(response);

      if (response.data.status === 'success' && response.data.data.billDate) {
        popupBillDate.textContent = response.data.data.billDate;
        popupNextBill.textContent = response.data.data.billAmount;
        if (!response.data.data.upgrade) {
          document.querySelector('.popup__plan--text').textContent =
            'Vous perdrez immédiatement les avantages liés à votre abonnement. ' +
            document.querySelector('.popup__plan--text').textContent;
        }
        popupButton.addEventListener('click', () => {
          popupButton.disabled = true;
          popupButton.innerText = 'Processing...';
          changePlan(plan);
        });
      } else {
        document.querySelector('.popup__plan--title').textContent = "S'abonner";
        document.querySelector('.popup__plan--text').textContent =
          'En cliquant sur le bouton ci-dessous, votre abonnement commencera immédiatement.';
        document.querySelector('.popup__plan--text').style.marginBottom =
          '15px';
        document.querySelector(
          '.popup__plan--disclaimer-wrapper'
        ).style.display = 'none';
        popupButton.addEventListener('click', () => {
          popupButton.disabled = true;
          popupButton.innerText = 'Processing...';
          changePlan(plan);
        });
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
  };

  const closePopup = () => popupPlan.classList.add('hidden');
  popupOverlay.addEventListener('click', closePopup);
  popupClose.addEventListener('click', closePopup);
  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape' || event.key === 'Esc') closePopup();
  });

  const changePlan = async (plan) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `/api/v1/users/change-plan/${plan}`,
      });

      if (response.data.data.session_url) {
        window.location.href = response.data.data.session_url;
      } else if (response.data.status === 'success') {
        showAlert('success', 'Plan switched successfully!');
        window.setTimeout(() => {
          location.reload();
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
  };

  if (btnStandardMonthly)
    btnStandardMonthly.addEventListener('click', () => {
      openPopup('standard-monthly');
    });
  if (btnPlusMonthly)
    btnPlusMonthly.addEventListener('click', () => {
      openPopup('plus-monthly');
    });
  if (btnBusinessMonthly)
    btnBusinessMonthly.addEventListener('click', () => {
      openPopup('business-monthly');
    });
  if (btnStandardAnnually)
    btnStandardAnnually.addEventListener('click', () => {
      openPopup('standard-annually');
    });
  if (btnPlusAnnually)
    btnPlusAnnually.addEventListener('click', () => {
      openPopup('plus-annually');
    });
  if (btnBusinessAnnually)
    btnBusinessAnnually.addEventListener('click', () => {
      openPopup('business-annually');
    });
}

if (document.querySelector('.btn--upgrade-to-business-monthly')) {
  document
    .querySelector('.btn--upgrade-to-business-monthly')
    .addEventListener('click', async function (e) {
      try {
        const response = await axios({
          method: 'POST',
          url: '/api/v1/users/change-plan/business-monthly',
        });

        if (response.data.data.session_url) {
          window.location.href = response.data.data.session_url;
        } else if (response.data.status === 'success') {
          showAlert('success', 'Plan switched successfully!');
          window.setTimeout(() => {
            location.reload();
          }, 1500);
        }
      } catch (err) {
        showAlert('error', 'Error, try again or contact us.');
        console.error(err.response.data.message);
      }
    });
}

if (document.querySelector('.btn--upgrade-to-business-annually')) {
  document
    .querySelector('.btn--upgrade-to-business-annually')
    .addEventListener('click', async function (e) {
      try {
        const response = await axios({
          method: 'POST',
          url: '/api/v1/users/change-plan/business-annually',
        });

        if (response.data.data.session_url) {
          window.location.href = response.data.data.session_url;
        } else if (response.data.status === 'success') {
          showAlert('success', 'Plan switched successfully!');
          window.setTimeout(() => {
            location.reload();
          }, 1500);
        }
      } catch (err) {
        showAlert('error', 'Error, try again or contact us.');
        console.error(err.response.data.message);
      }
    });
}

const inactivePopup = document.querySelector('.inactive__popup');
if (
  inactivePopup &&
  window.location.pathname !== '/change-plan' &&
  window.location.pathname !== '/fr/change-plan'
)
  inactivePopup.classList.remove('hidden');

/************************* CANCEL PLAN *************************/
const btnCancel = document.getElementById('cancelSubscription');
const btnPause = document.getElementById('pauseSubscription');
const btnResume = document.getElementById('resumeSubscription');

const cancelPlan = async (method) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `/api/v1/users/${method}-plan`,
    });

    if (response.data.status === 'success') {
      if (method === 'cancel') {
        showAlert(
          'success',
          `Plan successfully canceled! Shops won't be accessible at the end of your subscription.`
        );
        window.setTimeout(() => {
          location.href = 'https://www.story-theme.com/fr/me#tab2';
        }, 1500);
      }
      if (method === 'pause') {
        showAlert(
          'success',
          'Plan successfully paused! All shops are inaccessible.'
        );
        window.setTimeout(() => {
          location.href = 'https://www.story-theme.com/fr/me#tab2';
        }, 1500);
      }
      if (method === 'resume') {
        showAlert(
          'success',
          'Plan successfully resumed! All shops are accessible right now.'
        );
        window.setTimeout(() => {
          location.reload();
        }, 1500);
      }
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

if (btnCancel)
  btnCancel.addEventListener('click', () => {
    btnCancel.textContent = 'Loading...';
    cancelPlan('cancel');
  });

if (btnPause)
  btnPause.addEventListener('click', () => {
    btnPause.textContent = 'Loading...';
    cancelPlan('pause');
  });

if (btnResume)
  btnResume.addEventListener('click', () => {
    btnResume.textContent = 'Loading...';
    cancelPlan('resume');
  });

const btnCancelTypeform = document.getElementById('modalCancel');
if (btnCancelTypeform) {
  btnCancelTypeform.addEventListener('click', () => {
    const modalCancel = document.querySelector('.cancel-modal');
    modalCancel.classList.remove('hidden');
  });
}

/************************* SIDEBAR MENU *************************/
const btnOpenSidebar = document.querySelector('.header__menu-icon');
const btnCloseSidebar = document.querySelector('.sidebar__cross-icon');
const sidebar = document.querySelector('.sidebar');
const overlay = document.querySelector('.overlay');

if (btnOpenSidebar) {
  btnOpenSidebar.addEventListener('click', function () {
    sidebar.classList.add('sidebar__open');
  });
}

if (btnCloseSidebar) {
  btnCloseSidebar.addEventListener('click', function () {
    sidebar.classList.remove('sidebar__open');
  });
}

/************************* Swiper Story Design *************************/

if (document.querySelector('.storydesign__swiper')) {
  const swiperStoryDesign = new Swiper('.storydesign__swiper', {
    slidesPerView: 1,
    spaceBetween: 20,
    freeMode: true,
    // autoHeight: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      400: { slidesPerView: 2 },
      600: { slidesPerView: 3 },
    },
  });
}

/************************* Check Tab account page *************************/
const tabs = document.querySelector('.tabs');
if (tabs) {
  document.addEventListener('DOMContentLoaded', function () {
    if (window.location.hash === '#tab3') {
      document.getElementById('tab3').checked = true;
    }
  });

  document.addEventListener('DOMContentLoaded', function () {
    if (window.location.hash === '#tab2') {
      document.getElementById('tab2').checked = true;
    }
  });
}

/************************* My Level *************************/
const progressBar = document.querySelector('.tab3__progress-bar');
if (progressBar) {
  const amountSpent = progressBar.dataset.amountSpent;
  const afterElement = window.getComputedStyle(progressBar, '::after');

  const completeLevel = (height, id) => {
    progressBar.style.setProperty('--after-height', `${height}%`);
    const el = document.getElementById(`level${id}`);
    el.classList.add('tab3__level--complete');
    el.classList.remove('tab3__level--not-complete');
  };

  if (amountSpent < 30) progressBar.style.setProperty('--after-height', '1%');
  if (amountSpent >= 30) completeLevel(8, 1);
  if (amountSpent >= 45) completeLevel(19, 2);
  if (amountSpent >= 60) completeLevel(29, 3);
  if (amountSpent >= 75) completeLevel(40, 4);
  if (amountSpent >= 100) completeLevel(50, 5);
  if (amountSpent >= 150) completeLevel(61, 6);
  if (amountSpent >= 200) completeLevel(69, 7);
  if (amountSpent >= 300) completeLevel(79, 8);
  if (amountSpent >= 400) completeLevel(88, 9);
  if (amountSpent >= 500) completeLevel(100, 10);
}

/************************* Swiper 20 brands *************************/
if (document.querySelector('.swiper--20-brands')) {
  const swiper20Brands = new Swiper('.swiper--20-brands', {
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 2.4,
    mousewheel: { enabled: true, forceToAxis: true },
    scrollbar: {
      el: '.swiper--20-brands--scrollbar',
      draggable: true,
    },
    navigation: {
      prevEl: '.swiper--20-brands--prevEl',
      nextEl: '.swiper--20-brands--nextEl',
    },
  });
}

/************************* Swiper shops *************************/
if (document.querySelector('.ND-shops__container')) {
  const shopsSwiper = new Swiper('.ND-shops__container', {
    slidesPerView: 1,
    spaceBetween: 20,
    mousewheel: { enabled: true, forceToAxis: true },
    freeMode: true,
    breakpoints: {
      450: {
        slidesPerView: 2,
      },
      750: {
        slidesPerView: 3,
      },
      1050: {
        slidesPerView: 5,
      },
    },
  });
}

/************************* Upsell *************************/
if (document.getElementById('countdown')) {
  const closeModal = () => (window.location.href = '/fr/before-start');
  const progressBar = document.querySelector(
    '.upsell__countdown-bar--progress'
  );

  function afficherCompteARebours(minutes, secondes) {
    var countdownElement = document.getElementById('countdown');
    countdownElement.innerHTML =
      'Il reste ' +
      minutes.toString().padStart(2, '0') +
      ':' +
      secondes.toString().padStart(2, '0') +
      " minutes pour profiter de l'offre";
  }

  function demarrerCompteARebours() {
    const pageKey = 'countdownStartTime' + window.location.pathname; // Crée une clé unique basée sur l'URL de la page
    let startTime = sessionStorage.getItem(pageKey);
    let elapsedTime = 0;

    if (startTime) {
      elapsedTime = Math.floor((Date.now() - startTime) / 1000);
    } else {
      sessionStorage.setItem(pageKey, Date.now());
    }

    const duration = 5 * 60;
    let timeLeft = duration - elapsedTime;

    if (timeLeft < 0) timeLeft = 0; // Assurez-vous que le temps restant n'est pas négatif

    const updateProgressBar = () => {
      let currentProgress = (timeLeft / duration) * 100;
      progressBar.style.setProperty(
        'width',
        currentProgress + '%',
        'important'
      );

      if (currentProgress <= 40) {
        progressBar.style.backgroundColor = '#FF0000';
      } else if (currentProgress <= 80) {
        progressBar.style.backgroundColor = '#FFA500';
      } else {
        progressBar.style.backgroundColor = '#4CAF50';
      }
    };

    updateProgressBar();

    const countdownTimer = setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(countdownTimer);
        closeModal();
        return;
      }

      timeLeft--;
      updateProgressBar();
    }, 1000);

    let minutes = Math.floor(timeLeft / 60);
    let secondes = timeLeft % 60;

    const compteARebours = setInterval(function () {
      if (secondes === 0) {
        if (minutes === 0) {
          clearInterval(compteARebours);
          closeModal();
          sessionStorage.removeItem('countdownStartTimeUpsellConfirmationPage');
          return;
        } else {
          minutes--;
          secondes = 59;
        }
      } else {
        secondes--;
      }

      afficherCompteARebours(minutes, secondes);
    }, 1000);
  }

  window.onload = demarrerCompteARebours;

  // Faire fonctionner l'upsell
  const btnTakeUpsell = document.getElementById('takeUpsell');

  const takeUpsell = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `/api/v1/users/take-upsell`,
      });

      if (response.data.status === 'success') {
        showAlert(
          'success',
          `Upsell booked successfully! Redirecting to calendly...`
        );
        window.setTimeout(() => {
          window.location.href =
            'https://calendly.com/new-story-ecom/accompagnement-creation-de-boutique';
        }, 1500);
      }
    } catch (err) {
      showAlert(
        'error',
        'Un problème est intervenu. Vous allez être redirigé automatiquement...'
      );
      window.setTimeout(() => {
        window.location.href = '/exclusive-offer-2';
      }, 2500);
    }
  };

  if (btnTakeUpsell)
    btnTakeUpsell.addEventListener('click', () => {
      btnTakeUpsell.textContent = 'Loading...';
      takeUpsell();
    });

  // Faire fonctionner l'upsell
  const btnTakeUpsell2 = document.getElementById('takeUpsell2');

  const takeUpsell2 = async () => {
    const btnText = btnTakeUpsell2.textContent;
    try {
      const response = await fetch(`/api/v1/users/take-upsell2`, {
        method: 'POST',
      });

      const data = await response.json();
      const userData = data.data;

      if (response.ok) {
        showAlert(
          'success',
          'Les templates ont bien été achetées. Redirection automatique vers le compte client...'
        );
        window.setTimeout(() => {
          window.location.href = userData.session_url;
        }, 2500);
      } else {
        showAlert(
          'error',
          'Un problème est intervenu. Vous allez être redirigé automatiquement...'
        );
        window.setTimeout(() => {
          window.location.href = '/fr/before-start';
        }, 2500);
      }
    } catch (err) {
      console.log(err);
      btn.textContent = btnText;
    }
  };

  if (btnTakeUpsell2)
    btnTakeUpsell2.addEventListener('click', () => {
      btnTakeUpsell2.textContent = 'Loading...';
      takeUpsell2();
    });
}

/************************* New payment method *************************/
const btnNewPaymentMethod = document.getElementById('newPaymentMethod');

if (btnNewPaymentMethod) {
  const createNewPaymentMethod = async () => {
    try {
      const response = await fetch('/api/v1/users/create-new-payment-method', {
        method: 'POST',
      });

      const data = await response.json();
      const userData = data.data;

      if (response.ok) window.location.href = userData.session_url;
    } catch (err) {
      console.log(err);
    }
  };

  btnNewPaymentMethod.addEventListener('click', () => {
    btnNewPaymentMethod.textContent = 'Loading...';
    createNewPaymentMethod();
  });
}

/************************* CHANGE PLAN *************************/
const btnTakeStandardSub = document.getElementById('takeStandardSub');
const btnTakePlusSub = document.getElementById('takePlusSub');
const btnTakeBusinessSub = document.getElementById('takeBusinessSub');
const btnTakeStandardOne = document.getElementById('takeStandardOne');
const btnTakePlusOne = document.getElementById('takePlusOne');
const btnTakeBusinessOne = document.getElementById('takeBusinessOne');

const takePlanOne = async (newShops, price) => {
  try {
    const response = await fetch(
      `/api/v1/users/take-plan-one/${newShops}/${price}`,
      { method: 'POST' }
    );

    const data = await response.json();
    const userData = data.data;

    if (response.ok) window.location.href = userData.session_url;
  } catch (err) {
    console.log(err);
  }
};

if (btnTakeStandardOne) {
  btnTakeStandardOne.addEventListener('click', (e) => {
    e.preventDefault();
    btnTakeStandardOne.querySelector('p').textContent = 'Loading...';
    takePlanOne(1, btnTakeStandardOne.dataset.price);
  });
}

if (btnTakePlusOne) {
  btnTakePlusOne.addEventListener('click', (e) => {
    e.preventDefault();
    btnTakePlusOne.querySelector('p').textContent = 'Loading...';
    takePlanOne(3, btnTakePlusOne.dataset.price);
  });
}

if (btnTakeBusinessOne) {
  btnTakeBusinessOne.addEventListener('click', (e) => {
    e.preventDefault();
    btnTakeBusinessOne.querySelector('p').textContent = 'Loading...';
    takePlanOne(100, btnTakeBusinessOne.dataset.price);
  });
}

const takePlanSub = async (newShops, price) => {
  try {
    const response = await fetch(
      `/api/v1/users/take-plan-sub/${newShops}/${price}`,
      { method: 'POST' }
    );

    const data = await response.json();
    const userData = data.data;

    if (response.ok) window.location.href = userData.session_url;
  } catch (err) {
    console.log(err);
  }
};

if (btnTakeStandardSub) {
  btnTakeStandardSub.addEventListener('click', () => {
    btnTakeStandardSub.querySelector('p').textContent = 'Loading...';
    takePlanSub(1, btnTakeStandardSub.dataset.price);
  });
}

if (btnTakePlusSub) {
  btnTakePlusSub.addEventListener('click', () => {
    btnTakePlusSub.querySelector('p').textContent = 'Loading...';
    takePlanSub(3, btnTakePlusSub.dataset.price);
  });
}

if (btnTakeBusinessSub) {
  btnTakeBusinessSub.addEventListener('click', () => {
    btnTakeBusinessSub.querySelector('p').textContent = 'Loading...';
    takePlanSub(100, btnTakeBusinessSub.dataset.price);
  });
}

/************************* Story Design Products *************************/
const btnTakeStoryDesignStarter = document.getElementById(
  'takeStoryDesignStarter'
);
const btnTakeStoryDesignOptimization = document.getElementById(
  'takeStoryDesignOptimization'
);
const btnTakeStoryDesignBusiness = document.getElementById(
  'takeStoryDesignBusiness'
);

if (btnTakeStoryDesignStarter) {
  const takeStoryDesign = async (price) => {
    try {
      const response = await fetch(`/api/v1/users/story-design/${price}`, {
        method: 'POST',
      });

      const data = await response.json();
      const userData = data.data;

      if (response.ok) window.location.href = userData.session_url;
    } catch (err) {
      console.log(err);
    }
  };

  btnTakeStoryDesignStarter.addEventListener('click', () => {
    btnTakeStoryDesignStarter.textContent = 'Loading...';
    takeStoryDesign(btnTakeStoryDesignStarter.dataset.price);
  });

  btnTakeStoryDesignOptimization.addEventListener('click', () => {
    btnTakeStoryDesignOptimization.textContent = 'Loading...';
    takeStoryDesign(btnTakeStoryDesignOptimization.dataset.price);
  });

  btnTakeStoryDesignBusiness.addEventListener('click', () => {
    btnTakeStoryDesignBusiness.textContent = 'Loading...';
    takeStoryDesign(btnTakeStoryDesignBusiness.dataset.price);
  });
}

/************************* Checkout preconfig templates *************************/
const btnTakePreconfigTemplates = document.getElementById(
  'takePreconfigTemplates'
);
const btnTakeProductTemplates = document.getElementById('takeProductTemplates');
const btnTakeHomeTemplates = document.getElementById('takeHomeTemplates');
const btnTakeStoryTemplates = document.getElementById('takeStoryTemplates');
const btnTakeUltraTemplates = document.getElementById('takeUltraTemplates');
const btnTakeCreationBoutique = document.getElementById('takeCreationBoutique');

const checkoutButtonTemplate = (btn, url) => {
  if (btn) {
    const takePreconfigTemplates = async () => {
      const btnText = btn.textContent;
      try {
        const response = await fetch(url, { method: 'POST' });

        const data = await response.json();
        const userData = data.data;

        if (response.ok) window.location.href = userData.session_url;
      } catch (err) {
        console.log(err);
        btn.textContent = btnText;
      }
    };

    btn.addEventListener('click', () => {
      btn.textContent = 'Loading...';
      takePreconfigTemplates();
    });
  }
};

checkoutButtonTemplate(
  btnTakePreconfigTemplates,
  '/api/v1/users/take-preconfig-templates'
);
checkoutButtonTemplate(
  btnTakeProductTemplates,
  '/api/v1/users/take-product-templates'
);
checkoutButtonTemplate(
  btnTakeHomeTemplates,
  '/api/v1/users/take-home-templates'
);
checkoutButtonTemplate(
  btnTakeStoryTemplates,
  '/api/v1/users/take-story-templates'
);
checkoutButtonTemplate(
  btnTakeUltraTemplates,
  '/api/v1/users/take-ultra-templates'
);
checkoutButtonTemplate(
  btnTakeCreationBoutique,
  '/api/v1/users/take-creation-boutique'
);

/************************* Checkout analyse *************************/
const btnTakeAnalyse = document.querySelectorAll('.takeAnalyse');

if (btnTakeAnalyse.length > 0) {
  const originalTextContent = btnTakeAnalyse[0].textContent;
  const takeAnalyse = async () => {
    try {
      const response = await fetch(`/take-analyse`, {
        method: 'POST',
      });

      const data = await response.json();
      const userData = data.data;

      if (response.ok) window.location.href = userData.session_url;
    } catch (err) {
      console.log(err);
      btnTakeAnalyse.textContent = originalTextContent;
    }
  };

  btnTakeAnalyse.forEach((btn) => {
    btn.addEventListener('click', () => {
      btn.textContent = '...';
      takeAnalyse();
    });
  });
}

// Download theme files
const btnsDownloadThemeFile = document.querySelectorAll('.btn--download');

if (btnsDownloadThemeFile) {
  btnsDownloadThemeFile.forEach((btn) => {
    btn.addEventListener('click', () => {
      fetch(
        `/api/v1/users/download-theme-file/${btn.dataset.fileToDownload}/${btn.dataset.fileToRename}`
      )
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            btn.textContent = 'Télécharger';
          }
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = btn.dataset.fileToRename;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Une erreur est survenue :', error);
        });
    });
  });
}

//// DRAG AND DROP

const dropzone = document.querySelector('#file');
const dropzoneEl = document.querySelector('#dropzone');
const fileNameEl = document.querySelector('#fileName');
const uploadButton = document.querySelector('#uploadButton');
const updateLabel = document.querySelector('.update__labeler');
const downloadBtn = document.querySelector('#download-button');

if (dropzone) {
  dropzoneEl.addEventListener('dragover', (e) => {
    e.preventDefault();
    dropzoneEl.classList.add('dragover');
  });

  dropzoneEl.addEventListener('dragleave', (e) => {
    e.preventDefault();
    dropzoneEl.classList.remove('dragover');
  });

  dropzoneEl.addEventListener('drop', (e) => {
    e.preventDefault();
    dropzoneEl.classList.remove('dragover');
    dropzone.files = e.dataTransfer.files;
    updateFileName();
  });

  dropzone.addEventListener('change', () => {
    updateFileName();
  });

  function updateFileName() {
    if (dropzone.files.length > 0) {
      fileNameEl.textContent = dropzone.files[0].name;
      uploadButton.disabled = false;

      uploadButton.classList.remove('btn--disabled');
      uploadButton.classList.add('btn--shade');
      updateLabel.style.display = 'none';
    } else {
      fileNameEl.innerHTML = `Cliquez ici ou faites glisser un fichier ZIP ici.`;
      uploadButton.disabled = true;
    }
  }

  dropzone.addEventListener('change', () => {
    const files = dropzone.files;
    if (files.length > 0) {
      const fileName = files[0].name;

      fileNameEl.innerHTML = fileName;
      uploadButton.disabled = false;
      uploadButton.classList.remove('btn--disabled');
      uploadButton.classList.add('btn--shade');
      updateLabel.style.display = 'none';
    } else {
      if (window.location.pathname.startsWith('/fr')) {
        fileNameEl.innerHTML = `Cliquez ici ou faites glisser un fichier ZIP ici.`;
      } else {
        fileNameEl.innerHTML = `Click here or drag and drop a ZIP file here.`;
      }
    }
  });

  majForm.addEventListener('submit', (e) => {
    e.preventDefault();
    if (window.location.pathname.startsWith('/fr')) {
      uploadButton.textContent = 'Chargement...';
    } else {
      uploadButton.textContent = 'Loading...';
    }
    uploadButton.disabled = true;
    uploadButton.classList.add('btn--disabled');

    // Prevents HTML handling submission
    const files = document.getElementById('file');
    const formData = new FormData();

    // Appends value of text input
    for (let i = 0; i < files.files.length; i++) {
      formData.append('files', files.files[i]);
    }

    // Appends value(s) of file input
    // Post data to Node and Express server:
    fetch('/api', {
      method: 'POST',
      body: formData, // Payload is formData object
    })
      .then((res) => res.json())
      .then(() => {
        downloadBtn.classList.remove('hidden');
        uploadButton.classList.add('hidden');
      });
  });

  function downloadFile() {
    if (window.location.pathname.startsWith('/fr')) {
      downloadBtn.textContent = 'Téléchargement...';
    } else {
      downloadBtn.textContent = 'Downloading...';
    }
    downloadBtn.disabled = true;
    downloadBtn.classList.add('btn--disabled');

    const file = dropzone.files;
    const fileName = file[0].name;

    fetch(`/send-file?fileName=${fileName}`)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          if (window.location.pathname.startsWith('/fr')) {
            downloadBtn.textContent = 'Télécharger';
          } else {
            downloadBtn.textContent = 'Download';
          }
          window.location.reload();
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Story Thème - ${storyThemeVersion} - ${fileName}`;
        link.click();
        window.URL.revokeObjectURL(url);

        setTimeout(() => {
          fetch(`/delete-file?fileName=${fileName}`);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error('Une erreur est survenue :', error);
      });
  }

  downloadBtn.addEventListener('click', downloadFile);
}

/************************* Templates *************************/
const templatesCardsList = document.querySelectorAll('.templates__cards');
const templatesCards = document.querySelectorAll('.templates-cards');

if (templatesCardsList || templatesCards) {
  templatesCardsList.forEach((templatesCards) => {
    const templatesCardsTitle = templatesCards.querySelector(
      '.templates__cards--title'
    );
    const templatesCardsIcon = templatesCards.querySelector(
      '.templates__cards--icon'
    );
    // Si chaque templatesCards a son propre contenu, sélectionnez-le ici. Sinon, utilisez la sélection globale.
    const templatesContent =
      templatesCards.querySelector('.templates__content') ||
      document.querySelector('.templates__content');

    templatesCardsTitle.addEventListener('click', () => {
      templatesContent.classList.toggle('hidden');
      templatesCardsTitle.classList.toggle('mb-md');

      if (templatesCardsIcon.style.transform === 'rotate(90deg)') {
        templatesCardsIcon.style.transform = '';
      } else {
        templatesCardsIcon.style.transform = 'rotate(90deg)';
      }
    });
  });

  const templatesShow = document.querySelectorAll('.templates__open-modal');
  const modals = document.querySelectorAll('.templates__modal');
  const closeModalTemplate = document.querySelectorAll('.close-bonus-modal');

  templatesShow.forEach((template) => {
    template.addEventListener('click', () => {
      const modalId = template.getAttribute('data-modal');
      const modalToShow = document.querySelector(
        `.templates__modal[data-modal-id="${modalId}"]`
      );

      if (modalToShow) {
        modalToShow.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
      }
    });
  });

  closeModalTemplate.forEach((close) => {
    close.addEventListener('click', () => {
      modals.forEach((modal) => {
        modal.classList.add('hidden');
      });
      document.body.style.overflow = 'visible';
    });
  });
}

if (templatesCards) {
  function loadAndInsertJson(modalId) {
    let jsonFile;
    if (modalId === 'classica-info') jsonFile = 'classica.json';
    if (modalId === 'visua-info') jsonFile = 'visua.json';
    if (modalId === 'featuresfocus-info') jsonFile = 'features-focus.json';
    if (modalId === 'story-info') jsonFile = 'story.json';

    if (!jsonFile) return;

    fetch(`/templates-json/${jsonFile}`)
      .then((response) => response.json())
      .then((data) => {
        const preElement = document.querySelector(
          `[data-modal-id="${modalId}"] .templates__modal--code`
        );
        preElement.textContent = JSON.stringify(data, null, 2);
      })
      .catch((err) => console.error('Erreur lors du chargement du JSON', err));
  }

  function copyCode() {
    const activeModal = document.querySelector(
      '.templates__modal:not(.hidden)'
    );
    if (!activeModal) return;

    const preElement = activeModal.querySelector('.templates__modal--code');
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(preElement);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      selection.removeAllRanges();
      alert('Code JSON copié !');
    } catch (err) {
      console.error('Erreur lors de la copie', err);
    }
  }

  // Ajouter des écouteurs d'événements pour chaque bouton de copie
  document.querySelectorAll('[data-action="copy-code"]').forEach((button) => {
    button.addEventListener('click', copyCode);
  });

  // Ajouter des écouteurs d'événements pour chaque zone de code
  document
    .querySelectorAll('.templates__modal--code')
    .forEach((codeElement) => {
      codeElement.addEventListener('click', copyCode);
    });

  // Charger le JSON lorsque la modale devient visible
  document.querySelectorAll('.templates__modal').forEach((modal) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.attributeName === 'class' &&
          !modal.classList.contains('hidden')
        ) {
          loadAndInsertJson(modal.dataset.modalId);
        }
      });
    });
    observer.observe(modal, { attributes: true });
  });
}

/************************* SHOPS DASHBOARD *************************/
const showEditPopupTriggers = document.querySelectorAll('.showEditPopup');
const showDeletePopupTriggers = document.querySelectorAll('.showDeletePopup');
const showAddShopPopupTrigger = document.querySelector('.addShopButton');
const showAddShopPopupFakeTrigger =
  document.querySelector('.addShopButtonFake');

if (showEditPopupTriggers) {
  showEditPopupTriggers.forEach((el) => {
    el.addEventListener('click', (e) => {
      document
        .getElementById(e.currentTarget.dataset.popupToShow)
        .classList.toggle('show');
    });
  });
}

if (showAddShopPopupFakeTrigger) {
  showAddShopPopupFakeTrigger.addEventListener('click', (e) => {
    if (window.location.pathname === '/fr/dashboard') {
      showAlert(
        'error',
        "Vous avez atteint le nombre maximum de boutique pour votre formule, modifiez votre abonnement pour ajouter d'autres boutiques"
      );
    }

    if (window.location.pathname === '/dashboard') {
      showAlert(
        'error',
        "You've reached the maximum number of shops for your plan. Change your subscription to add more shops."
      );
    }
  });
}

if (showDeletePopupTriggers) {
  showDeletePopupTriggers.forEach((el) => {
    el.addEventListener('click', (e) => {
      document
        .getElementById(e.currentTarget.dataset.popupToShow)
        .classList.toggle('show');
    });
  });
}

if (showAddShopPopupTrigger) {
  showAddShopPopupTrigger.addEventListener('click', (e) => {
    document
      .getElementById(e.currentTarget.dataset.popupToShow)
      .classList.toggle('show');
  });
}

/************************* PROMO DASHBOARD *************************/
const closeOfferPromo = document.querySelector('#closeOfferPromo');
const promoToClose = document.querySelector('#promoToClose');
if (closeOfferPromo) {
  closeOfferPromo.addEventListener('click', () => {
    promoToClose.style.display = 'none';
  });
}

/************************* FREELANCE *************************/
const createCustomerForm = document.querySelector('.form--create-customer');

if (createCustomerForm) {
  createCustomerForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const freelanceId =
      document.querySelector('.freelanceId').dataset.freelanceId;

    // Send mail to customer to create their account
    const sendInvite = async (name, email, freelanceId) => {
      try {
        const res = await axios({
          method: 'POST',
          url: `/api/v1/users/send-mail-to-customer/${name}/${email}/${freelanceId}`,
          data: {
            name,
            email,
            freelanceId,
          },
        });

        if (res.data.status === 'success') {
          showAlert('success', 'Invitation sent successfully!');
        }
      } catch (err) {
        showAlert('error', err.response.data.message);
      }
    };

    sendInvite(name, email, freelanceId);
  });
}

/************************* User details *************************/
const editUserForm = document.querySelector('.form--edit-user');

if (editUserForm) {
  const editIcons = document.querySelectorAll('.form__icon--edit');
  const confirmIcons = document.querySelectorAll('.form__icon--confirm');
  const img = document.querySelector('.user__detail--image');
  const imgModal = document.getElementById('imgModal');
  const stateTitle = document.querySelector('.form__state--title');
  const stateContainer = document.querySelector('.form__state');

  function toggleIcons(editIcon, confirmIcon, title, input, inputValue) {
    editIcon.classList.toggle('hidden');
    confirmIcon.classList.toggle('hidden');
    title.classList.toggle('hidden');
    input.classList.toggle('hidden');
    if (!title.classList.contains('hidden')) {
      title.textContent = inputValue;
    }
  }

  editIcons.forEach((editIcon) => {
    editIcon.addEventListener('click', () => {
      const inputData = editIcon.getAttribute('data-input');
      const confirmIcon = document.querySelector(
        `.form__icon--confirm[data-input="${inputData}"]`
      );
      const title = document.querySelector(`.form__${inputData}--title`);
      const input = document.querySelector(`.form__${inputData}--input`);
      const inputValue = document.getElementById(`${inputData}`);
      toggleIcons(editIcon, confirmIcon, title, input, inputValue);
    });
  });

  confirmIcons.forEach((confirmIcon) => {
    confirmIcon.addEventListener('click', () => {
      const inputData = confirmIcon.getAttribute('data-input');
      const editIcon = document.querySelector(
        `.form__icon--edit[data-input="${inputData}"]`
      );
      const title = document.querySelector(`.form__${inputData}--title`);
      const input = document.querySelector(`.form__${inputData}--input`);
      const inputValue = document.getElementById(`${inputData}`).value;
      toggleIcons(editIcon, confirmIcon, title, input, inputValue);
    });
  });

  img.addEventListener('click', () => {
    imgModal.classList.remove('hidden');
  });

  imgModal.addEventListener('click', () => {
    imgModal.classList.add('hidden');
  });

  if (stateTitle.textContent == 'active') {
    stateContainer.style.backgroundColor = '#40c057';
  } else {
    stateContainer.style.backgroundColor = '#fa5252';
  }
}

/************************* Contact page *************************/

const contact = document.querySelector('.contact');
if (contact) {
  document.addEventListener('DOMContentLoaded', function () {
    const responsesContainer = document.querySelector('.contact__response');
    const buttons = document.querySelectorAll('.contact__choice');

    buttons.forEach((button) => {
      button.addEventListener('click', function () {
        // Identifier la réponse correspondante
        const responseClass = '.response-' + this.getAttribute('data-response');
        const responseElement = document.querySelector(responseClass);

        // Masquer toutes les autres réponses et retirer la classe active des autres boutons
        buttons.forEach((btn) => {
          if (btn !== button) {
            btn.classList.remove('contact__choice--active');
          }
        });

        const responses = document.querySelectorAll('.contact__response > div');
        responses.forEach((response) => {
          if (response !== responseElement) {
            response.classList.add('hidden');
          }
        });

        // Afficher le conteneur de réponses si une réponse doit être visible
        if (responseElement.classList.contains('hidden')) {
          responsesContainer.classList.remove('hidden');
        }

        // Basculer la visibilité de la réponse correspondante et la classe active du bouton
        responseElement.classList.toggle('hidden');
        button.classList.toggle('contact__choice--active');

        // Si toutes les réponses sont cachées, cacher le conteneur de réponses
        const allResponsesHidden = Array.from(responses).every((response) =>
          response.classList.contains('hidden')
        );
        if (allResponsesHidden) {
          responsesContainer.classList.add('hidden');
        }
      });
    });

    // Gérer les sous-boutons et sous-réponses dans la réponse 4
    const subResponsesContainer = document.querySelector(
      '.contact__sub-response'
    );
    const subButtons = document.querySelectorAll('.contact__choice--sub');

    subButtons.forEach((subButton) => {
      subButton.addEventListener('click', function () {
        // Identifier la sous-réponse correspondante
        const subResponseClass =
          '.response-' + this.getAttribute('data-response');
        const subResponseElement = document.querySelector(subResponseClass);

        // Masquer toutes les autres sous-réponses et retirer la classe active des autres sous-boutons
        subButtons.forEach((btn) => {
          if (btn !== subButton) {
            btn.classList.remove('contact__choice--active');
          }
        });

        const subResponses = document.querySelectorAll(
          '.contact__sub-response > div'
        );
        subResponses.forEach((subResponse) => {
          if (subResponse !== subResponseElement) {
            subResponse.classList.add('hidden');
          }
        });

        // Afficher le conteneur de sous-réponses si une sous-réponse doit être visible
        if (subResponseElement.classList.contains('hidden')) {
          subResponsesContainer.classList.remove('hidden');
        }

        // Basculer la visibilité de la sous-réponse correspondante et la classe active du sous-bouton
        subResponseElement.classList.toggle('hidden');
        subButton.classList.toggle('contact__choice--active');

        // Si toutes les sous-réponses sont cachées, cacher le conteneur de sous-réponses
        const allSubResponsesHidden = Array.from(subResponses).every(
          (subResponse) => subResponse.classList.contains('hidden')
        );
        if (allSubResponsesHidden) {
          subResponsesContainer.classList.add('hidden');
        }
      });
    });
  });
}

/************************* Road Map *************************/
const roadMapAdmin = document.querySelector('.roadMapAdmin');
const roadMap = document.querySelector('.roadmap');
const themeVersionForm = document.getElementById('themeVersionForm');

if (themeVersionForm) {
  themeVersionForm.addEventListener('submit', async function (e) {
    e.preventDefault();
    const id = this.action.split('/').pop();
    const version = document.getElementById('version').value;
    const releaseDate = document.getElementById('releaseDate').value;
    const isNewFeaturesAdd = document.getElementById('isNewFeatures').checked;
    const isNewFeaturesElement = document.getElementById(`isNewFeatures-${id}`);
    const isNewFeatures = isNewFeaturesElement
      ? isNewFeaturesElement.checked
      : undefined;

    await createThemeVersion({
      version,
      releaseDate,
      isNewFeatures: isNewFeaturesAdd,
    });
  });
}

if (roadMapAdmin) {
  const deleteButtons = document.querySelectorAll('.btn--delete-version');
  deleteButtons.forEach((button) => {
    button.addEventListener('click', async function () {
      const id = this.getAttribute('data-id');
      await deleteThemeVersion(id);
    });
  });

  const editForms = document.querySelectorAll('.editThemeVersionForm');
  editForms.forEach((form) => {
    form.addEventListener('submit', async function (e) {
      e.preventDefault();
      const id = this.action.split('/').pop();
      const version = document.getElementById(`editVersion-${id}`).value;
      const releaseDate = document.getElementById(
        `editReleaseDate-${id}`
      ).value;
      const isNewFeatures = document.getElementById(
        `isNewFeatures-${id}`
      ).checked;

      await updateThemeVersion(id, { version, releaseDate, isNewFeatures });
    });
  });

  const updateForm = document.getElementById('updateForm');
  if (updateForm) {
    updateForm.addEventListener('submit', async function (e) {
      e.preventDefault();
      const versionElement = document.getElementById('updateVersion');
      const typeElement = document.getElementById('updateType');
      const categoryElement = document.getElementById('updateCategory');
      const descriptionElement = document.getElementById('updateDescription');
      const descriptionFRElement = document.getElementById(
        'updateDescriptionFR'
      );

      if (
        !versionElement ||
        !typeElement ||
        !categoryElement ||
        !descriptionElement ||
        !descriptionFRElement
      ) {
        showAlert('error', 'Please fill out all fields.');
        return;
      }

      const version = versionElement.value;
      const type = typeElement.value;
      const category = categoryElement.value;
      const descriptions = descriptionElement.value.split(',');
      const descriptionsFR = descriptionFRElement.value.split(',');

      if (descriptions.length !== descriptionsFR.length) {
        showAlert(
          'error',
          'Please ensure each description has a corresponding French description.'
        );
        return;
      }

      const updates = descriptions.map((description, index) => ({
        version,
        type,
        category,
        description: description.trim(),
        descriptionFR: descriptionsFR[index].trim(),
      }));

      await createUpdates(updates);
    });
  }

  const editUpdateButtons = document.querySelectorAll('.btn--edit-update');
  editUpdateButtons.forEach((button) => {
    button.addEventListener('click', function () {
      const id = this.getAttribute('data-id');
      const updateElement = this.closest('.roadmap__update');
      if (!updateElement) {
        console.error(`Update element not found for update id ${id}`);
        return;
      }

      const editFormElement = updateElement.querySelector(
        `.roadmap__update--edit`
      );
      if (!editFormElement) {
        console.error(`Form element not found for update id ${id}`);
        return;
      }

      // Toggle visibility of the edit form
      editFormElement.classList.toggle('hidden');
    });
  });

  const deleteUpdateButtons = document.querySelectorAll('.btn--delete-update');
  deleteUpdateButtons.forEach((button) => {
    button.addEventListener('click', async function () {
      const id = this.getAttribute('data-id');
      await deleteUpdate(id);
    });
  });

  const cancelEditButtons = document.querySelectorAll('.btn-cancel-edit');
  cancelEditButtons.forEach((button) => {
    button.addEventListener('click', function () {
      const formElement = this.closest('.roadmap__update--edit');
      if (formElement) {
        formElement.classList.add('hidden');
      } else {
        console.error('Form element not found for cancel button');
      }
    });
  });

  const editUpdateForms = document.querySelectorAll('.editUpdateForm');
  editUpdateForms.forEach((form) => {
    form.addEventListener('submit', async function (e) {
      e.preventDefault();
      const id = this.getAttribute('data-id');
      const updatedCategory = form.querySelector(`#category-${id}`).value;
      const updatedVersion = form.querySelector(`#version-${id}`).value;
      const updatedDescription = form.querySelector(
        `#editDescription-${id}`
      ).value;
      const updatedDescriptionFR = form.querySelector(
        `#editDescriptionFR-${id}`
      ).value;

      await updateUpdate(id, {
        description: updatedDescription,
        descriptionFR: updatedDescriptionFR,
        category: updatedCategory,
        version: updatedVersion,
      });

      // Update the displayed text
      const updateElement = form.closest('.roadmap__update');
      const descriptionElement =
        updateElement.querySelector('p:nth-of-type(1)');
      const descriptionFRElement =
        updateElement.querySelector('p:nth-of-type(2)');
      descriptionElement.textContent = updatedDescription;
      descriptionFRElement.textContent = updatedDescriptionFR;

      // Hide the edit form
      form.closest('.roadmap__update--edit').classList.add('hidden');
    });
  });

  ///////////// Gérer l'ordre
  function initializeSortable(list, updateType) {
    Sortable.create(list, {
      animation: 150,
      onEnd: function (evt) {
        const { oldIndex, newIndex } = evt;
        const themeVersionId = list.getAttribute('data-id');
        const updateId = evt.item.getAttribute('data-id');

        // Fonction pour mettre à jour l'ordre sur le serveur
        updateOrder(themeVersionId, updateId, oldIndex, newIndex, updateType);
      },
    });
  }

  // Initialiser SortableJS pour les listes de bugs
  document.querySelectorAll('.version__bug-list').forEach((list) => {
    initializeSortable(list, 'bug');
  });

  // Initialiser SortableJS pour les listes d'améliorations
  document.querySelectorAll('.version__update-list').forEach((list) => {
    initializeSortable(list, 'update');
  });

  // Initialiser SortableJS pour les listes de nouveautés
  document.querySelectorAll('.version__new-list').forEach((list) => {
    initializeSortable(list, 'new-feature');
  });

  // Fonction pour mettre à jour l'ordre des mises à jour sur le serveur
  async function updateOrder(
    themeVersionId,
    updateId,
    oldIndex,
    newIndex,
    updateType
  ) {
    try {
      const res = await axios({
        method: 'POST',
        url: `/api/v1/themeVersion/${themeVersionId}/updateOrder`,
        data: {
          updateId,
          oldIndex,
          newIndex,
          updateType,
        },
      });

      if (res.data.status === 'success') {
        showAlert('success', 'Ordre des mises à jour mis à jour avec succès!');
      }
    } catch (err) {
      showAlert(
        'error',
        "Erreur lors de la mise à jour de l'ordre des mises à jour!"
      );
    }
  }

  // Modal update
  // Sélection des éléments DOM
  const detailsModalBtns = document.querySelectorAll('.roadmap__track--text');
  const detailsModal = document.querySelector('.roadmap__modal');
  const detailsText = document.querySelector('#detailsText');
  const closeModalBtn = document.querySelector('.modal-close-btn');
  const imagesContainer = document.querySelector('.roadmap__modal--imgs');
  const body = document.body;
  const dropZone = document.querySelector('.roadmap__modal--dropzone');
  const imagesInput = document.getElementById('imagesInput');
  const imagesForm = document.getElementById('imagesForm');
  const imageModal = document.querySelector('.roadmap__image-modal');
  const enlargedImage = document.querySelector('#enlargedImage');

  // Gestion du défilement de la page
  function disableScroll() {
    body.classList.add('body-no-scroll');
  }

  function enableScroll() {
    body.classList.remove('body-no-scroll');
  }

  // Ouverture de la modal des détails
  detailsModalBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      const updateId = btn.getAttribute('data-id');
      const updateDetails = btn.getAttribute('data-details');
      const imagesData = btn.getAttribute('data-images');

      // Assurez-vous que les formulaires ont l'ID de mise à jour correct
      detailsForm.setAttribute('data-id', updateId);
      imagesForm.setAttribute('data-id', updateId);

      // Remplir le champ "details" avec les données existantes
      detailsText.value = updateDetails || '';

      // Efface les images précédentes
      imagesContainer.innerHTML = '';

      // Gérer l'affichage des images existantes
      if (imagesData) {
        const images = imagesData.split(',');
        images.forEach((image) => {
          const imgElement = createImageElement(image, updateId);
          imagesContainer.appendChild(imgElement);
        });
      } else {
        const noImageMessage = document.createElement('p');
        noImageMessage.textContent = 'Aucune image disponible pour cet update.';
        imagesContainer.appendChild(noImageMessage);
      }

      // Ouvrir la modal et désactiver le défilement
      detailsModal.classList.remove('hidden');
      disableScroll();
    });
  });

  // Fonction pour créer un élément d'image avec les événements appropriés
  function createImageElement(imageSrc, updateId) {
    const imgElement = document.createElement('img');
    imgElement.src = imageSrc;

    // Clic gauche pour agrandir l'image
    imgElement.addEventListener('click', () => openImageModal(imageSrc));

    // Clic droit pour afficher le menu contextuel de suppression
    imgElement.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      if (confirm('Voulez-vous vraiment supprimer cette image ?')) {
        deleteImage(updateId, imageSrc, imgElement);
      }
    });

    return imgElement;
  }

  // Fonction pour ouvrir la modal d'image agrandie
  function openImageModal(imageSrc) {
    enlargedImage.src = imageSrc;
    imageModal.classList.remove('hidden');
  }

  // Fermeture de la modal d'image agrandie
  imageModal.addEventListener('click', (e) => {
    if (e.target === imageModal) {
      imageModal.classList.add('hidden');
    }
  });

  // Fermeture de la modal avec la touche Échap
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      if (!imageModal.classList.contains('hidden')) {
        imageModal.classList.add('hidden');
      } else if (!detailsModal.classList.contains('hidden')) {
        detailsModal.classList.add('hidden');
        enableScroll();
      }
    }
  });

  // Fermeture de la modal principale
  closeModalBtn.addEventListener('click', () => {
    detailsModal.classList.add('hidden');
    enableScroll();
  });

  // Fermeture de la modal principale en cliquant à l'extérieur
  detailsModal.addEventListener('click', (e) => {
    if (e.target === detailsModal) {
      detailsModal.classList.add('hidden');
      enableScroll();
    }
  });

  // Fonction de suppression d'image
  function deleteImage(updateId, imageUrl, imgElement) {
    fetch(`/api/v1/update/${updateId}/images`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ imageUrl }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          imgElement.remove();
          showAlert('success', 'Image supprimée avec succès');
        } else {
          showAlert('error', "Erreur lors de la suppression de l'image");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression de l'image:", error);
        showAlert('error', "Erreur lors de la suppression de l'image");
      });
  }

  // Soumission du formulaire de texte
  detailsForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const updateId = detailsForm.getAttribute('data-id');

    fetch(`/api/v1/update/${updateId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ details: detailsText.value }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          showAlert('success', 'Texte mis à jour avec succès');
          location.reload(); // Recharger la page pour actualiser les modifications
          enableScroll();
        } else {
          showAlert('error', 'Erreur lors de la mise à jour du texte');
        }
      })
      .catch((error) => {
        showAlert('error', 'Erreur lors de la mise à jour du texte');
      });
  });

  // Gestion du drag-and-drop pour les images
  ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
    dropZone.addEventListener(eventName, (e) => e.preventDefault());
    dropZone.addEventListener(eventName, (e) => e.stopPropagation());
  });

  ['dragenter', 'dragover'].forEach((eventName) => {
    dropZone.addEventListener(eventName, () =>
      dropZone.classList.add('dragging')
    );
  });

  ['dragleave', 'drop'].forEach((eventName) => {
    dropZone.addEventListener(eventName, () =>
      dropZone.classList.remove('dragging')
    );
  });

  // Gérer le drop de fichiers
  dropZone.addEventListener('drop', (e) => {
    imagesInput.files = e.dataTransfer.files;
    submitImagesForm();
  });

  // Gérer le clic sur la drop zone pour ouvrir le sélecteur de fichiers
  dropZone.addEventListener('click', () => {
    imagesInput.click();
  });

  // Gérer la sélection manuelle de fichiers
  imagesInput.addEventListener('change', () => {
    submitImagesForm();
  });

  // Soumission du formulaire d'images
  function submitImagesForm() {
    const updateId = imagesForm.getAttribute('data-id');
    const formData = new FormData();

    for (let i = 0; i < imagesInput.files.length; i++) {
      formData.append('images', imagesInput.files[i]);
    }

    fetch(`/api/v1/update/${updateId}/images`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          // Ajouter les nouvelles images au conteneur
          data.data.images.forEach((image) => {
            const imgElement = createImageElement(image, updateId);
            imagesContainer.appendChild(imgElement);
          });
          showAlert('success', 'Images ajoutées avec succès');
          imagesInput.value = ''; // Réinitialiser le champ de fichier
        } else {
          showAlert('error', "Erreur lors de l'ajout des images");
        }
      })
      .catch((error) => {
        showAlert('error', "Erreur lors de l'ajout des images");
      });
  }
}

if (roadMap) {
  const logItems = document.querySelectorAll('.roadmap__logs--maj');
  const updateContainers = document.querySelectorAll('.roadmap__updates');

  logItems.forEach((logItem) => {
    logItem.addEventListener('click', function () {
      const id = this.getAttribute('data-id');
      const correspondingUpdate = document.querySelector(
        `.roadmap__updates[data-id="${id}"]`
      );

      logItems.forEach((item) => {
        if (item !== this) {
          item.classList.remove('roadmap__logs--active');
          item.querySelector('span').innerHTML = '&#x25BA;'; // Flèche vers la droite
        }
      });

      updateContainers.forEach((container) => {
        if (container !== correspondingUpdate) {
          container.classList.add('hidden');
        }
      });

      this.classList.toggle('roadmap__logs--active');
      const isActive = this.classList.contains('roadmap__logs--active');
      this.querySelector('span').innerHTML = isActive ? '&#x25BC;' : '&#x25BA;'; // Flèche vers le bas pour actif, vers la droite pour inactif

      correspondingUpdate.classList.toggle('hidden');
    });
  });

  // Fonction pour mettre à jour l'état de la mise à jour
  const updateUpdateDevStatus = async (id, isDev) => {
    try {
      const res = await axios({
        method: 'PATCH',
        url: `/api/v1/update/${id}`,
        data: {
          isDev,
        },
      });

      if (res.data.status === 'success') {
        showAlert('success', 'Mise à jour réussie');
      }
    } catch (err) {
      console.error('Erreur lors de la mise à jour', err);
      showAlert('error', 'Erreur lors de la mise à jour');
    }
  };

  // Ajoutez des écouteurs d'événements pour chaque case à cocher
  const checkboxes = document.querySelectorAll('input[name="isDev"]');
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', function () {
      const id = this.getAttribute('data-id');
      const isDev = this.checked;
      updateUpdateDevStatus(id, isDev);
    });
  });
}

/************************* Boutons ultimes *************************/
if (document.querySelector('.ultime__btn--phone')) {
  document
    .querySelector('.ultime__btn--phone')
    .addEventListener('click', async function (e) {
      try {
        const res = await axios({
          method: 'GET',
          url: `/api/v1/users/ultime-update-phone`,
        });

        if (res.data.status === 'success') {
          showAlert('success', 'Phone numbers updated!');
        }
      } catch (err) {
        showAlert('error', 'Error, check the console!');
        console.error(err.response.data.message);
      }
    });
}

if (document.querySelector('.ultime__btn--plan')) {
  document
    .querySelector('.ultime__btn--plan')
    .addEventListener('click', async function (e) {
      try {
        const res = await axios({
          method: 'GET',
          url: `/api/v1/users/ultime-update-plan`,
        });

        if (res.data.status === 'success') {
          showAlert('success', 'Plans updated!');
        }
      } catch (err) {
        showAlert('error', 'Error, check the console!');
        console.error(err.response.data.message);
      }
    });
}

if (document.querySelector('.ultime__btn--address')) {
  document
    .querySelector('.ultime__btn--address')
    .addEventListener('click', async function (e) {
      try {
        const res = await axios({
          method: 'GET',
          url: `/api/v1/users/ultime-update-address`,
        });

        if (res.data.status === 'success') {
          showAlert('success', 'Addresses updated!');
        }
      } catch (err) {
        showAlert('error', 'Error, check the console!');
        console.error(err.response.data.message);
      }
    });
}

if (document.querySelector('.ultime__btn--subscriptions')) {
  document
    .querySelector('.ultime__btn--subscriptions')
    .addEventListener('click', async function (e) {
      try {
        const res = await axios({
          method: 'GET',
          url: `/api/v1/users/ultime-update-subscriptions`,
        });

        if (res.data.status === 'success') {
          showAlert('success', 'Subscriptions updated!');
        }
      } catch (err) {
        showAlert('error', 'Error, check the console!');
        console.error(err.response.data.message);
      }
    });
}

/************************* Video *************************/

const videoTutos = document.querySelector('.tutos');
const videoForm = document.getElementById('videoForm');
const videoAdmin = document.querySelector('.videoAdmin');

if (videoTutos) {
  document.addEventListener('DOMContentLoaded', () => {
    const videoImages = document.querySelectorAll('.tutos__video--img-overlay');
    const modal = document.getElementById('videoModal');
    const closeModalBtn = document.querySelector('.tutos__modal--close');
    const youtubePlayer = document.getElementById('youtubePlayer');
    const modalTitle = document.getElementById('modalTitle');
    const modalDescription = document.getElementById('modalDescription');

    // Ouvrir la modal lorsque l'utilisateur clique sur l'image de la vidéo
    videoImages.forEach((image) => {
      image.addEventListener('click', function () {
        const video = this.closest('.tutos__video'); // Trouver le conteneur parent .tutos__video
        const videoId = video.getAttribute('data-video-id');
        const title = video.getAttribute('data-title');
        const description = video.getAttribute('data-description');

        // Mettre à jour le contenu de la modal
        youtubePlayer.src = `https://www.youtube.com/embed/${videoId}?rel=0&controls=1&modestbranding=1&showinfo=0&autohide=1`;
        modalTitle.textContent = title;
        modalDescription.textContent = description;

        // Afficher la modal
        modal.style.display = 'block';

        // Ecouter la touche 'Escape' même dans l'iframe
        youtubePlayer.contentWindow.focus();
        youtubePlayer.contentWindow.addEventListener(
          'keydown',
          function (event) {
            if (event.key === 'Escape') {
              closeModal();
            }
          }
        );
      });
    });

    // Fermer la modal
    function closeModal() {
      modal.style.display = 'none';
      youtubePlayer.src = ''; // Stopper la vidéo
    }

    closeModalBtn.addEventListener('click', closeModal);

    // Fermer la modal si l'utilisateur clique en dehors du contenu
    window.addEventListener('click', (event) => {
      if (event.target === modal) {
        closeModal();
      }
    });

    // Fermer la modal avec la touche "Escape" lorsque le focus n'est pas dans l'iframe
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    });
  });

  document.addEventListener('DOMContentLoaded', () => {
    // Récupérer les éléments de la sidebar, des vidéos et du message d'absence de vidéos
    const categoryButtons = document.querySelectorAll('.tutos__sidebar--title');
    const videoCards = document.querySelectorAll('.tutos__video');
    const noVideosMessage = document.querySelector('.no-videos');

    // Fonction pour afficher les vidéos par catégorie
    function filterVideos(category) {
      let hasVisibleVideos = false;

      videoCards.forEach((card) => {
        if (card.getAttribute('data-category') === category) {
          card.classList.remove('hidden');
          hasVisibleVideos = true;
        } else {
          card.classList.add('hidden');
        }
      });

      // Afficher ou masquer le message "Aucune vidéo"
      if (hasVisibleVideos) {
        noVideosMessage.classList.add('hidden');
      } else {
        noVideosMessage.classList.remove('hidden');
      }
    }

    // Ajouter un écouteur d'événements sur chaque bouton de catégorie
    categoryButtons.forEach((button) => {
      button.addEventListener('click', function () {
        // Supprimer la classe active de tous les boutons
        categoryButtons.forEach((btn) =>
          btn.classList.remove('tutos__category--active')
        );

        // Ajouter la classe active au bouton cliqué
        this.classList.add('tutos__category--active');

        // Filtrer les vidéos
        const selectedCategory = this.getAttribute('data-category');
        filterVideos(selectedCategory);
      });
    });

    // Par défaut, afficher les vidéos de la catégorie "general" et appliquer la classe active
    const defaultButton = document.querySelector(
      '.tutos__sidebar--title[data-category="general"]'
    );
    if (defaultButton) {
      defaultButton.classList.add('tutos__category--active');
    }
    filterVideos('general');
  });
}

if (videoForm) {
  videoForm.addEventListener('submit', async function (e) {
    e.preventDefault();

    // Récupérer les valeurs des champs du formulaire
    const title = document.getElementById('title').value;
    const titleFR = document.getElementById('titleFR').value;
    const category = document.getElementById('category').value;
    const description = document.getElementById('description').value;
    const descriptionFR = document.getElementById('descriptionFR').value;
    const link = document.getElementById('link').value;

    // Construire l'objet pour l'ajout de la vidéo
    const videoData = {
      title,
      titleFR,
      category,
      description,
      descriptionFR,
      link,
    };

    try {
      // Envoyer la requête POST pour créer une nouvelle vidéo
      const res = await axios({
        method: 'POST',
        url: '/api/v1/video/',
        data: videoData,
      });

      if (res.data.status === 'success') {
        showAlert('success', 'Vidéo ajoutée avec succès');
        window.setTimeout(() => {
          location.reload();
        }, 1500);
      }
    } catch (err) {
      showAlert('error', "Erreur lors de l'ajout de la vidéo");
    }
  });
}

if (videoAdmin) {
  document.addEventListener('DOMContentLoaded', () => {
    const modifyBtn = document.querySelectorAll('.tutos__card--modify');
    const editFormVideoAdmin = document.querySelectorAll('.edit-video-form');

    modifyBtn.forEach((btn, index) => {
      btn.addEventListener('click', () => {
        // Toggle la classe 'hidden' uniquement sur le formulaire correspondant
        editFormVideoAdmin[index].classList.toggle('hidden');
      });
    });

    // Fonction pour basculer entre l'affichage et l'édition des champs
    function toggleIcons(editIcon, confirmIcon, title, input) {
      editIcon.classList.toggle('hidden');
      confirmIcon.classList.toggle('hidden');
      title.classList.toggle('hidden');
      input.classList.toggle('hidden');
    }

    // Gestion de l'édition des champs
    const editIcons = document.querySelectorAll('.form__icon--edit');
    const confirmIcons = document.querySelectorAll('.form__icon--confirm');

    editIcons.forEach((editIcon) => {
      editIcon.addEventListener('click', function () {
        const inputData = editIcon.getAttribute('data-input');
        const confirmIcon = editIcon
          .closest('.form__group')
          .querySelector(`.form__icon--confirm[data-input="${inputData}"]`);
        const title = editIcon
          .closest('.form__group--flex')
          .querySelector(`.form__text--${inputData}`);
        const input = editIcon
          .closest('.form__group--flex')
          .querySelector(`.form__input--${inputData}`);

        toggleIcons(editIcon, confirmIcon, title, input);
      });
    });

    confirmIcons.forEach((confirmIcon) => {
      confirmIcon.addEventListener('click', function () {
        const inputData = confirmIcon.getAttribute('data-input');
        const editIcon = confirmIcon
          .closest('.form__group')
          .querySelector(`.form__icon--edit[data-input="${inputData}"]`);
        const title = confirmIcon
          .closest('.form__group--flex')
          .querySelector(`.form__text--${inputData}`);
        const input = confirmIcon
          .closest('.form__group--flex')
          .querySelector(`.form__input--${inputData}`);
        const inputValue = input.querySelector('input, textarea').value;

        // Mise à jour du texte affiché avec seulement la nouvelle valeur
        title.textContent = inputValue;

        toggleIcons(editIcon, confirmIcon, title, input);
      });
    });

    // Gestion de l'enregistrement de toutes les modifications
    const editForms = document.querySelectorAll('.edit-video-form');
    editForms.forEach((form) => {
      form.addEventListener('submit', async function (e) {
        e.preventDefault();
        const videoId = this.getAttribute('data-id');
        console.log(this);

        // Récupérer les valeurs des champs du formulaire actuel
        const title = this.querySelector('input[name="title"]')?.value;
        const titleFR = this.querySelector('input[name="titleFR"]')?.value;
        const category = this.querySelector('select[name="category"]').value;
        const description = this.querySelector(
          'textarea[name="description"]'
        )?.value;
        const descriptionFR = this.querySelector(
          'textarea[name="descriptionFR"]'
        )?.value;
        const link = this.querySelector('input[name="link"]')?.value;

        // Assurez-vous que toutes les valeurs sont bien récupérées
        if (
          !title ||
          !titleFR ||
          !category ||
          !description ||
          !descriptionFR ||
          !link
        ) {
          console.error('Some fields are missing values.');
          showAlert('error', 'Tous les champs doivent être remplis.');
          return;
        }

        const data = {
          title,
          titleFR,
          category,
          description,
          descriptionFR,
          link,
        };

        console.log('Data sent to server:', data); // Vérifier les valeurs récupérées

        try {
          const res = await axios({
            method: 'PATCH',
            url: `/api/v1/video/${videoId}`,
            data: data,
          });

          if (res.data.status === 'success') {
            showAlert('success', 'Modifications enregistrées avec succès');
            window.setTimeout(() => {
              location.reload();
            }, 1500);
          }
        } catch (err) {
          console.error('Erreur lors de la mise à jour:', err);
          showAlert('error', 'Erreur lors de la mise à jour de la vidéo');
        }
      });
    });

    // Gestion de la suppression
    const deleteButtons = document.querySelectorAll('.btn-delete');
    deleteButtons.forEach((button) => {
      button.addEventListener('click', async function () {
        const videoId =
          this.closest('.edit-video-form').getAttribute('data-id');

        try {
          console.log(`Attempting to delete video with ID: ${videoId}`); // Debugging log
          const res = await axios({
            method: 'DELETE',
            url: `/api/v1/video/${videoId}`,
          });

          if (res.status === 204) {
            // Vérification du statut 204 pour succès
            showAlert('success', 'Vidéo supprimée avec succès');
            window.setTimeout(() => {
              location.reload();
            }, 1500);
          } else {
            showAlert('error', 'Problème lors de la suppression de la vidéo');
          }
        } catch (err) {
          console.error('Erreur lors de la suppression:', err);
          showAlert('error', 'Erreur lors de la suppression de la vidéo');
          window.setTimeout(() => {
            location.reload();
          }, 1500);
        }
      });
    });
  });

  document.addEventListener('DOMContentLoaded', () => {
    // Récupérer les éléments de la sidebar et des vidéos
    const categoryButtons = document.querySelectorAll('.tutos__sidebar--title');
    const videoCards = document.querySelectorAll('.tutos__card');

    // Fonction pour afficher les vidéos par catégorie
    function filterVideos(category) {
      videoCards.forEach((card) => {
        if (card.getAttribute('data-category') === category) {
          card.classList.remove('hidden');
        } else {
          card.classList.add('hidden');
        }
      });
    }

    // Ajouter un écouteur d'événements sur chaque bouton de catégorie
    categoryButtons.forEach((button) => {
      button.addEventListener('click', function () {
        // Supprimer la classe active de tous les boutons
        categoryButtons.forEach((btn) =>
          btn.classList.remove('tutos__category--active')
        );

        // Ajouter la classe active au bouton cliqué
        this.classList.add('tutos__category--active');

        // Filtrer les vidéos
        const selectedCategory = this.getAttribute('data-category');
        filterVideos(selectedCategory);
      });
    });

    // Par défaut, afficher les vidéos de la catégorie "general" et appliquer la classe active
    const defaultButton = document.querySelector(
      '.tutos__sidebar--title[data-category="general"]'
    );
    if (defaultButton) {
      defaultButton.classList.add('tutos__category--active');
    }
    filterVideos('general');
  });

  document.addEventListener('DOMContentLoaded', () => {
    // Récupérer toutes les catégories
    const categoryContainers = document.querySelectorAll(
      '.tutos__content--flex'
    );

    // Initialiser SortableJS sur chaque catégorie
    categoryContainers.forEach((container) => {
      new Sortable(container, {
        handle: '.tutos__card--moove', // La poignée pour déplacer les cartes
        animation: 150, // Animation de déplacement
        onEnd: function (/**Event*/ evt) {
          // Récupérer l'ordre des cartes après le déplacement
          const newOrder = [...container.children].map(
            (card) => card.getAttribute('data-id') // Assurez-vous que cet attribut est correct
          );

          // Appeler une fonction pour mettre à jour l'ordre dans la base de données
          updateVideoOrder(newOrder);
        },
      });
    });

    // Fonction pour mettre à jour l'ordre des vidéos dans la base de données
    async function updateVideoOrder(order) {
      try {
        const response = await axios.post('/api/v1/video/updateOrder', {
          order,
        });
        if (response.data.status === 'success') {
          showAlert('success', "L'ordre des vidéos a été mis à jour.");
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'ordre:", error);
        showAlert('error', "Erreur lors de la mise à jour de l'ordre.");
      }
    }
  });
}

/************************* UTLIME *************************/

if (document.querySelector('.ultime__btn--phone')) {
  document
    .querySelector('.ultime__btn--phone')
    .addEventListener('click', async function (e) {
      try {
        const res = await axios({
          method: 'GET',
          url: `/api/v1/users/ultime-update-phone`,
        });

        if (res.data.status === 'success') {
          showAlert('success', 'Phone numbers updated!');
        }
      } catch (err) {
        showAlert('error', 'Error, check the console!');
        console.error(err.response.data.message);
      }
    });
}
