const salePage = document.querySelector('.salePage');

if (salePage) {
  /// Charger le script TikTok uniquement quand la pagee à charger et pareil pour swiper
  document.addEventListener('DOMContentLoaded', () => {
    const loadTikTokScript = () => {
      const script = document.createElement('script');
      script.src =
        'https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=CLNI313C77U5HE4E52DG';
      script.async = true;
      document.body.appendChild(script);
    };

    document
      .querySelector('body')
      .addEventListener('mousemove', loadTikTokScript, { once: true });

    const slider = document.querySelector('.swiper-container');
    const loadSwiper = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.js';
      script.async = true;
      script.onload = () => {
        const swiper = new Swiper('.swiper-container', {
          // Options Swiper ici
        });
      };
      document.body.appendChild(script);
    };

    slider.addEventListener('mouseover', loadSwiper, { once: true });
  });

  /************************* SalePage Javascript *************************/
  // import { Froogaloop } from '../public/froogaloop.js';

  rewardful('ready', function () {
    if (Rewardful.referral) {
      console.log('Referral ID:' + Rewardful.referral);
    } else {
      console.log('No referral ID');
    }
  });

  // Sélectionne toutes les images dans la grille
  const images = document.querySelectorAll('.features__card--img');

  // Sélectionne la popup et ses éléments internes
  const popup = document.getElementById('features-image-popup');
  const popupImg = popup.querySelector('img');
  const popupClose = popup.querySelector('.popup-close');
  const body = document.body;

  // Ajouter un gestionnaire de clic pour chaque image
  images.forEach((image) => {
    image.addEventListener('click', () => {
      popupImg.src = image.src; // Assigner la source de l'image cliquée à la popup
      popup.classList.remove('hidden'); // Afficher la popup
      body.classList.add('no-scroll'); // Empêcher le défilement
    });
  });

  // Fermer la popup en cliquant sur le bouton de fermeture
  popupClose.addEventListener('click', () => {
    popup.classList.add('hidden');
    body.classList.remove('no-scroll');
  });

  // Fermer la popup en cliquant en dehors de l'image (dans la zone grise de la popup)
  popup.addEventListener('click', (e) => {
    if (e.target === popup) {
      popup.classList.add('hidden');
      body.classList.remove('no-scroll');
    }
  });

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
      popup.classList.add('hidden');
      body.classList.remove('no-scroll');
    }
  });

  //////////// Tabs 1
  const tabs = document.querySelectorAll('.tab__btn');
  const allContent = document.querySelectorAll('.tab__content');

  tabs.forEach((tab, i) => {
    tab.addEventListener('click', () => {
      tabs.forEach((tab) => {
        tab.classList.remove('tab__content--active');
      });

      tab.classList.add('tab__content--active');

      allContent.forEach((content) =>
        content.classList.remove('tab__content--active')
      );

      allContent[i].classList.add('tab__content--active');
    });
  });

  //////////// Tabs 2
  const tabs2 = document.querySelectorAll('.tab__btn-2');
  const allContent2 = document.querySelectorAll('.tab__content-2');

  tabs2.forEach((tab, i) => {
    tab.addEventListener('click', () => {
      tabs2.forEach((tab) => {
        tab.classList.remove('tab__content--active-2');
      });

      tab.classList.add('tab__content--active-2');

      allContent2.forEach((content) =>
        content.classList.remove('tab__content--active-2')
      );

      allContent2[i].classList.add('tab__content--active-2');
    });
  });

  //////////// Accordion
  const accordionItemHeaders = document.querySelectorAll(
    '.accordion__item--header'
  );

  accordionItemHeaders.forEach((accordionItemHeader) => {
    accordionItemHeader.addEventListener('click', (event) => {
      // Uncomment in case you only want to allow for the display of only one collapsed item at a time!

      const currentlyActiveAccordionItemHeader = document.querySelector(
        '.accordion__item--header.active'
      );
      if (
        currentlyActiveAccordionItemHeader &&
        currentlyActiveAccordionItemHeader !== accordionItemHeader
      ) {
        currentlyActiveAccordionItemHeader.classList.toggle('active');
        currentlyActiveAccordionItemHeader.nextElementSibling.style.maxHeight = 0;
      }

      accordionItemHeader.classList.toggle('active');
      const accordionItemBody = accordionItemHeader.nextElementSibling;
      if (accordionItemHeader.classList.contains('active')) {
        accordionItemBody.style.maxHeight =
          accordionItemBody.scrollHeight + 'px';
      } else {
        accordionItemBody.style.maxHeight = 0;
      }
    });
  });

  //////////// Sticky Navigation

  const sectionHeroEl = document.querySelector('.banner');

  const obs = new IntersectionObserver(
    function (entries) {
      const ent = entries[0];
      if (ent.isIntersecting === false) {
        document.body.classList.add('sticky');
      }
      if (ent.isIntersecting) {
        document.body.classList.remove('sticky');
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-130px',
    }
  );

  if (sectionHeroEl) {
    obs.observe(sectionHeroEl);
  }

  const headerEl = document.querySelector('.header-salepage__sale-page');
  const bannerBar = document.querySelector('.banner__bar');

  // document.querySelectorAll('a[href^="/#"]').forEach((anchor) => {
  //   let hash = anchor.hash;

  //   anchor.addEventListener('click', function (e) {
  //     e.preventDefault();

  //     const targetAnchor = document.querySelector(hash);
  //     let scrollTop = targetAnchor.offsetTop;
  //     const newScrollTop = scrollTop - 100;

  //     window.scrollTo(0, newScrollTop);
  //   });
  // });

  //////////// Swiper speed test

  const swiperSpeed = new Swiper('.swiper--speed', {
    // Optional parameters
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-button-next--speed',
      prevEl: '.swiper-button-prev--speed',
    },
  });

  //////////// Swiper video reviews

  const swiper = new Swiper('.swiper--video-testi', {
    // Optional parameters
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  //////////// Swiper TrustPilot reviews

  let SwiperTop = new Swiper('.swiper--top', {
    spaceBetween: 0,
    centeredSlides: true,
    speed: 10000,
    autoplay: {
      delay: 1,
    },
    loop: true,
    loopedSlides: 2,
    slidesPerView: 'auto',
    allowTouchMove: false,
    disableOnInteraction: true,
  });

  let SwiperBottom = new Swiper('.swiper--bottom', {
    spaceBetween: 0,
    centeredSlides: true,
    speed: 10000,
    autoplay: {
      delay: 1,
      reverseDirection: true,
    },

    loop: true,
    loopedSlides: 2,
    slidesPerView: 'auto',
    allowTouchMove: false,
    disableOnInteraction: true,
  });

  //////////// Modal popup more infos pricing table

  const modal = document.querySelector('.bonus-modal');
  const overlay = document.querySelector('.overlay');
  const btnCloseModal = document.querySelector('.close-bonus-modal');
  const btnsOpenModal = document.querySelectorAll('.show-modal');

  const openModal = function () {
    modal.classList.remove('hidden');
    overlay.classList.remove('hidden');
    document.body.classList.add('noscroll');
  };

  const closeModal = function () {
    modal.classList.add('hidden');
    overlay.classList.add('hidden');
    document.body.classList.remove('noscroll');
  };

  for (let i = 0; i < btnsOpenModal.length; i++) {
    btnsOpenModal[i].addEventListener('click', openModal);

    btnCloseModal.addEventListener('click', closeModal);
    overlay.addEventListener('click', closeModal);

    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
        closeModal();
      }

      if (e.key === 'Escape' && !allModals.classList.contains('hidden')) {
        closeModal();
      }
    });
  }

  //////////// Modal popup Features
  const allModals = document.querySelectorAll('.modal');
  const allModalsButtons = document.querySelectorAll('.btn__open--modal');
  const allModalsCloseButtons = document.querySelectorAll('.close-modal');

  // Open modal
  allModalsButtons.forEach((modalBtn) => {
    modalBtn.addEventListener('click', (e) => {
      document
        .getElementById(e.currentTarget.dataset.modal)
        .classList.remove('hidden');
      overlay.classList.remove('hidden');
      document.body.classList.add('noscroll');

      if (modalBtn.dataset.videoToPlay)
        document.getElementById(modalBtn.dataset.videoToPlay).play();
    });
  });

  const pauseVideo = () => {
    const allTrust = document.querySelectorAll('.video-trust');
    for (let i = 0; i < allTrust.length; i++) {
      const iframe = document.getElementById(`video-${i}`);
      const player = $f(iframe);
      player.api('pause');
    }
  };

  const closeModalWithIframe = () => {
    allModals.forEach((e) => e.classList.add('hidden'));
    overlay.classList.add('hidden');
    document.body.classList.remove('noscroll');
    pauseVideo();
  };

  // Close clicking btn close
  allModalsCloseButtons.forEach((closeBtn) => {
    closeBtn.addEventListener('click', (e) => {
      closeModalWithIframe();
    });
  });

  // Close clicking overlay
  if (overlay) {
    overlay.addEventListener('click', (e) => {
      closeModalWithIframe();
    });

    // Close press escape
    document.addEventListener('keydown', function (e) {
      allModals.forEach((modal) => {
        if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
          modal.classList.add('hidden');
          overlay.classList.add('hidden');
          document.body.classList.remove('noscroll');
        }
      });
    });
  }

  // CHECKOUT SESSION NOTION PRODUCT SEARCH
  const btnCheckoutNotionSearchProduct = document.getElementById(
    'checkoutNotionSearchProduct'
  );

  if (btnCheckoutNotionSearchProduct) {
    const launchCheckoutNotionSearchProduct = async () => {
      try {
        const response = await fetch(
          '/api/v1/users/checkout-notion-search-product',
          { method: 'POST' }
        );

        const data = await response.json();
        const userData = data.data;

        if (response.ok) window.location.href = userData.session_url;
      } catch (err) {
        console.log(err);
      }
    };

    btnCheckoutNotionSearchProduct.addEventListener('click', () => {
      btnCheckoutNotionSearchProduct.textContent = 'Loading...';
      launchCheckoutNotionSearchProduct();
    });
  }

  /************************* TAKE PLAN *************************/
  const btnTakeStandardSub = document.getElementById('takeStandardSub');
  const btnTakePlusSub = document.getElementById('takePlusSub');
  const btnTakeBusinessSub = document.getElementById('takeBusinessSub');
  const btnTakeStandardOne = document.getElementById('takeStandardOne');
  const btnTakePlusOne = document.getElementById('takePlusOne');
  const btnTakeBusinessOne = document.getElementById('takeBusinessOne');

  const takePlanOne = async (newShops, price, referral) => {
    try {
      const response = await fetch(
        `/take-plan-one/${newShops}/${price}/${referral}`,
        {
          method: 'POST',
        }
      );

      const data = await response.json();
      const userData = data.data;

      if (response.ok) window.location.href = userData.session_url;
    } catch (err) {
      console.log(err);
    }
  };

  if (btnTakeStandardOne) {
    btnTakeStandardOne.addEventListener('click', (e) => {
      e.preventDefault();
      btnTakeStandardOne.querySelector('p').textContent = 'Loading...';
      rewardful('ready', function () {
        if (Rewardful.referral) {
          takePlanOne(1, btnTakeStandardOne.dataset.price, Rewardful.referral);
        } else {
          takePlanOne(1, btnTakeStandardOne.dataset.price);
        }
      });
    });
  }

  if (btnTakePlusOne) {
    btnTakePlusOne.addEventListener('click', (e) => {
      e.preventDefault();
      btnTakePlusOne.querySelector('p').textContent = 'Loading...';
      rewardful('ready', function () {
        if (Rewardful.referral) {
          takePlanOne(3, btnTakePlusOne.dataset.price, Rewardful.referral);
        } else {
          takePlanOne(3, btnTakePlusOne.dataset.price);
        }
      });
    });
  }

  if (btnTakeBusinessOne) {
    btnTakeBusinessOne.addEventListener('click', (e) => {
      e.preventDefault();
      btnTakeBusinessOne.querySelector('p').textContent = 'Loading...';
      rewardful('ready', function () {
        if (Rewardful.referral) {
          takePlanOne(
            100,
            btnTakeBusinessOne.dataset.price,
            Rewardful.referral
          );
        } else {
          takePlanOne(100, btnTakeBusinessOne.dataset.price);
        }
      });
    });
  }

  const takePlanSub = async (newShops, price, referral) => {
    try {
      const response = await fetch(
        `/take-plan-sub/${newShops}/${price}/${referral}`,
        {
          method: 'POST',
        }
      );

      const data = await response.json();
      const userData = data.data;

      if (response.ok) window.location.href = userData.session_url;
    } catch (err) {
      console.log(err);
    }
  };

  if (btnTakeStandardSub) {
    btnTakeStandardSub.addEventListener('click', () => {
      btnTakeStandardSub.querySelector('p').textContent = 'Loading...';
      rewardful('ready', function () {
        if (Rewardful.referral) {
          takePlanSub(1, btnTakeStandardSub.dataset.price, Rewardful.referral);
        } else {
          takePlanSub(1, btnTakeStandardSub.dataset.price);
        }
      });
    });
  }

  if (btnTakePlusSub) {
    btnTakePlusSub.addEventListener('click', () => {
      btnTakePlusSub.querySelector('p').textContent = 'Loading...';
      rewardful('ready', function () {
        if (Rewardful.referral) {
          takePlanSub(3, btnTakePlusSub.dataset.price, Rewardful.referral);
        } else {
          takePlanSub(3, btnTakePlusSub.dataset.price);
        }
      });
    });
  }

  if (btnTakeBusinessSub) {
    btnTakeBusinessSub.addEventListener('click', () => {
      btnTakeBusinessSub.querySelector('p').textContent = 'Loading...';
      rewardful('ready', function () {
        if (Rewardful.referral) {
          takePlanSub(
            100,
            btnTakeBusinessSub.dataset.price,
            Rewardful.referral
          );
        } else {
          takePlanSub(100, btnTakeBusinessSub.dataset.price);
        }
      });
    });
  }
}
