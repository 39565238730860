import { showAlert } from './alerts';

const colorifyFR = document.getElementById('colorify-FR');

if (colorifyFR) {
  /////////////////////////// Contrast Checker /////////////////////////////////////

  // Fonction pour calculer le ratio de contraste
  function getContrastRatio(foreground, background) {
    const lum1 = getLuminance(foreground);
    const lum2 = getLuminance(background);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }

  // Fonction pour obtenir la luminance d'une couleur
  function getLuminance(hex) {
    const rgb = hexToRgb(hex);
    const a = rgb.map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  // Fonction pour convertir le code hex en RGB
  function hexToRgb(hex) {
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length == 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length == 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return [r, g, b];
  }

  // Fonction pour déterminer le retour en fonction du ratio de contraste
  function getFeedback(contrastRatio, type) {
    if (type === 'small') {
      if (contrastRatio >= 7) return 'Parfait';
      if (contrastRatio >= 4.5) return 'Bon';
      if (contrastRatio >= 3) return 'Moyen';
      return 'Mauvais';
    } else if (type === 'large') {
      if (contrastRatio >= 7) return 'Parfait';
      if (contrastRatio >= 3) return 'Bon';
      if (contrastRatio >= 2) return 'Moyen';
      return 'Mauvais';
    }
    return 'Très mauvais';
  }

  // Fonction pour mettre à jour la couleur de fond en fonction du retour
  function updateBackgroundColor(element, feedback) {
    if (feedback === 'Très mauvais' || feedback === 'Mauvais') {
      element.style.backgroundColor = '#e97373';
      element.style.color = '#260000';
    } else if (feedback === 'Moyen') {
      element.style.backgroundColor = '#d8e973';
      element.style.color = '#293000';
    } else if (feedback === 'Bon' || feedback === 'Parfait') {
      element.style.backgroundColor = '#9fda91';
      element.style.color = '#072001';
    }
  }

  // Fonction pour mettre à jour le ratio de contraste et le retour
  function updateContrast() {
    const textColor = document.getElementById('textColorInput').value;
    const backgroundColor = document.getElementById(
      'backgroundColorInput'
    ).value;
    const contrastRatio = getContrastRatio(textColor, backgroundColor).toFixed(
      2
    );

    document.getElementById('contrastRatioResult').textContent = contrastRatio;

    const smallTextFeedback = getFeedback(contrastRatio, 'small');
    document.getElementById('smallTextContrastFeedback').textContent =
      smallTextFeedback;

    const largeTextFeedback = getFeedback(contrastRatio, 'large');
    document.getElementById('largeTextContrastFeedback').textContent =
      largeTextFeedback;

    let generalFeedback = getFeedback(contrastRatio, 'small');
    document.getElementById('contrastFeedback').textContent = generalFeedback;

    const generalElement = document.querySelector('.contrast__ratio--general');
    const smallTextElement = document.querySelector('.contrast__ratio--small');
    const largeTextElement = document.querySelector('.contrast__ratio--large');

    updateBackgroundColor(generalElement, generalFeedback);
    updateBackgroundColor(smallTextElement, smallTextFeedback);
    updateBackgroundColor(largeTextElement, largeTextFeedback);

    const previewText = document.querySelectorAll('.contrast-result__text');
    const previewBackground = document.querySelector('.contrast-result');
    const previewBackgroundButton = document.getElementById(
      'backgroundColorButtonEl'
    );
    const previewTextButton = document.getElementById('textColorButtonEl');

    previewText.forEach((previewText) => {
      previewText.style.color = textColor;
    });
    previewTextButton.style.backgroundColor = textColor;
    previewBackground.style.backgroundColor = backgroundColor;
    previewBackgroundButton.style.backgroundColor = backgroundColor;
  }

  // Initialisation du Pickr pour la couleur du texte
  const textColorPicker = Pickr.create({
    container: '#colorTextPickerContainer',
    el: '#textColorButton',
    theme: 'nano',
    default: '#111111',
    components: {
      preview: true,
      hue: true,
    },
  });

  textColorPicker.on('init', (instance) => {
    instance.getRoot().app.classList.add('text-pickr');
  });

  textColorPicker.on('change', (color) => {
    const hexColor = color.toHEXA().toString();
    document.getElementById('textColorInput').value = hexColor;
    updateContrast();
  });

  // Initialisation du Pickr pour la couleur de fond
  const backgroundColorPicker = Pickr.create({
    container: '#colorBackgroundPickerContainer',
    el: '#backgroundColorButton',
    theme: 'nano',
    default: '#e5dbff',
    components: {
      preview: true,
      hue: true,
    },
  });

  backgroundColorPicker.on('init', (instance) => {
    instance.getRoot().app.classList.add('background-pickr');
  });

  backgroundColorPicker.on('change', (color) => {
    const hexColor = color.toHEXA().toString();
    document.getElementById('backgroundColorInput').value = hexColor;
    updateContrast();
  });

  // Écouteurs pour les champs de texte
  document
    .getElementById('textColorInput')
    .addEventListener('input', updateContrast);
  document
    .getElementById('backgroundColorInput')
    .addEventListener('input', updateContrast);

  // Calcul initial
  updateContrast();

  /////////////////////////// Générateur palette /////////////////////////////////////

  // Gestion des boutons pour la sélection de couleur
  const buttonsChooseColor = document.querySelectorAll(
    '.color-create__color--buttons button'
  );
  const iaSection = document.querySelector('.color-create__color--ia');
  const colorSection = document.querySelector('.color-create__color--color');
  const exactColorSection = document.querySelector(
    '.color-create__color--exact-color'
  );

  // Fonction pour mettre à jour les sections en fonction de la sélection
  function updateColorSection(selectedValue) {
    iaSection.classList.add('hidden');
    colorSection.classList.add('hidden');
    exactColorSection.classList.add('hidden');

    if (selectedValue === 'ia') {
      iaSection.classList.remove('hidden');
    } else if (selectedValue === 'color') {
      colorSection.classList.remove('hidden');
    } else if (selectedValue === 'exact-color') {
      exactColorSection.classList.remove('hidden');
    }
  }

  // Fonction pour gérer l'état du bouton actif
  function setActiveButton(button) {
    buttonsChooseColor.forEach((btn) =>
      btn.classList.remove('color-create__color--active')
    );
    button.classList.add('color-create__color--active');
  }

  // Ajout des événements sur les boutons
  buttonsChooseColor.forEach((button) => {
    button.addEventListener('click', (e) => {
      const value = e.target.getAttribute('value');
      updateColorSection(value);
      setActiveButton(e.target);
    });
  });

  // Appel initial pour afficher la section par défaut
  updateColorSection('ia');

  const generateColors = document.getElementById('generateColors');
  const generateColorsPopup = document.getElementById('generateColorsPopup');
  const popupOverlay = document.getElementById('popupOverlay');

  // Ouvre la popup
  generateColors.addEventListener('click', () => {
    generateColorsPopup.classList.remove('hidden');
    popupOverlay.classList.remove('hidden');
    document.body.classList.add('no-scroll');
  });

  /////////////////////////// API GPT pour générer les couleurs /////////////////////////////////////

  // Fonction pour envoyer la demande au serveur pour générer des palettes
  async function generateColorPalettes(
    description,
    colorSelection,
    exactColor = null
  ) {
    try {
      const response = await fetch('/api/v1/palette/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description,
          colorSelection,
          exactColor,
        }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        console.log('Réponse du serveur :', data.data);

        // Afficher les palettes générées à l'utilisateur
        document.querySelector('lottie-player').classList.add('hidden');
        document.getElementById('generatedPalettes').classList.remove('hidden');
        document
          .querySelector('.color-create__popup--btns')
          .classList.remove('hidden');

        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      console.error('Erreur lors de la génération des palettes :', err);
    }
  }

  // Fonction pour extraire les infos du formulaire et générer des palettes
  document
    .getElementById('generateColors')
    .addEventListener('click', async () => {
      const description = document.getElementById('brandDescription').value;
      const selectedButton = document.querySelector(
        '.color-create__color--active'
      );
      const colorSelection = selectedButton.getAttribute('value');

      let exactColor = null;
      if (colorSelection === 'exact-color') {
        exactColor = document.getElementById('exactColorInput').value;
      }

      try {
        const palettes = await generateColorPalettes(
          description,
          colorSelection,
          exactColor
        );
        displayPalettes(palettes);
      } catch (error) {
        if (error.message.includes('limite de 100 générations')) {
          alert(
            'Vous avez atteint votre limite de 100 générations de palettes pour ce mois.'
          );
        } else {
          console.error('Erreur lors de la génération des palettes:', error);
        }
      }
    });

  // Fonction pour reformater le dégradé
  function formatGradient(gradient) {
    if (!gradient) return ''; // Si le gradient est vide, on retourne une chaîne vide

    // Extraire les codes couleurs du gradient
    const match = gradient.match(/#([0-9a-fA-F]{6}),?\s*#([0-9a-fA-F]{6})/);

    if (match) {
      return `Linéaire, ${match[1]}, ${match[2]}`; // Reformater pour "Linéaire, #code1, #code2"
    }

    return gradient; // Si le format n'est pas reconnu, on retourne le gradient d'origine
  }

  // Fonction pour afficher les 4 palettes dans la popup
  function displayPalettes(palettes) {
    let paletteArray;
    try {
      paletteArray = JSON.parse(palettes); // Suppose que les palettes sont fournies au format JSON
      if (!Array.isArray(paletteArray) || paletteArray.length < 4) {
        throw new Error('Moins de 4 palettes reçues.');
      }
    } catch (error) {
      console.error(
        'Erreur lors du parsing des palettes ou nombre insuffisant de palettes :',
        error
      );
      return; // On ne peut pas continuer si les palettes ne sont pas correctement reçues
    }

    // Remplissage des couleurs pour les 4 palettes
    for (let i = 1; i <= 4; i++) {
      updatePaletteColors(i, paletteArray[i - 1]);
    }
  }

  // Fonction pour mettre à jour les couleurs d'une palette donnée
  function updatePaletteColors(paletteNumber, palette) {
    // Vérification des données avant d'appliquer les couleurs
    if (!palette || !palette.titles) {
      console.error(
        `Palette ${paletteNumber} est invalide ou n'a pas les données nécessaires.`
      );
      return;
    }

    console.log(`Updating palette ${paletteNumber} with data:`, palette);

    // Préparer les éléments d'aperçu et de code couleur
    const titlesPreview = document.getElementById(
      `palette${paletteNumber}TitlesPreview`
    );
    const textsPreview = document.getElementById(
      `palette${paletteNumber}TextsPreview`
    );
    const textGradientPreview = document.getElementById(
      `palette${paletteNumber}TextGradientPreview`
    );
    const backgroundPreview = document.getElementById(
      `palette${paletteNumber}BackgroundPreview`
    );
    const backgroundSecondaryPreview = document.getElementById(
      `palette${paletteNumber}BackgroundSecondaryPreview`
    );
    const backgroundGradientPreview = document.getElementById(
      `palette${paletteNumber}BackgroundGradientPreview`
    );
    const accentPrimaryPreview = document.getElementById(
      `palette${paletteNumber}Accent1Preview`
    );
    const accent2Preview = document.getElementById(
      `palette${paletteNumber}Accent2Preview`
    );
    const buttonGradientPreview = document.getElementById(
      `palette${paletteNumber}ButtonGradientPreview`
    );

    const titlesCode = document.getElementById(
      `palette${paletteNumber}TitlesCode`
    );
    const textsCode = document.getElementById(
      `palette${paletteNumber}TextsCode`
    );
    const textGradientCode = document.getElementById(
      `palette${paletteNumber}TextGradientCode`
    );
    const backgroundCode = document.getElementById(
      `palette${paletteNumber}BackgroundCode`
    );
    const backgroundSecondaryCode = document.getElementById(
      `palette${paletteNumber}BackgroundSecondaryCode`
    );
    const backgroundGradientCode = document.getElementById(
      `palette${paletteNumber}BackgroundGradientCode`
    );
    const accentPrimaryCode = document.getElementById(
      `palette${paletteNumber}Accent1Code`
    );
    const accent2Code = document.getElementById(
      `palette${paletteNumber}Accent2Code`
    );
    const buttonGradientCode = document.getElementById(
      `palette${paletteNumber}ButtonGradientCode`
    );

    // Log des codes couleurs appliqués
    console.log(
      `Titles: ${palette.titles}, Texts: ${palette.texts}, Background: ${palette.background}`
    );

    // Appliquer les couleurs aux aperçus
    if (titlesPreview)
      titlesPreview.style.backgroundColor = palette.titles || '#000000';
    if (textsPreview)
      textsPreview.style.backgroundColor = palette.texts || '#000000';
    if (textGradientPreview)
      textGradientPreview.style.background =
        palette.textGradient || 'linear-gradient(#000000, #000000)';
    if (backgroundPreview)
      backgroundPreview.style.backgroundColor = palette.background || '#FFFFFF';
    if (backgroundSecondaryPreview)
      backgroundSecondaryPreview.style.backgroundColor =
        palette.backgroundSecondary || '#F2F2F2';
    if (backgroundGradientPreview)
      backgroundGradientPreview.style.background =
        palette.backgroundGradient || 'linear-gradient(#FFFFFF, #F2F2F2)';
    if (accentPrimaryPreview)
      accentPrimaryPreview.style.backgroundColor = palette.accent1 || '#000000';
    if (accent2Preview)
      accent2Preview.style.backgroundColor = palette.accent2 || '#000000';
    if (buttonGradientPreview)
      buttonGradientPreview.style.background =
        palette.buttonGradient || 'linear-gradient(#000000, #FFFFFF)';

    // Appliquer les codes couleurs formatés
    if (titlesCode) titlesCode.textContent = palette.titles || '#000000';
    if (textsCode) textsCode.textContent = palette.texts || '#000000';
    if (textGradientCode)
      textGradientCode.textContent =
        formatGradient(palette.textGradient) || 'Linéaire, #000000, #000000';
    if (backgroundCode)
      backgroundCode.textContent = palette.background || '#FFFFFF';
    if (backgroundSecondaryCode)
      backgroundSecondaryCode.textContent =
        palette.backgroundSecondary || '#F2F2F2';
    if (backgroundGradientCode)
      backgroundGradientCode.textContent =
        formatGradient(palette.backgroundGradient) ||
        'Linéaire, #FFFFFF, #F2F2F2';
    if (accentPrimaryCode)
      accentPrimaryCode.textContent = palette.accent1 || '#000000';
    if (accent2Code) accent2Code.textContent = palette.accent2 || '#000000';
    if (buttonGradientCode)
      buttonGradientCode.textContent =
        formatGradient(palette.buttonGradient) || 'Linéaire, #000000, #FFFFFF';
  }

  /////////////////////////// Enregistrer les palettes sélectionnées /////////////////////////////////////
  // Fonction pour gérer la sélection d'une palette
  document.addEventListener('DOMContentLoaded', () => {
    const paletteButtons = document.querySelectorAll(
      '.color-create__popup--select'
    );

    paletteButtons.forEach((button) => {
      button.addEventListener('click', function () {
        if (this.classList.contains('color-create__popup--selected')) {
          this.classList.remove('color-create__popup--selected');
          this.textContent = 'Sélectionner cette palette';
        } else {
          this.classList.add('color-create__popup--selected');
          this.textContent = 'Palette sélectionnée';
        }
      });
    });
  });

  document
    .querySelector('.color-create__popup--save')
    .addEventListener('click', function () {
      const selectedPalettes = document.querySelectorAll(
        '.color-create__popup--selected'
      );

      selectedPalettes.forEach((palette, index) => {
        const paletteContainer = palette.closest(
          '.color-create__popup--palette-container'
        );
        const paletteId = paletteContainer ? paletteContainer.id : null;

        console.log(`Vérification pour la palette ID : ${paletteId}`);

        if (!paletteId) {
          console.error(
            `ID introuvable pour la palette sélectionnée ${index + 1}`
          );
          return;
        }

        // Récupérer les éléments de chaque nuancier
        const titlesElement = document.querySelector(`#${paletteId}TitlesCode`);
        const textsElement = document.querySelector(`#${paletteId}TextsCode`);
        const textGradientElement = document.querySelector(
          `#${paletteId}TextGradientCode`
        );
        const backgroundElement = document.querySelector(
          `#${paletteId}BackgroundCode`
        );
        const backgroundSecondaryElement = document.querySelector(
          `#${paletteId}BackgroundSecondaryCode`
        );
        const backgroundGradientElement = document.querySelector(
          `#${paletteId}BackgroundGradientCode`
        );
        const accent1Element = document.querySelector(
          `#${paletteId}Accent1Code`
        );

        const accent2Element = document.querySelector(
          `#${paletteId}Accent2Code`
        );
        const buttonGradientElement = document.querySelector(
          `#${paletteId}ButtonGradientCode`
        );

        // Log chaque élément pour vérifier lequel est manquant
        console.log({
          titlesElement,
          textsElement,
          textGradientElement,
          backgroundElement,
          backgroundSecondaryElement,
          backgroundGradientElement,
          accent1Element,
          accent2Element,
          buttonGradientElement,
        });

        // Vérification des éléments récupérés
        if (
          !titlesElement ||
          !textsElement ||
          !textGradientElement ||
          !backgroundElement ||
          !backgroundSecondaryElement ||
          !backgroundGradientElement ||
          !accent1Element ||
          !accent2Element ||
          !buttonGradientElement
        ) {
          console.error(
            `Un ou plusieurs éléments manquants pour la palette ${paletteId}`
          );
          return; // Ne pas continuer si des éléments sont manquants
        }

        // Construire chaque nuancier avec les propriétés appropriées
        const background1 = {
          titles: titlesElement.textContent,
          texts: textsElement.textContent,
          textGradient: textGradientElement.textContent,
          background: backgroundElement.textContent,
          backgroundSecondary: backgroundSecondaryElement.textContent,
          backgroundGradient: backgroundGradientElement.textContent,
          accent1: accent1Element.textContent,
          accent2: accent2Element.textContent,
          buttonGradient: buttonGradientElement.textContent,
        };

        const background2 = {
          ...background1,
          background: backgroundSecondaryElement.textContent,
          backgroundSecondary: backgroundElement.textContent,
        };

        const inverse = {
          ...background1,
          titles: backgroundElement.textContent,
          texts: backgroundSecondaryElement.textContent,
          background: titlesElement.textContent,
          backgroundSecondary: textsElement.textContent,
        };

        const scheme = {
          ...background1,
          background: accent1Element.textContent,
          backgroundSecondary: accent2Element.textContent,
          accent1: backgroundElement.textContent,
          accent2: backgroundSecondaryElement.textContent,
        };

        // Préparer les données
        const paletteData = {
          background1,
          background2,
          inverse,
          scheme,
        };

        // Envoyer les données au backend
        fetch('/api/v1/palette/savePalette', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paletteData),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              showAlert(
                'success',
                'La palette ou les palettes ont été enregistrée'
              );
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            } else {
              showAlert(
                'error',
                "Erreur lors de l'enregistrement de la palette :"
              );
              console.error(
                "Erreur lors de l'enregistrement de la palette :",
                data.message
              );
            }
          })
          .catch((err) => console.error('Erreur :', err));
      });
    });

  /////////////////////////// Copier en JSON /////////////////////////////////////
  // Fonction pour générer le JSON des palettes
  function generateColorSchemesJSON(nuanciers, withGradients = true) {
    const createScheme = (nuancier) => ({
      settings: {
        text: nuancier.texts || '#000000',
        text_secondary: nuancier.titles || '#000000',
        background_gradient_text: withGradients
          ? convertGradientToCSS(nuancier.textGradient || '')
          : '',
        background: nuancier.background || '#000000',
        background_secondary: nuancier.backgroundSecondary || '#000000',
        background_gradient: withGradients
          ? convertGradientToCSS(nuancier.backgroundGradient || '')
          : '',
        'accent-1': nuancier.accent1 || '#000000',
        'accent-2': nuancier.accent2 || '#000000',
        background_gradient_accent: withGradients
          ? convertGradientToCSS(nuancier.buttonGradient || '')
          : '',
      },
    });

    // Construction des différentes sections des palettes
    const colorSchemes = {
      color_schemes: {
        'background-1': createScheme(nuanciers.background1),
        'background-2': createScheme(nuanciers.background2),
        inverse: createScheme(nuanciers.inverse),
        scheme: createScheme(nuanciers.scheme),
      },
    };

    return JSON.stringify(colorSchemes.color_schemes, null, 2);
  }

  // Fonction pour copier le JSON dans le presse-papier
  function copyColorSchemesToClipboard(nuanciers, withGradients = true) {
    const colorSchemesJSON = generateColorSchemesJSON(nuanciers, withGradients);
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = `"color_schemes": ${colorSchemesJSON}`;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);

    alert(
      'Les codes couleurs des palettes ont été copiés dans le presse-papier !'
    );
  }

  // Événements pour les boutons "Copier"
  document
    .querySelector('.color-create__copy--with-gradient')
    .addEventListener('click', () => {
      const selectedPaletteData = palettesData.find(
        (palette) => palette._id === selectedPaletteId
      ); // Utiliser la palette sélectionnée
      if (selectedPaletteData) {
        copyColorSchemesToClipboard(selectedPaletteData.nuanciers, true);
      } else {
        alert('Aucune palette sélectionnée.');
      }
    });

  document
    .querySelector('.color-create__copy--without-gradient')
    .addEventListener('click', () => {
      const selectedPaletteData = palettesData.find(
        (palette) => palette._id === selectedPaletteId
      ); // Utiliser la palette sélectionnée
      if (selectedPaletteData) {
        copyColorSchemesToClipboard(selectedPaletteData.nuanciers, false);
      } else {
        alert('Aucune palette sélectionnée.');
      }
    });

  /////////////////////////// Ouverture popup palette /////////////////////////////////////

  // Fonction pour convertir RGB en HEX
  function rgbToHex(rgb) {
    if (!rgb || !rgb.includes('rgb')) return rgb; // Si ce n'est pas en format RGB, on retourne la valeur telle qu'elle est (ex: déjà en HEX)
    const result = rgb
      .match(/\d+/g)
      .map((num) => parseInt(num).toString(16).padStart(2, '0'))
      .join('');
    return `#${result}`;
  }

  // Fonction pour reformater le dégradé en un format CSS valide
  function convertGradientToCSS(gradient) {
    if (!gradient.includes('Linéaire') && !gradient.includes('Linear')) {
      return gradient;
    }

    const colors = gradient.match(/#?[0-9a-fA-F]{6}/g);

    if (colors && colors.length >= 2) {
      const color1 = colors[0].startsWith('#') ? colors[0] : `#${colors[0]}`;
      const color2 = colors[1].startsWith('#') ? colors[1] : `#${colors[1]}`;
      const cssGradient = `linear-gradient(to right, ${color1}, ${color2})`;
      return cssGradient;
    }

    return gradient;
  }

  // Fonction pour extraire les données d'un nuancier
  function getNuancierData(nuanciers, key) {
    return nuanciers[key] || {};
  }

  // Fonction pour mettre à jour un nuancier spécifique
  function updatePalette(paletteNumber, paletteData) {
    // Mise à jour des éléments dans la section de la palette
    const sectionElement = document.getElementById(
      `backgroundSection${paletteNumber}`
    );
    const titleElement = document.getElementById(`titleColor${paletteNumber}`);
    const textElement = document.getElementById(`textColor${paletteNumber}`);
    const accent1Element = document.getElementById(
      `accentPrimaryColor${paletteNumber}`
    );
    const accent2Element = document.getElementById(
      `accentSecondaryColor${paletteNumber}`
    );

    // Mise à jour des aperçus de la section de la palette
    if (sectionElement && paletteData.background) {
      sectionElement.style.backgroundColor = paletteData.background;
    }

    if (titleElement && paletteData.titles) {
      titleElement.style.color = paletteData.titles;
    }

    if (textElement && paletteData.texts) {
      textElement.style.color = paletteData.texts;
    }

    if (accent1Element && paletteData.accent1) {
      accent1Element.style.backgroundColor = paletteData.accent1;
    }

    if (accent2Element && paletteData.accent2) {
      accent2Element.style.backgroundColor = paletteData.accent2;
    }

    // Mise à jour des valeurs de la popup (titres, textes, dégradés, arrière-plans)
    document.getElementById(`popupTitlesValue${paletteNumber}`).textContent =
      paletteData.titles || '';
    document.getElementById(`popupTextsValue${paletteNumber}`).textContent =
      paletteData.texts || '';
    document.getElementById(
      `popupTextGradientValue${paletteNumber}`
    ).textContent = paletteData.textGradient || '';
    document.getElementById(
      `popupBackgroundValue${paletteNumber}`
    ).textContent = paletteData.background || '';
    document.getElementById(
      `popupBackgroundSecondaryValue${paletteNumber}`
    ).textContent = paletteData.backgroundSecondary || '';
    document.getElementById(
      `popupBackgroundGradientValue${paletteNumber}`
    ).textContent = paletteData.backgroundGradient || '';
    document.getElementById(
      `popupAccentPrimaryValue${paletteNumber}`
    ).textContent = paletteData.accent1 || '';
    document.getElementById(
      `popupAccentSecondaryValue${paletteNumber}`
    ).textContent = paletteData.accent2 || '';
    document.getElementById(
      `popupButtonGradientValue${paletteNumber}`
    ).textContent = paletteData.buttonGradient || '';

    // Mise à jour des aperçus visuels dans la popup
    document.getElementById(
      `popupTitlesPreview${paletteNumber}`
    ).style.backgroundColor = paletteData.titles || '';
    document.getElementById(
      `popupTextsPreview${paletteNumber}`
    ).style.backgroundColor = paletteData.texts || '';
    document.getElementById(
      `popupTextGradientPreview${paletteNumber}`
    ).style.background = convertGradientToCSS(paletteData.textGradient || '');
    document.getElementById(
      `popupBackgroundPreview${paletteNumber}`
    ).style.backgroundColor = paletteData.background || '';
    document.getElementById(
      `popupBackgroundSecondaryPreview${paletteNumber}`
    ).style.backgroundColor = paletteData.backgroundSecondary || '';
    document.getElementById(
      `popupBackgroundGradientPreview${paletteNumber}`
    ).style.background = convertGradientToCSS(
      paletteData.backgroundGradient || ''
    );
    document.getElementById(
      `popupAccentPrimaryPreview${paletteNumber}`
    ).style.backgroundColor = paletteData.accent1 || '';
    document.getElementById(
      `popupAccentSecondaryPreview${paletteNumber}`
    ).style.backgroundColor = paletteData.accent2 || '';
    document.getElementById(
      `popupButtonGradientPreview${paletteNumber}`
    ).style.background = convertGradientToCSS(paletteData.buttonGradient || '');
  }

  let selectedPaletteId = null; // Déclarer une variable globale

  // Fonction pour afficher la popup avec les informations de la palette sélectionnée
  function showPalettePopup(paletteData) {
    const nuanciers = paletteData.nuanciers;
    const popupElements = document.querySelectorAll(
      '.color-create__popup--palette-container'
    );

    // Mise à jour dynamique du data-palette-id dans la popup
    popupElements.forEach((element) => {
      element.setAttribute('data-palette-id', paletteData._id); // Remplace l'ID de la palette dans chaque container de la popup
    });

    selectedPaletteId = paletteData._id; // Stocker l'ID de la palette sélectionnée

    // Mise à jour des éléments de la popup pour chaque nuancier (background1, background2, inverse, scheme)
    if (nuanciers) {
      updatePalette(1, getNuancierData(nuanciers, 'background1'));
      updatePalette(2, getNuancierData(nuanciers, 'background2'));
      updatePalette(3, getNuancierData(nuanciers, 'inverse'));
      updatePalette(4, getNuancierData(nuanciers, 'scheme'));
    }

    // Afficher la popup
    document.getElementById('popupOverlayPalette').classList.remove('hidden');
    document.getElementById('palettePopupContent').classList.remove('hidden');
    document.body.classList.add('no-scroll');
  }

  // Gestion des événements pour les palettes
  const paletteSection = document.querySelectorAll(
    '.color-create__palette--section'
  );
  paletteSection.forEach((paletteElement) => {
    paletteElement.addEventListener('click', function () {
      const paletteId = paletteElement.getAttribute('data-palette-id'); // Récupérer l'ID de la palette
      const paletteData = palettesData.find(
        (palette) => palette._id === paletteId
      ); // Trouver les données de la palette correspondante

      if (paletteData) {
        showPalettePopup(paletteData); // Afficher la popup avec les infos de la palette
      } else {
        console.error('Aucune palette trouvée pour cet ID:', paletteId);
      }
    });
  });

  // Fonction pour masquer la popup
  function hidePalettePopup() {
    document.getElementById('popupOverlayPalette').classList.add('hidden');
    document.getElementById('palettePopupContent').classList.add('hidden');
    document.body.classList.remove('no-scroll');
  }

  // Fermer la popup
  document
    .querySelector('.popup-close')
    .addEventListener('click', hidePalettePopup);
  document
    .getElementById('popupOverlayPalette')
    .addEventListener('click', function (e) {
      if (e.target === document.getElementById('popupOverlayPalette')) {
        hidePalettePopup();
      }
    });

  // Fermeture via la touche "Escape"
  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
      hidePalettePopup();
    }
  });

  /////////////////////////// Modifier couleur /////////////////////////////////////
  let activeInput = null; // Variable pour suivre l'input actif
  const showInputAndButtons = (element, paletteNumber, field) => {
    // Correction des noms pour certains champs
    if (field === 'BackgroundSecondary') {
      field = 'backgroundSecondary';
    } else if (field === 'AccentPrimary') {
      field = 'accentPrimary';
    } else if (field === 'AccentSecondary') {
      field = 'accentSecondary';
    } else if (field === 'TextGradient') {
      field = 'textGradient';
    } else if (field === 'BackgroundGradient') {
      field = 'backgroundGradient';
    } else if (field === 'ButtonGradient') {
      field = 'buttonGradient';
    }

    const fieldsWithoutLowerCase = [
      'textGradient',
      'backgroundSecondary',
      'backgroundGradient',
      'accentPrimary',
      'accentSecondary',
      'buttonGradient',
    ];

    const formattedField = fieldsWithoutLowerCase.includes(field)
      ? field
      : field.toLowerCase();

    console.log('Formatted field:', formattedField); // Vérification du champ formaté
    let inputElement1, inputElement2;
    let previewElement, saveButton, cancelButton;

    const closeActiveInput = () => {
      if (activeInput) {
        const {
          input1,
          input2,
          saveBtn,
          cancelBtn,
          element,
          previewEl,
          formattedField,
          paletteNumber,
          nuancier,
          paletteId,
        } = activeInput;

        if (input1 && saveBtn && cancelBtn && element) {
          const newColor1 = input1.value.trim();
          const newColor2 = input2 ? input2.value.trim() : null;

          if (!isValidHex(newColor1) || (newColor2 && !isValidHex(newColor2))) {
            alert(
              'Erreur : les codes couleur doivent être valides. Format attendu : # suivi de 6 caractères hexadécimaux.'
            );
            return;
          }

          const formattedGradient = newColor2
            ? `Linéaire, ${newColor1}, ${newColor2}`
            : newColor1;

          element.textContent = formattedGradient;
          if (previewEl) {
            if (newColor2) {
              previewEl.style.background = `linear-gradient(to right, ${newColor1}, ${newColor2})`;
            } else {
              previewEl.style.backgroundColor = newColor1;
            }
          }

          saveColorToDB(formattedField, formattedGradient, paletteId, nuancier);
          updatePaletteColorsInSection(
            paletteNumber,
            formattedField,
            newColor1,
            newColor2
          );

          input1.classList.add('hidden');
          if (input2) input2.classList.add('hidden');
          saveBtn.classList.add('hidden');
          cancelBtn.classList.add('hidden');
          element.classList.remove('hidden');

          activeInput = null;
        }
      }
    };

    closeActiveInput();

    if (field.includes('Gradient')) {
      inputElement1 = document.getElementById(
        `${formattedField}Color1Input${paletteNumber}`
      );
      inputElement2 = document.getElementById(
        `${formattedField}Color2Input${paletteNumber}`
      );
      saveButton = document.getElementById(
        `save${
          formattedField.charAt(0).toUpperCase() + formattedField.slice(1)
        }${paletteNumber}`
      );
      cancelButton = document.getElementById(
        `cancel${
          formattedField.charAt(0).toUpperCase() + formattedField.slice(1)
        }${paletteNumber}`
      );
      previewElement = document.getElementById(
        `popup${
          formattedField.charAt(0).toUpperCase() + formattedField.slice(1)
        }Preview${paletteNumber}`
      );

      if (
        inputElement1 &&
        inputElement2 &&
        saveButton &&
        cancelButton &&
        previewElement
      ) {
        const currentGradient = element.textContent.trim();
        console.log('Current gradient text content:', currentGradient); // Vérification du contenu du gradient actuel
        const colors = currentGradient.match(/[0-9a-fA-F]{6}/g); // Extraction des couleurs sans #
        console.log('Extracted colors without #:', colors); // Pour vérifier les couleurs extraites

        // Ajoutez le préfixe # aux couleurs extraites
        inputElement1.value = colors ? `#${colors[0]}` : '#000000';
        inputElement2.value = colors && colors[1] ? `#${colors[1]}` : '#000000';

        console.log(
          'Color values for inputs:',
          inputElement1.value,
          inputElement2.value
        ); // Pour vérifier les valeurs assignées aux inputs

        element.classList.add('hidden');
        inputElement1.classList.remove('hidden');
        inputElement2.classList.remove('hidden');
        saveButton.classList.remove('hidden');
        cancelButton.classList.remove('hidden');

        const nuancier = document
          .getElementById(`palette${paletteNumber}`)
          .getAttribute('data-nuancier');
        const paletteId = document
          .getElementById(`palette${paletteNumber}`)
          .getAttribute('data-palette-id');

        console.log('Palette ID and Nuancier:', { paletteId, nuancier }); // Vérification des attributs palette et nuancier

        activeInput = {
          input1: inputElement1,
          input2: inputElement2,
          saveBtn: saveButton,
          cancelBtn: cancelButton,
          element: element,
          previewEl: previewElement,
          formattedField: formattedField,
          paletteNumber: paletteNumber,
          nuancier: nuancier,
          paletteId: paletteId,
        };

        saveButton.addEventListener('click', () => {
          const newColor1 = inputElement1.value.trim();
          const newColor2 = inputElement2.value.trim();

          if (!isValidHex(newColor1) || !isValidHex(newColor2)) {
            alert(
              'Erreur : les codes couleur doivent être valides. Format attendu : # suivi de 6 caractères hexadécimaux.'
            );
            return;
          }

          const formattedGradient = `Linéaire, ${newColor1}, ${newColor2}`;

          element.textContent = formattedGradient;
          previewElement.style.background = `linear-gradient(to right, ${newColor1}, ${newColor2})`;

          saveColorToDB(formattedField, formattedGradient, paletteId, nuancier);
          updatePaletteColorsInSection(
            paletteNumber,
            formattedField,
            newColor1,
            newColor2
          );

          inputElement1.classList.add('hidden');
          inputElement2.classList.add('hidden');
          saveButton.classList.add('hidden');
          cancelButton.classList.add('hidden');
          element.classList.remove('hidden');

          activeInput = null;
        });

        cancelButton.addEventListener('click', () => {
          inputElement1.classList.add('hidden');
          inputElement2.classList.add('hidden');
          saveButton.classList.add('hidden');
          cancelButton.classList.add('hidden');
          element.classList.remove('hidden');
          activeInput = null;
        });
      }
    } else {
      inputElement1 = document.getElementById(
        `${formattedField}Input${paletteNumber}`
      );
      saveButton = document.getElementById(
        `save${
          formattedField.charAt(0).toUpperCase() + formattedField.slice(1)
        }${paletteNumber}`
      );
      cancelButton = document.getElementById(
        `cancel${
          formattedField.charAt(0).toUpperCase() + formattedField.slice(1)
        }${paletteNumber}`
      );
      previewElement = document.getElementById(
        `popup${
          formattedField.charAt(0).toUpperCase() + formattedField.slice(1)
        }Preview${paletteNumber}`
      );

      if (inputElement1 && saveButton && cancelButton && previewElement) {
        const currentColor = element.textContent.trim();
        inputElement1.value = currentColor;

        element.classList.add('hidden');
        inputElement1.classList.remove('hidden');
        saveButton.classList.remove('hidden');
        cancelButton.classList.remove('hidden');

        const nuancier = document
          .getElementById(`palette${paletteNumber}`)
          .getAttribute('data-nuancier');
        const paletteId = document
          .getElementById(`palette${paletteNumber}`)
          .getAttribute('data-palette-id');

        activeInput = {
          input1: inputElement1,
          saveBtn: saveButton,
          cancelBtn: cancelButton,
          element: element,
          previewEl: previewElement,
          formattedField: formattedField,
          paletteNumber: paletteNumber,
          nuancier: nuancier,
          paletteId: paletteId,
        };

        saveButton.addEventListener('click', () => {
          const newColor = inputElement1.value.trim();

          if (!isValidHex(newColor)) {
            alert(
              'Erreur : les codes couleur doivent être valides. Format attendu : # suivi de 6 caractères hexadécimaux.'
            );
            return;
          }

          element.textContent = newColor;
          previewElement.style.backgroundColor = newColor;

          saveColorToDB(formattedField, newColor, paletteId, nuancier);
          updatePaletteColorsInSection(paletteNumber, formattedField, newColor);

          inputElement1.classList.add('hidden');
          saveButton.classList.add('hidden');
          cancelButton.classList.add('hidden');
          element.classList.remove('hidden');

          activeInput = null;
        });

        cancelButton.addEventListener('click', () => {
          inputElement1.classList.add('hidden');
          saveButton.classList.add('hidden');
          cancelButton.classList.add('hidden');
          element.classList.remove('hidden');
          activeInput = null;
        });
      }
    }
  };

  // Fonction de validation du format HEX
  const isValidHex = (color) => {
    const hexRegex = /^#[0-9A-Fa-f]{6}$/;
    return hexRegex.test(color);
  };

  // Fonction pour mettre à jour les couleurs de la section principale de la palette
  // Fonction pour mettre à jour les couleurs directement sur la page
  const updatePaletteColorsInSection = (
    paletteNumber,
    field,
    color1,
    color2 = null
  ) => {
    const sectionElement = document.getElementById(
      `backgroundSection${paletteNumber}`
    );
    const titleElement = document.getElementById(`titleColor${paletteNumber}`);
    const textElement = document.getElementById(`textColor${paletteNumber}`);
    const accentPrimaryElement = document.getElementById(
      `accentPrimaryColor${paletteNumber}`
    );
    const accentSecondaryElement = document.getElementById(
      `accentSecondaryColor${paletteNumber}`
    );

    // Si le champ est backgroundGradient, on ne met pas à jour la couleur de sectionElement
    if (field === 'backgroundGradient') {
      return; // On ne fait rien pour le backgroundGradient
    }

    if (sectionElement && field === 'background') {
      sectionElement.style.backgroundColor = color1;
    }

    if (titleElement && field === 'titles') {
      titleElement.style.color = color1;
    }

    if (textElement && field === 'texts') {
      textElement.style.color = color1;
    }

    if (accentPrimaryElement && field === 'accentPrimary') {
      accentPrimaryElement.style.backgroundColor = color1;
    }

    if (accentSecondaryElement && field === 'accentSecondary') {
      accentSecondaryElement.style.backgroundColor = color1;
    }
  };

  // Fonction pour sauvegarder la nouvelle couleur dans la base de données
  const saveColorToDB = (colorField, newColor, paletteId, nuancier) => {
    // Correspondance des noms de champs pour la base de données
    const fieldMapping = {
      accentPrimary: 'accent1',
      accentSecondary: 'accent2', // Ajout ici
      backgroundSecondary: 'backgroundSecondary',
      backgroundGradient: 'backgroundGradient',
      textGradient: 'textGradient',
      buttonGradient: 'buttonGradient',
    };

    // Vérifie si le champ a besoin d'être converti
    const dbField = fieldMapping[colorField] || colorField;

    const dataToSend = {
      nuancier: nuancier,
      field: dbField, // Utilisation du nom de champ correct pour la DB
      newColor: newColor,
    };

    fetch(`/api/v1/palette/${paletteId}/updateColor`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          console.log(
            `${dbField} mis à jour avec succès pour la palette ${paletteId}`
          );
        } else {
          console.error(
            'Erreur lors de la sauvegarde de la couleur:',
            data.message
          );
        }
      })
      .catch((err) => {
        console.error('Erreur lors de la requête:', err);
      });
  };

  // Activer l'édition pour chaque palette
  document.addEventListener('DOMContentLoaded', () => {
    // Sélectionner et gérer les clics sur les éléments de code et de prévisualisation des couleurs
    document
      .querySelectorAll(
        '.color-create__popup--color-code, .color-create__popup--color-preview'
      )
      .forEach((element) => {
        element.addEventListener('click', () => {
          const paletteNumber = element.id.match(/\d+/)[0]; // Numéro de la palette
          const field = element.id
            .replace(/\d+/g, '')
            .replace('popup', '')
            .replace('Value', '')
            .replace('Preview', '');

          console.log(
            `Clicked element ID: ${element.id}, Palette number: ${paletteNumber}, Field: ${field}`
          );

          showInputAndButtons(element, paletteNumber, field);
        });
      });
  });

  document.addEventListener('DOMContentLoaded', () => {
    const deleteButtons = document.querySelectorAll(
      '.color-create__delete-btn'
    );

    deleteButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        const paletteId = button.closest('.color-create__palette').dataset
          .paletteId;
        deletePalette(paletteId); // Appelle la fonction deletePalette avec l'ID
      });
    });
  });

  function deletePalette(paletteId) {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette palette ?')) {
      fetch(`/api/v1/palette/${paletteId}`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (response.ok) {
            showAlert('success', 'Palette supprimée avec succès');
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            showAlert('error', 'Erreur lors de la suppression de la palette');
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la suppression de la palette:', error);
        });
    }
  }
}
