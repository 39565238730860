import { showAlert } from './alerts.js';

import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
// import 'quill-image-uploader/dist/quill.imageUploader.min.css';

import { showAlert } from './alerts.js';

import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
// import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import Sortable from 'sortablejs';

const docsAdmin = document.querySelector('.docsAdmin');

if (docsAdmin) {
  function convertFormDataToJson(formData) {
    const object = {};
    formData.forEach((value, key) => {
      const keys = key.split(/[\[\]]+/).filter(Boolean);
      if (keys.length > 1) {
        if (!object[keys[0]]) object[keys[0]] = {};
        object[keys[0]][keys[1]] = value;
      } else {
        object[key] = value;
      }
    });
    return object;
  }

  document.addEventListener('DOMContentLoaded', () => {
    // Fonctions d'ouverture et de fermeture de modal
    const openModal = (modal) => {
      if (!modal) return;
      modal.classList.add('active');
    };

    const closeModal = (modal) => {
      if (!modal) return;
      modal.classList.remove('active');
    };

    // Gestion de l'ouverture des modales via les boutons déclencheurs
    const modalTriggers = document.querySelectorAll('[data-modal-target]');
    modalTriggers.forEach((trigger) => {
      trigger.addEventListener('click', function () {
        const modalSelector = this.getAttribute('data-modal-target');
        const modal = document.querySelector(modalSelector);
        openModal(modal);

        if (modalSelector === '#modalUpdateCategory') {
          const categorySelect = modal.querySelector('#updateCategorySelect');
          if (categorySelect) {
            categorySelect.value = this.dataset.categoryId || '';
          }
          modal.querySelector('#updateCategoryNameFR').value = this.dataset.categoryNameFr || '';
          modal.querySelector('#updateCategoryNameEN').value = this.dataset.categoryNameEn || '';
          modal.querySelector('#updateCategoryDescriptionFR').value = this.dataset.categoryDescriptionFr || '';
          modal.querySelector('#updateCategoryDescriptionEN').value = this.dataset.categoryDescriptionEn || '';
          modal.querySelector('#updateCategorySlug').value = this.dataset.categorySlug || '';
          modal.querySelector('#updateCategoryIcon').value = this.dataset.categoryIcon || '';
          modal.querySelector('form').action = `/api/v1/docs/categories/update/${this.dataset.categoryId}`;
        } else if (modalSelector === '#modalUpdateSubCategory') {
          const subCatId = this.getAttribute('data-subcategory-id') || '';
          // Pour le select qui liste les sous-catégories
          const selectSub = modal.querySelector('#updateSubCategorySelect');
          if (selectSub) {
            selectSub.value = subCatId;
            // Forcer le déclenchement de l'événement change
            selectSub.dispatchEvent(new Event('change'));
          }
          // Pré-remplissage des champs de texte se fera via le gestionnaire 'change' du select
          // Pré-remplissage du select de catégorie associé
          const catId = this.getAttribute('data-category-id') || '';
          const selectCat = modal.querySelector('#updateSubCategoryCategory');
          if (selectCat) {
            selectCat.value = catId;
          }
          modal.querySelector('form').action = `/api/v1/docs/subcategories/update/${subCatId}`;
        } else if (modalSelector === '#modalUpdateArticle') {
          modal.querySelector('#updateArticleTitleFR').value = this.dataset.articleTitleFr || '';
          modal.querySelector('#updateArticleTitleEN').value = this.dataset.articleTitleEn || '';
          modal.querySelector('#updateArticleDescriptionFR').value = this.dataset.articleDescriptionFr || '';
          modal.querySelector('#updateArticleDescriptionEN').value = this.dataset.articleDescriptionEn || '';
          modal.querySelector('#updateArticleSlug').value = this.dataset.articleSlug || '';
          modal.querySelector('#updateArticleContentFR').value = this.dataset.articleContentFr || '';
          modal.querySelector('#updateArticleContentEN').value = this.dataset.articleContentEn || '';
          modal.querySelector('#updateArticleStatus').value = this.dataset.articleStatus || 'draft';
          modal.querySelector('form').action = `/api/v1/docs/articles/update/${this.dataset.articleId}`;
          modal.querySelector('#updateArticleSubCategory').value = this.dataset.subcategoryId || '';
        } else if (modalSelector === '#modalQuickUpdateArticle') {
          // Pour la modale de modification rapide d'article (sans content)
          if (this.dataset.articleId) {
            const quickSelect = modal.querySelector('#quickUpdateArticleSelect');
            if (quickSelect) {
              quickSelect.value = this.dataset.articleId;
              quickSelect.dispatchEvent(new Event('change'));
            }
            modal.querySelector('#quickUpdateArticleTitleFR').value = this.dataset.articleTitleFr || '';
            modal.querySelector('#quickUpdateArticleTitleEN').value = this.dataset.articleTitleEn || '';
            modal.querySelector('#quickUpdateArticleDescriptionFR').value = this.dataset.articleDescriptionFr || '';
            modal.querySelector('#quickUpdateArticleDescriptionEN').value = this.dataset.articleDescriptionEn || '';
            modal.querySelector('#quickUpdateArticleSlug').value = this.dataset.articleSlug || '';
            modal.querySelector('#quickUpdateArticleStatus').value = this.dataset.articleStatus || 'draft';
            modal.querySelector('form').action = `/api/v1/docs/articles/update/${this.dataset.articleId}`;
            modal.querySelector('#quickUpdateArticleSubCategory').value = this.dataset.subcategoryId || '';
          } else {
            modal.querySelector('#quickUpdateArticleTitleFR').value = '';
            modal.querySelector('#quickUpdateArticleTitleEN').value = '';
            modal.querySelector('#quickUpdateArticleDescriptionFR').value = '';
            modal.querySelector('#quickUpdateArticleDescriptionEN').value = '';
            modal.querySelector('#quickUpdateArticleSlug').value = '';
            modal.querySelector('#quickUpdateArticleStatus').value = 'draft';
            modal.querySelector('form').action = '/api/v1/docs/articles/update/';
            modal.querySelector('#quickUpdateArticleSubCategory').value = '';
          }
        }
      });
    });

    // Fermeture des modales via les boutons "Fermer" ou en cliquant sur l'overlay
    const closeButtons = document.querySelectorAll('[data-modal-target-close], .modal-close');
    closeButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const modal = button.closest('.modal');
        closeModal(modal);
      });
    });
    document.querySelectorAll('.modal').forEach((modal) => {
      modal.addEventListener('click', (e) => {
        if (e.target === modal) closeModal(modal);
      });
    });

    // Ouvrir la modale de réattribution d'une sous-catégorie
    const reassignSubCategoryButtons = document.querySelectorAll('[data-modal-target="#modalReassignSubCategory"]');
    reassignSubCategoryButtons.forEach((btn) => {
      btn.addEventListener('click', function (e) {
        e.preventDefault();
        const modal = document.querySelector('#modalReassignSubCategory');
        if (modal) {
          modal.classList.remove('hidden');
          // Définir l'ID de la sous-catégorie dans le champ caché
          const subCatId = this.getAttribute('data-subcategory-id') || '';
          modal.querySelector('#reassignSubCategoryId').value = subCatId;
        }
      });
    });

    // Ouvrir la modale de réattribution d'un article
    const reassignArticleButtons = document.querySelectorAll('[data-modal-target="#modalReassignArticle"]');
    reassignArticleButtons.forEach((btn) => {
      btn.addEventListener('click', function (e) {
        e.preventDefault();
        const modal = document.querySelector('#modalReassignArticle');
        if (modal) {
          modal.classList.remove('hidden');
          // Définir l'ID de l'article dans le champ caché
          const articleId = this.getAttribute('data-article-id') || '';
          modal.querySelector('#reassignArticleId').value = articleId;
        }
      });
    });

    // Gestion de la fermeture des modales
    const modalCloseButtons = document.querySelectorAll('[data-modal-target-close], .modal-close');
    modalCloseButtons.forEach((btn) => {
      btn.addEventListener('click', function () {
        const modal = this.closest('.modal');
        if (modal) {
          modal.classList.add('hidden');
        }
      });
    });

    // Gestion de la soumission du formulaire de réattribution d'une sous-catégorie
    const reassignSubCategoryForm = document.getElementById('reassignSubCategoryForm');
    if (reassignSubCategoryForm) {
      reassignSubCategoryForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(reassignSubCategoryForm);
        const requestData = Object.fromEntries(formData.entries());
        try {
          const response = await fetch('/api/v1/docs/subcategories/reassign', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Sous-catégorie réattribuée avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Gestion de la soumission du formulaire de réattribution d'un article
    const reassignArticleForm = document.getElementById('reassignArticleForm');
    if (reassignArticleForm) {
      reassignArticleForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(reassignArticleForm);
        const requestData = Object.fromEntries(formData.entries());
        try {
          const response = await fetch('/api/v1/docs/articles/reassign', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Article réattribué avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Gestion des formulaires d'ajout
    const newCategoryForm = document.getElementById('newCategoryForm');
    if (newCategoryForm) {
      newCategoryForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(newCategoryForm);
        const requestData = convertFormDataToJson(formData);
        try {
          const response = await fetch('/api/v1/docs/categories', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Catégorie ajoutée avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    const newSubCategoryForm = document.getElementById('newSubCategoryForm');
    if (newSubCategoryForm) {
      newSubCategoryForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(newSubCategoryForm);
        const requestData = convertFormDataToJson(formData);
        try {
          const response = await fetch('/api/v1/docs/subcategories', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Sous-catégorie ajoutée avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    const newArticleForm = document.getElementById('newArticleForm');
    if (newArticleForm) {
      newArticleForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(newArticleForm);
        const requestData = convertFormDataToJson(formData);
        try {
          const response = await fetch('/api/v1/docs/articles', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Article ajouté avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Gestion des formulaires de modification

    // Pour la modal de modification de catégorie bilingue
    const updateCategorySelect = document.getElementById('updateCategorySelect');
    if (updateCategorySelect) {
      updateCategorySelect.addEventListener('change', function () {
        const selectedOption = this.options[this.selectedIndex];
        document.getElementById('updateCategoryNameFR').value = selectedOption.dataset.nameFr || '';
        document.getElementById('updateCategoryNameEN').value = selectedOption.dataset.nameEn || '';
        document.getElementById('updateCategoryDescriptionFR').value = selectedOption.dataset.descriptionFr || '';
        document.getElementById('updateCategoryDescriptionEN').value = selectedOption.dataset.descriptionEn || '';
        document.getElementById('updateCategorySlug').value = selectedOption.dataset.slug || '';
        document.getElementById('updateCategoryIcon').value = selectedOption.dataset.icon || '';
      });
    }
    const updateCategoryForm = document.getElementById('updateCategoryForm');
    if (updateCategoryForm) {
      updateCategoryForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(updateCategoryForm);
        const requestData = convertFormDataToJson(formData);
        try {
          const response = await fetch('/api/v1/docs/categories/update', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Catégorie mise à jour avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Pour la modal de modification de sous-catégorie bilingue
    const updateSubCategorySelect = document.getElementById('updateSubCategorySelect');
    if (updateSubCategorySelect) {
      updateSubCategorySelect.addEventListener('change', function () {
        const selectedOption = this.options[this.selectedIndex];
        document.getElementById('updateSubCategoryNameFR').value = selectedOption.dataset.nameFr || '';
        document.getElementById('updateSubCategoryNameEN').value = selectedOption.dataset.nameEn || '';
        document.getElementById('updateSubCategoryDescriptionFR').value = selectedOption.dataset.descriptionFr || '';
        document.getElementById('updateSubCategoryDescriptionEN').value = selectedOption.dataset.descriptionEn || '';
        document.getElementById('updateSubCategorySlug').value = selectedOption.dataset.slug || '';
      });
    }
    const updateSubCategoryForm = document.getElementById('updateSubCategoryForm');
    if (updateSubCategoryForm) {
      updateSubCategoryForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(updateSubCategoryForm);
        const requestData = convertFormDataToJson(formData);
        try {
          const response = await fetch('/api/v1/docs/subcategories/update', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Sous-catégorie mise à jour avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Pour la modal de modification d'article classique bilingue
    const updateArticleSelect = document.getElementById('updateArticleSelect');
    if (updateArticleSelect) {
      updateArticleSelect.addEventListener('change', function () {
        const selectedOption = this.options[this.selectedIndex];
        document.getElementById('updateArticleTitleFR').value = selectedOption.dataset.titleFr || '';
        document.getElementById('updateArticleTitleEN').value = selectedOption.dataset.titleEn || '';
        document.getElementById('updateArticleDescriptionFR').value = selectedOption.dataset.descriptionFr || '';
        document.getElementById('updateArticleDescriptionEN').value = selectedOption.dataset.descriptionEn || '';
        document.getElementById('updateArticleSlug').value = selectedOption.dataset.slug || '';
        document.getElementById('updateArticleContentFR').value = selectedOption.dataset.contentFr || '';
        document.getElementById('updateArticleContentEN').value = selectedOption.dataset.contentEn || '';
        document.getElementById('updateArticleStatus').value = selectedOption.dataset.status || 'draft';
        document.getElementById('updateArticleSubCategory').value = selectedOption.dataset.subcategoryId || '';
      });
    }
    const updateArticleForm = document.getElementById('updateArticleForm');
    if (updateArticleForm) {
      updateArticleForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(updateArticleForm);
        const requestData = convertFormDataToJson(formData);
        requestData.articleId = updateArticleSelect.value;
        requestData.subCategory = document.getElementById('updateArticleSubCategory').value;
        try {
          const response = await fetch('/api/v1/docs/articles/update', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Article mis à jour avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Pour la modal de modification rapide d'article bilingue (sans content)
    const quickUpdateArticleSelect = document.getElementById('quickUpdateArticleSelect');
    if (quickUpdateArticleSelect) {
      quickUpdateArticleSelect.addEventListener('change', function () {
        const selectedOption = this.options[this.selectedIndex];
        document.getElementById('quickUpdateArticleTitleFR').value = selectedOption.dataset.titleFr || '';
        document.getElementById('quickUpdateArticleTitleEN').value = selectedOption.dataset.titleEn || '';
        document.getElementById('quickUpdateArticleDescriptionFR').value = selectedOption.dataset.descriptionFr || '';
        document.getElementById('quickUpdateArticleDescriptionEN').value = selectedOption.dataset.descriptionEn || '';
        document.getElementById('quickUpdateArticleSlug').value = selectedOption.dataset.slug || '';
        document.getElementById('quickUpdateArticleStatus').value = selectedOption.dataset.status || 'draft';
        document.getElementById('quickUpdateArticleSubCategory').value = selectedOption.dataset.subcategoryId || '';
      });
    }
    const quickUpdateArticleForm = document.getElementById('quickUpdateArticleForm');
    if (quickUpdateArticleForm) {
      quickUpdateArticleForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        const formData = new FormData(quickUpdateArticleForm);
        const requestData = convertFormDataToJson(formData);
        requestData.articleId = quickUpdateArticleSelect.value;
        requestData.subCategory = document.getElementById('quickUpdateArticleSubCategory').value;
        try {
          const response = await fetch('/api/v1/docs/articles/update', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Article mis à jour avec succès !');
          } else {
            showAlert('error', result.message || 'Une erreur est survenue.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    }

    // Gestion des suppressions
    document.querySelectorAll('.btn-delete').forEach((button) => {
      button.addEventListener('click', async () => {
        const type = button.dataset.categoryId
          ? 'category'
          : button.dataset.subcategoryId
          ? 'subcategory'
          : button.dataset.articleId
          ? 'article'
          : null;
        if (!type) return;
        let id, url;
        if (type === 'category') {
          id = button.dataset.categoryId;
          url = `/api/v1/docs/categories/${id}`;
        } else if (type === 'subcategory') {
          id = button.dataset.subcategoryId;
          url = `/api/v1/docs/subcategories/${id}`;
        } else if (type === 'article') {
          id = button.dataset.articleId;
          url = `/api/v1/docs/articles/${id}`;
        }
        if (!confirm('Confirmez-vous la suppression ?')) return;
        try {
          const res = await fetch(url, { method: 'DELETE' });
          const result = await res.json();
          if (result.status === 'success') {
            showAlert('success', `${type} supprimé(e) avec succès !`);
          } else {
            showAlert('error', result.message || 'Erreur lors de la suppression.');
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur.');
        }
      });
    });
  });

  // Tri des catégories
  const categoriesList = document.getElementById('categoriesList');
  if (categoriesList) {
    new Sortable(categoriesList, {
      animation: 150,
      onEnd: async function (evt) {
        const items = categoriesList.querySelectorAll('[data-id]');
        const newOrder = Array.from(items).map((item, index) => ({
          id: item.getAttribute('data-id'),
          order: index,
        }));
        try {
          const response = await fetch('/api/v1/docs/categories/updateOrder', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ newOrder }),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Ordre des catégories mis à jour avec succès !');
          } else {
            showAlert('error', result.message || "Erreur lors de la mise à jour de l'ordre des catégories.");
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur pour les catégories.');
          console.error(error);
        }
      },
    });
  }

  // Tri des sous-catégories (pour chaque conteneur de sous-catégories)
  const subCategoriesLists = document.querySelectorAll('.subCategoriesList');
  subCategoriesLists.forEach((list) => {
    new Sortable(list, {
      animation: 150,
      onEnd: async function (evt) {
        const items = list.querySelectorAll('[data-id]');
        const newOrder = Array.from(items).map((item, index) => ({
          id: item.getAttribute('data-id'),
          order: index,
        }));
        try {
          const response = await fetch('/api/v1/docs/subcategories/updateOrder', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ newOrder }),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Ordre des sous-catégories mis à jour avec succès !');
          } else {
            showAlert('error', result.message || "Erreur lors de la mise à jour de l'ordre des sous-catégories.");
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur pour les sous-catégories.');
          console.error(error);
        }
      },
    });
  });

  // Tri des articles (pour chaque liste d'articles)
  const articlesLists = document.querySelectorAll('.articlesList');
  articlesLists.forEach((list) => {
    new Sortable(list, {
      animation: 150,
      onEnd: async function (evt) {
        const items = list.querySelectorAll('[data-id]');
        const newOrder = Array.from(items).map((item, index) => ({
          id: item.getAttribute('data-id'),
          order: index,
        }));
        try {
          const response = await fetch('/api/v1/docs/articles/updateOrder', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ newOrder }),
          });
          const result = await response.json();
          if (result.status === 'success') {
            showAlert('success', 'Ordre des articles mis à jour avec succès !');
          } else {
            showAlert('error', result.message || "Erreur lors de la mise à jour de l'ordre des articles.");
          }
        } catch (error) {
          showAlert('error', 'Erreur de connexion au serveur pour les articles.');
          console.error(error);
        }
      },
    });
  });

  // Boutons globaux pour ouvrir/fermer tous les détails
  const openAllBtn = document.getElementById('openAll');
  const closeAllBtn = document.getElementById('closeAll');
  const allDetails = document.querySelectorAll('#categoriesList details');

  if (openAllBtn) {
    openAllBtn.addEventListener('click', () => {
      allDetails.forEach((detail) => detail.setAttribute('open', ''));
    });
  }

  if (closeAllBtn) {
    closeAllBtn.addEventListener('click', () => {
      allDetails.forEach((detail) => detail.removeAttribute('open'));
    });
  }

  // Boutons pour déplier/replier les sous-catégories pour une catégorie donnée
  const openSubBtns = document.querySelectorAll('.open-all-sub');
  const closeSubBtns = document.querySelectorAll('.close-all-sub');

  openSubBtns.forEach((btn) => {
    btn.addEventListener('click', function () {
      const catId = this.getAttribute('data-category-id');
      const parentDetail = document.querySelector(`details.docsAdmin__menu--category[data-id="${catId}"]`);
      if (parentDetail) {
        // Ouvrir la catégorie
        parentDetail.setAttribute('open', '');
        // Ouvrir toutes les sous-catégories de cette catégorie
        const subDetails = parentDetail.querySelectorAll('details.docsAdmin__menu--subcategory');
        subDetails.forEach((sd) => sd.setAttribute('open', ''));
      }
    });
  });

  closeSubBtns.forEach((btn) => {
    btn.addEventListener('click', function () {
      const catId = this.getAttribute('data-category-id');
      const parentDetail = document.querySelector(`details.docsAdmin__menu--category[data-id="${catId}"]`);
      if (parentDetail) {
        // Replier toutes les sous-catégories de cette catégorie...
        const subDetails = parentDetail.querySelectorAll('details.docsAdmin__menu--subcategory');
        subDetails.forEach((sd) => sd.removeAttribute('open'));
        // ...et replier également la catégorie elle-même
        parentDetail.removeAttribute('open');
      }
    });
  });
}

const docsEditArticle = document.querySelector('.docsEditArticle');

if (docsEditArticle) {
  document.addEventListener('DOMContentLoaded', function () {
    // Récupérer les données anglaises depuis l'élément caché
    const articleDataEl = document.getElementById('articleData');
    const englishData = {
      title: articleDataEl ? articleDataEl.getAttribute('data-title-en') : '',
      description: articleDataEl ? articleDataEl.getAttribute('data-description-en') : '',
      content: articleDataEl ? articleDataEl.getAttribute('data-content-en') : '',
    };

    // Initialiser l'objet articleData avec les versions bilingues
    const articleData = {
      title: {
        fr: document.getElementById('title').value || '',
        en: englishData.title || '',
      },
      description: {
        fr: document.getElementById('description').value || '',
        en: englishData.description || '',
      },
      content: {
        fr: document.querySelector('#editor').innerHTML || '',
        en: englishData.content || '',
      },
    };

    // La langue active par défaut est le français
    let activeLang = 'fr';

    // Sélecteur de langue
    const languageSelect = document.getElementById('languageSelect');
    languageSelect.addEventListener('change', function () {
      // Sauvegarder les valeurs actuelles pour la langue active
      const titleEl = document.getElementById('title');
      const descEl = document.getElementById('description');
      if (titleEl && descEl) {
        articleData.title[activeLang] = titleEl.value;
        articleData.description[activeLang] = descEl.value;
        articleData.content[activeLang] = editor.root.innerHTML;
      }
      // Changer la langue active
      activeLang = this.value;
      // Mettre à jour les inputs et l'éditeur avec les valeurs de la nouvelle langue
      if (titleEl && descEl) {
        titleEl.value = articleData.title[activeLang] || '';
        descEl.value = articleData.description[activeLang] || '';
        editor.root.innerHTML = articleData.content[activeLang] || '';
      }
    });

    const loaderModal = document.getElementById('modalLoaderDocs');
    const overlay = document.querySelector('.overlay');
    const translateBtn = document.getElementById('translateContentBtn');
    if (translateBtn) {
      translateBtn.addEventListener('click', async function (e) {
        e.preventDefault();
        // Afficher la modal de chargement
        if (loaderModal) {
          loaderModal.classList.remove('hidden');
          document.body.classList.add('noscroll');
          document.querySelector('.overlay').classList.remove('hidden');
        }
        // On récupère le contenu français stocké dans articleData
        const contentFr = articleData.content['fr'];
        console.log('Contenu FR envoyé pour traduction :', contentFr);
        if (!contentFr) {
          showAlert('error', 'Le contenu français est vide.');
          if (loaderModal) {
            loaderModal.classList.add('hidden');
            document.body.classList.remove('noscroll');
            document.querySelector('.overlay').classList.add('hidden');
          }
          return;
        }
        try {
          const response = await fetch('/api/v1/docs/articles/translate', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contentFr }),
          });
          if (!response.ok) {
            // On log l'erreur et on envoie un message au client
            const errorText = await response.text();
            console.error('Erreur serveur lors de la traduction :', errorText);
            throw new Error('Erreur serveur');
          }
          const result = await response.json();
          if (result.status === 'success') {
            // Mise à jour de la version anglaise dans l'objet articleData
            articleData.content.en = result.data;
            console.log(articleData.content.en);
            showAlert('success', 'Contenu traduit en anglais !');
            // Optionnel : afficher la traduction dans un élément visible (si vous en avez un)
            const translatedEl = document.getElementById('translatedContent');
            if (translatedEl) {
              translatedEl.innerHTML = result.data;
            }
            // Si la langue active est déjà l'anglais, mettre à jour l'éditeur
            if (languageSelect.value === 'en') {
              editor.root.innerHTML = articleData.content.en;
            }
          } else {
            showAlert('error', result.message || 'Erreur lors de la traduction.');
          }
        } catch (error) {
          console.error('Erreur de connexion lors de la traduction :', error);
          showAlert('error', 'Erreur de connexion au serveur lors de la traduction.');
        } finally {
          // Fermer la modal de chargement
          if (loaderModal) {
            loaderModal.classList.add('hidden');
            document.body.classList.remove('noscroll');
            document.querySelector('.overlay').classList.add('hidden');
          }
        }
      });
    }

    const translateMetaBtn = document.getElementById('translateMetaBtn');
    if (translateMetaBtn) {
      translateMetaBtn.addEventListener('click', async function (e) {
        e.preventDefault();

        // Afficher la modal de chargement
        if (loaderModal) {
          loaderModal.classList.remove('hidden');
          document.body.classList.add('noscroll');
          if (overlay) overlay.classList.remove('hidden');
        }

        // Récupérer les valeurs françaises depuis articleData
        const titleFr = articleData.title['fr'];
        const descriptionFr = articleData.description['fr'];

        if (!titleFr || !descriptionFr) {
          showAlert('error', 'Le titre et la description en français sont requis pour la traduction.');
          if (loaderModal) {
            loaderModal.classList.add('hidden');
            document.body.classList.remove('noscroll');
            if (overlay) overlay.classList.add('hidden');
          }
          return;
        }

        try {
          const response = await fetch('/api/v1/docs/articles/translate-meta', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ titleFr, descriptionFr }),
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText);
          }

          const result = await response.json();
          if (result.status === 'success') {
            // Mettre à jour l'objet articleData avec les traductions en anglais
            articleData.title['en'] = result.data.title;
            articleData.description['en'] = result.data.description;

            // Si la langue active est l'anglais, mettre à jour les inputs visibles
            if (languageSelect && languageSelect.value === 'en') {
              const titleInput = document.getElementById('title');
              const descInput = document.getElementById('description');
              if (titleInput) titleInput.value = result.data.title;
              if (descInput) descInput.value = result.data.description;
            }
            showAlert('success', 'Titre et description traduits en anglais !');
          } else {
            showAlert('error', result.message || 'Erreur lors de la traduction.');
          }
        } catch (error) {
          console.error('Erreur de connexion lors de la traduction meta :', error);
          showAlert('error', 'Erreur de connexion au serveur lors de la traduction.');
        } finally {
          if (loaderModal) {
            loaderModal.classList.add('hidden');
            document.body.classList.remove('noscroll');
            if (overlay) overlay.classList.add('hidden');
          }
        }
      });
    } else {
      console.error("translateMetaBtn n'est pas trouvé dans le DOM.");
    }

    // Initialisation de Quill
    const editor = new Quill('#editor', {
      theme: 'snow',
      placeholder: 'Commencez à éditer...',
      modules: {
        toolbar: [
          [{ header: '2' }, { font: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
          ['bold', 'italic', 'underline'],
          ['link'],
          ['image'],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ color: [] }, { background: [] }],
          ['blockquote', 'code-block'],
        ],
      },
    });

    editor.on('text-change', function (delta, oldDelta, source) {
      if (source === 'user' && activeLang === 'fr') {
        articleData.content['fr'] = editor.root.innerHTML;
      }
    });

    const titleInput = document.getElementById('title');
    const descInput = document.getElementById('description');

    if (titleInput) {
      titleInput.addEventListener('input', function () {
        if (activeLang === 'fr') {
          articleData.title['fr'] = titleInput.value;
        } else {
          articleData.title['en'] = titleInput.value;
        }
      });
    }

    if (descInput) {
      descInput.addEventListener('input', function () {
        if (activeLang === 'fr') {
          articleData.description['fr'] = descInput.value;
        } else {
          articleData.description['en'] = descInput.value;
        }
      });
    }

    // Gestion de l'upload d'image dans Quill
    const toolbar = editor.getModule('toolbar');
    toolbar.addHandler('image', function () {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async function () {
        const file = input.files[0];
        if (file) {
          const formData = new FormData();
          formData.append('image', file);
          try {
            const response = await fetch('/api/v1/docs/upload-image', {
              method: 'POST',
              body: formData,
            });
            const result = await response.json();
            if (result.imageUrl) {
              const range = editor.getSelection();
              editor.insertEmbed(range.index, 'image', result.imageUrl);
            } else {
              showAlert('error', "Erreur lors de l'upload de l'image.");
            }
          } catch (error) {
            showAlert('error', "Erreur lors de l'upload de l'image.");
          }
        }
      };
    });

    // Pré-remplissage initial (langue française)
    document.getElementById('title').value = articleData.title.fr;
    document.getElementById('description').value = articleData.description.fr;
    // L'éditeur Quill est déjà initialisé avec article.content.fr

    // Gestion de la soumission du formulaire
    const form = document.getElementById('editArticleForm');
    form.addEventListener('submit', async function (e) {
      e.preventDefault();
      // Sauvegarder la version active
      articleData.title[activeLang] = document.getElementById('title').value;
      articleData.description[activeLang] = document.getElementById('description').value;
      articleData.content[activeLang] = editor.root.innerHTML;

      // Préparer l'objet de données à envoyer
      const data = {
        slug: document.getElementById('slug').value,
        title: articleData.title,
        description: articleData.description,
        content: articleData.content,
        status: document.getElementById('statusSelect').value,
      };

      // Mettre à jour le champ caché si nécessaire
      document.getElementById('content-hidden').value = articleData.content[activeLang];

      try {
        const response = await fetch(form.getAttribute('action'), {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        const result = await response.json();
        if (result.status === 'success') {
          showAlert('success', 'Article mis à jour avec succès !');
        } else {
          showAlert('error', result.message || 'Erreur lors de la mise à jour.');
        }
      } catch (error) {
        showAlert('error', 'Erreur de connexion au serveur.');
      }
    });
  });
}

// // Global variable to store the active block when the menu is shown
// let currentActiveBlock = null;
// // Global variable to track the index of the selected option in the menu
// let menuSelectedIndex = 0;

// // Selector for the blocks container
// const blocksContainer = document.getElementById('blocksContainer');

// // Utility function to place the caret at the end of a contenteditable element
// function placeCaretAtEnd(el) {
//   el.focus();
//   if (
//     typeof window.getSelection !== 'undefined' &&
//     typeof document.createRange !== 'undefined'
//   ) {
//     const range = document.createRange();
//     range.selectNodeContents(el);
//     range.collapse(false);
//     const sel = window.getSelection();
//     sel.removeAllRanges();
//     sel.addRange(range);
//   }
// }

// // When the DOM is loaded, attach a focus listener on the first block (server-generated)
// document.addEventListener('DOMContentLoaded', () => {
//   const firstBlockInput = document.querySelector(
//     '#blocksContainer .block[data-index="0"] .block-input'
//   );
//   if (
//     firstBlockInput &&
//     firstBlockInput.innerText.trim() === 'Écrivez ici...'
//   ) {
//     firstBlockInput.addEventListener('focus', handlePlaceholderFocus);
//   }
// });

// // Function to handle the placeholder focus event
// function handlePlaceholderFocus(e) {
//   const placeholder = this.getAttribute('data-placeholder');
//   if (this.innerText.trim() === placeholder) {
//     this.innerText = '';
//   }
//   // Remove the event listener so it only executes once
//   this.removeEventListener('focus', handlePlaceholderFocus);
//   this.removeAttribute('data-placeholder');
// }

// /**
//  * Creates a new empty block after the current block and places focus inside it.
//  */
// function createNewBlockAfter(currentBlock, newType = 'text') {
//   const handleIconHTML = `
// <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//   <path d="M6 5c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zm0 7c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zm-1 8c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1zm8-8c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zm-1-6c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1zm1 13c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zm6-6c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1zm1-8c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zm-1 15c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1" fill="rgb(0,0,0)"/>
// </svg>
// `;
//   const newIndex = blocksContainer.children.length;
//   const blockDiv = document.createElement('div');
//   blockDiv.classList.add('block');
//   blockDiv.dataset.index = newIndex;
//   blockDiv.dataset.type = newType;

//   // 1. Create the container for the handle and insert the SVG icon
//   const blockHandle = document.createElement('div');
//   blockHandle.classList.add('block-handle');
//   blockHandle.innerHTML = handleIconHTML;
//   blockDiv.appendChild(blockHandle);

//   // 2. Create the editable container
//   const blockInput = document.createElement('div');
//   blockInput.classList.add('block-input');
//   blockInput.setAttribute('contenteditable', 'true');

//   if (newType === 'text' && newIndex === 0) {
//     blockInput.innerText = 'Write here...';
//     blockInput.setAttribute('data-placeholder', 'Write here...');
//     blockInput.addEventListener('focus', handlePlaceholderFocus);
//   } else {
//     blockInput.innerText = '';
//   }

//   blockDiv.appendChild(blockInput);
//   currentBlock.insertAdjacentElement('afterend', blockDiv);
//   blockInput.focus();
// }

// /**
//  * Displays the contextual menu to choose a block type.
//  * @param {HTMLElement} currentBlock - The block in which the user typed "/".
//  * @param {Object} position - The { x, y } position where to display the menu.
//  */
// function showBlockMenu(currentBlock, position) {
//   // Update the global active block
//   currentActiveBlock = currentBlock;
//   let menu = document.getElementById('blockMenu');
//   if (!menu) {
//     menu = document.createElement('div');
//     menu.id = 'blockMenu';
//     menu.style.position = 'absolute';
//     menu.style.background = '#fff';
//     menu.style.border = '1px solid #ccc';
//     menu.style.zIndex = '9999';
//     // Allow the menu to receive focus to capture keyboard events
//     menu.tabIndex = 0;
//     menu.innerHTML = `
//     <div data-type="text" style="padding: 5px; cursor: pointer;">Text</div>
//     <div data-type="title" style="padding: 5px; cursor: pointer;">Title</div>
//     <div data-type="subheading" style="padding: 5px; cursor: pointer;">Subheading</div>
//     <div data-type="boxed" style="padding: 5px; cursor: pointer;">Boxed</div>
//     <div data-type="ul" style="padding: 5px; cursor: pointer;">Unordered List</div>
//     <div data-type="img" style="padding: 5px; cursor: pointer;">Image</div>
//   `;
//     document.body.appendChild(menu);

//     // Get the menu options
//     const items = menu.querySelectorAll('div[data-type]');
//     // For mouse navigation: update selection on hover
//     items.forEach((item, index) => {
//       item.addEventListener('mouseover', function () {
//         items.forEach((it) => it.classList.remove('selected'));
//         this.classList.add('selected');
//         menuSelectedIndex = index;
//       });
//       // Selection on click
//       item.addEventListener('click', function () {
//         const selectedType = this.dataset.type;
//         transformBlockType(currentActiveBlock, selectedType);
//         hideBlockMenu();
//       });
//     });

//     // Add a keyboard navigation listener to the menu
//     menu.addEventListener('keydown', function (e) {
//       if (e.key === 'ArrowDown') {
//         e.preventDefault();
//         menuSelectedIndex = (menuSelectedIndex + 1) % items.length;
//         items.forEach((it) => it.classList.remove('selected'));
//         items[menuSelectedIndex].classList.add('selected');
//       } else if (e.key === 'ArrowUp') {
//         e.preventDefault();
//         menuSelectedIndex =
//           (menuSelectedIndex - 1 + items.length) % items.length;
//         items.forEach((it) => it.classList.remove('selected'));
//         items[menuSelectedIndex].classList.add('selected');
//       } else if (e.key === 'Enter') {
//         e.preventDefault();
//         const selectedItem = items[menuSelectedIndex];
//         if (selectedItem) {
//           const selectedType = selectedItem.dataset.type;
//           transformBlockType(currentActiveBlock, selectedType);
//           hideBlockMenu();
//         }
//       }
//     });
//   }

//   // Reset menu selection
//   menuSelectedIndex = 0;
//   const items = menu.querySelectorAll('div[data-type]');
//   items.forEach((it) => it.classList.remove('selected'));
//   if (items.length > 0) {
//     items[0].classList.add('selected');
//   }

//   // Position and display the menu
//   menu.style.left = position.x + 'px';
//   menu.style.top = position.y + 'px';
//   menu.style.display = 'block';
//   menu.focus();
// }

// /**
//  * Hides the contextual menu.
//  */
// function hideBlockMenu() {
//   const menu = document.getElementById('blockMenu');
//   if (menu) {
//     menu.style.display = 'none';
//   }
// }

// /**
//  * Transforms the current block to another type and gives focus to its editable field.
//  * For type "ul", if contentOverride is provided, it fills the first list item with that content.
//  */
// function transformBlockType(blockElement, newType, contentOverride) {
//   blockElement.dataset.type = newType;
//   const input = blockElement.querySelector('.block-input');
//   if (input) {
//     if (newType === 'ul') {
//       const content =
//         typeof contentOverride === 'string' ? contentOverride : '';
//       input.innerHTML = `<ul><li>${content}</li></ul>`;
//       const newLi = input.querySelector('li');
//       if (newLi) {
//         placeCaretAtEnd(newLi);
//       }
//     } else {
//       input.innerText = '';
//       input.focus();
//       placeCaretAtEnd(input);
//     }
//   }
// }

// // Global listener for handling key events in the blocks container
// blocksContainer.addEventListener('keydown', (e) => {
//   const currentBlock = e.target.closest('.block');
//   if (!currentBlock) return;

//   // 1. Handle Backspace to delete an empty block
//   if (e.key === 'Backspace') {
//     const blockInput = currentBlock.querySelector('.block-input');
//     const sel = window.getSelection();
//     if (blockInput.innerText.trim() === '' && sel.anchorOffset === 0) {
//       e.preventDefault();
//       e.stopPropagation();
//       if (currentBlock.previousElementSibling) {
//         const prevBlock = currentBlock.previousElementSibling;
//         currentBlock.remove();
//         const prevInput = prevBlock.querySelector('.block-input');
//         if (prevInput) {
//           prevInput.focus();
//           placeCaretAtEnd(prevInput);
//         }
//       }
//       return;
//     }
//   }

//   // 2. Handle Enter key
//   if (e.key === 'Enter') {
//     // If Shift+Enter is pressed, allow default behavior (line break)
//     if (e.shiftKey) {
//       return;
//     }
//     // If the block is a UL, handle list behavior separately (see below)
//     if (currentBlock.dataset.type === 'ul') {
//       handleUlEnterKey(e, currentBlock);
//       return;
//     }
//     e.preventDefault();
//     createNewBlockAfter(currentBlock);
//   }
//   // 3. Handle "/" key to display the menu
//   else if (e.key === '/') {
//     e.preventDefault();
//     const rect = e.target.getBoundingClientRect();
//     const position = { x: rect.left, y: rect.bottom };
//     showBlockMenu(currentBlock, position);
//   }
// });

// // Close the menu when clicking elsewhere
// document.addEventListener('click', (e) => {
//   const menu = document.getElementById('blockMenu');
//   if (menu && !menu.contains(e.target)) {
//     hideBlockMenu();
//   }
// });

// // Special handling for UL blocks: on Enter inside a UL block
// function handleUlEnterKey(e, blockElement) {
//   if (e.key === 'Enter') {
//     if (e.shiftKey) return; // allow line break if needed
//     e.preventDefault();
//     e.stopPropagation();
//     const selection = window.getSelection();
//     let anchorNode = selection.anchorNode;
//     if (!anchorNode) return;
//     if (anchorNode.nodeType === Node.TEXT_NODE) {
//       anchorNode = anchorNode.parentElement;
//     }
//     const currentLi = anchorNode.closest('li');
//     if (!currentLi) return;
//     if (currentLi.textContent.trim() === '') {
//       // If the current li is empty, exit the list and create a new text block
//       createNewBlockAfter(blockElement, 'text');
//       currentLi.remove();
//     } else {
//       // Otherwise, add a new li after the current one
//       const newLi = document.createElement('li');
//       newLi.innerHTML = '';
//       currentLi.insertAdjacentElement('afterend', newLi);
//       placeCaretAtEnd(newLi);
//     }
//   }
// }

// // New: Detect "- " at the beginning of a text block to convert to a UL block.
// blocksContainer.addEventListener('input', (e) => {
//   const currentBlock = e.target.closest('.block');
//   if (!currentBlock) return;
//   // On ne vérifie que pour les blocs de type "text"
//   if (currentBlock.dataset.type === 'text') {
//     const input = currentBlock.querySelector('.block-input');
//     if (input) {
//       // On récupère le texte tel quel (sans trim, pour conserver l'espace final)
//       const text = input.innerText;
//       // Si le texte commence exactement par "- " (tiret suivi d'un espace)
//       if (text.startsWith('- ')) {
//         // Extraire le contenu après "- " (cela peut être vide)
//         const newContent = text.slice(2);
//         // Transformer immédiatement le bloc en bloc UL avec le contenu extrait
//         transformBlockType(currentBlock, 'ul', newContent);
//       }
//     }
//   }
// });
