import { showAlert } from './alerts.js';

const inputForm = document.querySelector('.form__footer');
const mailInput = document.getElementById('email');

if (inputForm) {
  inputForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    let email = mailInput.value;

    try {
      const response = await fetch(`/create-contact-on-brevo/${email}/11`, {
        method: 'POST',
      });

      if (response.ok) {
        showAlert('success', 'Vous êtes inscrit à la newsletter !');
      } else {
        const errorData = await response.json();
        document.getElementById('email').value = '';
        showAlert(
          'error',
          `Une erreur est survenue : ${
            errorData.data.message === 'Contact already exist'
              ? 'votre adresse mail est déjà enregistrée.'
              : errorData.data.message
          }`
        );
      }
    } catch (err) {
      console.log(err);
    }
  });
}
